import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request';
import { GenericTable } from '../VacantCOTAlerts/sharedComponents';
import endpoints from '../../../helpers/endpoints'
import hasPermission from '../../../helpers/permissions';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import { Link } from 'react-router-dom';
import session from '../../../stores/session';

const localised = Intl.NumberFormat('en-GB');

const GapSitesAlertsPostcodeTable = ({ selectedCategory, setSelectedPostcode, selectedPostcode, exportToExcel, translations }) => {
  const [postcodes, setPostcodes] = useState([]);
  const [sortBy, setSortBy] = useState({ column: "Postcode", order: "dsc" });
  const [isLoading, setIsLoading] = useState(true);
  const headers = [
    ["Postcode", translations ? translations['gsa.Postcode'] : ''], 
    ["Client", translations ? translations['gsa.client'] : ''], 
    ["ABP", translations ? translations['gsa.abp'] : ''], 
    ["CT", translations ? translations['gsa.ct'] : ''], 
    ["Address Matched", translations ? translations['gsa.address_matched'] : ''], 
    ["Commercial", translations ? translations['gsa.commercial'] : ''], 
    ["Bulk Meter", translations ? translations['gsa.bulk_meter'] : ''], 
    ["Aligned Mismatch", translations ? translations['gsa.aligned_mismatch'] : ''], 
    ["Flat Above Shop", translations ? translations['gsa.flat_above_shop'] : ''], 
    ["Split Property", translations ? translations['gsa.split_property'] : ''], 
    ["New Connection", translations ? translations['gsa.new_connection'] : ''],
    ["Potential Gap", translations ? translations['gsa.potential_gap'] : ''],
  ]

   const rawHeaders = headers.map(header =>header[1]);

  const rows = postcodes ? postcodes.filter(postcode => selectedCategory ? postcode[selectedCategory === "New Connection" ? "New Connections" : selectedCategory] > 0 : true).map(postcode => [
    postcode.Postcode ? postcode.Postcode.replace(' ', String.fromCharCode(160)) : null,
    postcode.Client,
    postcode.ABP,
    postcode.CT,
    postcode["Address Matched"],
    postcode.Commercial,
    postcode["Bulk Meter"],
    postcode['Aligned Mismatch'],
    postcode["Flat Above Shop"],
    postcode["Split Property"],
    postcode["New Connections"],
    postcode["Potential Gap"],
  ]) : null;

  useEffect(() => {
    // Get Postcodes for Outward
    request(true).get(endpoints.GAP_SITES_ALERTS_POSTCODES, {
      params: {
        category: selectedCategory === "New Connection" ? "New Connections" : selectedCategory
      }
    }).then(e => {
      setPostcodes(e.data);
      setIsLoading(false);
    }).catch(e => {
      console.log(e);
      window.alert('Failed to retrieve postcodes.')
    })
  }, [selectedCategory]);

  const sortTable = (column) => {
    if (sortBy.column == column) {
      setSortBy({ ...sortBy, order: sortBy.order == "asc" ? "dsc" : "asc" });
    } else {
      setSortBy({ column, order: "asc" })
    }
  }

  useEffect(() => {
    const sorted = [...postcodes].sort((a, b) => {
      if (sortBy.column) {
        const valueA = parseInt(a[sortBy.column]);
        const valueB = parseInt(b[sortBy.column]);

        if (valueA < valueB) {
          return sortBy.order == "asc" ? 1 : -1;
        } else if (valueA > valueB) {
          return sortBy.order == "asc" ? -1 : 1;
        }
      }
      return 0;
    });
    setPostcodes(sorted);
  }, [sortBy]);

  return (
    <div style={{overflowX: 'auto', maxHeight: '718px'}}>
      <table className='table smaller-text squish'>
        <thead style={{position: 'sticky', top: '0px'}}>
          <tr>
            {
              headers.map(header => {
                return (
                  <th style={ header[0] === selectedCategory ? {color: 'black'} : null} onClick={() => sortTable(header[0] === "New Connection" ? "New Connections" : header[0])}>{header[1] ? header[1] : ''}</th>
                )
              })
            }
            <th><img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px', position: 'relative', right: '17.5%'}} onClick={ () => exportToExcel(rawHeaders, rows, "GapSitesAlertsPostcodesTable")}></img></th>
          </tr>
        </thead>
        <tbody style={{whiteSpace: 'nowrap'}}>
          {
            isLoading ?
              <i style={{ marginTop: '10px', marginLeft: '10px' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
            :
              rows ? rows.map((row, index) => {
                return (
                  <tr>
                    {
                      row.map(data => {
                        return <td>{isNaN(data) ? data : localised.format(data)}</td>
                      })
                    }
                    <td>
                      <div onClick={() => setSelectedPostcode(postcodes[index].Postcode)} style={{cursor: 'pointer'}}>
                        {
                          selectedPostcode === postcodes[index].Postcode ? <i className='fas fa-circle'></i> : <i className='far fa-circle'></i>
                        }
                      </div>
                    </td>
                  </tr>
                )
              }) : null
          }
        </tbody>
      </table>
    </div> 
  )
}

const GapSitesAlertsHouseMap = ({ selectedPostcode, setSelectedUPRN, selectedOutward }) => {
  // Map Functionality
  const {isLoaded, loadError} = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
  });
  const [reload, setReload] = useState(true);

  // Data Extraction
  const [centerPos, setCenterPos] = useState(null);
  const [markers, setMarkers] = useState([])

  // Get Markers for Postcode
  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_MAP_DATA, {
      params: {
        postcode: selectedPostcode
      }
    }).then(e => {
      setMarkers(e.data.data);
      setCenterPos(e.data.meta.center);
      setReload(true);
    });
  }, [selectedPostcode]);

  useEffect(() => {
    setReload(false);
  }, [selectedOutward])

  const onClick = useCallback((marker) => {
    console.log(marker); 
    setSelectedUPRN(marker.ABP_UPRN)
  }, [setSelectedUPRN])

  return isLoaded && !loadError && reload && markers.length ? <>
      <GoogleMap
        center={{ lat: Number(centerPos.latitude), lng: Number(centerPos.longitude) }} 
        zoom={18} 
        mapContainerStyle={{height: '100%', width: '100%', maxHeight: '100%'}} 
        >
        {
          markers.map(marker => {
            var icon = "";
            switch (marker.Category) {
              case 'Split Property':
                icon = '/circle-xmark-solid.png'
                break;
              case 'Flat Above Shop':
                icon = '/circle-xmark-solid.png';
                break;
              case 'New Connections':
                icon = '/circle-xmark-solid.png';
                break;
              case 'Potential Gap':
                icon = '/circle-xmark-solid.png';
                break;
              default:
                icon = '/circle-solid.png';
                break;
            }
            return (<Marker
              key={marker.ABP_URPN + `_${Math.random()}`}
                position={{ lat: Number(marker.Latitude), lng: Number(marker.Longitude) }} 
                onClick={() => onClick(marker) }
                icon={icon}
              />
              )
          })
        }
      </GoogleMap>
    </> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div>
}

const GapSitesAlertsVOAEvidence = ({ uprnEvidence: data }) => {
    const rows = [
      ['ABP Address', 'ABP_Address'],
      ['ABP UPRN', 'ABP_UPRN'],
      ['ABP Update Date', 'ABP_UPDATE_DATE'],
      ['VOA CT Address', 'VOA_CT_Address'],
      ['VOA CT Ref', 'VOA_CT_Ref'],
      ['VOA CT Effective Date', 'VOA_CT_Effective_Date'],
      ['VOA BR Address', 'VOA_BR_Address'],
      ['VOA BR Ref', 'VOA_BR_Ref'],
      ['VOA BR Effective Date', 'VOA_BR_Effective_Date'],
      ['Land Registry Address', 'Land_Registry_Address'],
      ['Land Registry Deed', 'Land_Registry_Deed'],
      ['Land Registry Last Sold Date', ''],
    ];
    return <GenericTable data={data} extraClass={'smaller-text squish left'} rows={rows} title={'Evidence'} subtitle='Detail' firstColWidth={200} />
}

const GapSitesAlertsOccupierEvidenceLeft = ({ uprnEvidence: data }) => {
  const rows = [
    ['Listed on electoral roll', 'Electoral_Roll'],
    ['Listed on BT register', 'BT_Line'],
    ['Count of secured loans', 'Secured_Loans'],
    ['GAS redirect in place', 'GAS_Redirect'],
    ['NCoA redirect in place', 'NCOA_Redirect'],
    ['Count of bank accounts', 'Bank_Accounts'],
    ['Count of unsecured loans', 'Unsecured_Loans'],
    ['Count of utility accounts', 'Utility_Accounts'],
  ]
  return <GenericTable data={data} extraClass={'smaller-text squish left'} rows={rows} title={'Occupier Footprint'} subtitle='Evidence' />
}

const GapSitesAlertsOccupierEvidenceRight = ({ uprnEvidence: data }) => {
  const rows = [
    ['Count of telco/media accounts', 'Telco_Accounts'],
    ['Count of home credit accounts', 'Home_Credit_Accounts'],
    ['Count of mail order accounts', 'Mail_Order'],
    ['Count of revolving facilities', 'Revolving_Facilities'],
    ['Count of other facilities', 'Other_Accounts'],
    ['Count of recent credit searches', 'Credit_Searches'],
    ['Earliest date linked to property', 'Earliest_Date'],
    ['Occupier Score', 'Occupier_Score'],
  ]
  return <GenericTable data={data} extraClass={'smaller-text squish left'} rows={rows} title={'Occupier Footprint'} subtitle='Evidence' />
}

const GapSitesAlertsTitleBar = ({ exportToExcel, translations, selectedCategory, setSelectedCategory }) => {
  const [totals, setTotals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const headers = totals ? Object.keys(totals) : null;
  const rows = totals ? Object.values(totals) : null;

  const headers = [
    translations ? translations['gsa.client'] : '', 
    translations ? translations['gsa.abp'] : '', 
    translations ? translations['gsa.ct'] : '', 
    translations ? translations['gsa.address_matched'] : '', 
    translations ? translations['gsa.commercial'] : '', 
    translations ? translations['gsa.bulk_meter'] : '', 
    translations ? translations['gsa.aligned_mismatch'] : '',
    translations ? translations['gsa.flat_above_shop'] : '', 
    translations ? translations['gsa.split_property'] : '', 
    translations ? translations['gsa.new_connection'] : '',
    translations ? translations['gsa.potential_gap'] : '',
    translations ? translations['gsa.totals_gaps'] : '',
    translations ? translations['gsa.gaps_closed'] : '',
    translations ? translations['gsa.gap_pounds'] : '',
    translations ? translations['gsa.potential_leakage'] : '',
   ]

  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_TOTALS).then(e => {
      setTotals(e.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <table className='table smaller-text squish' style={{whiteSpace: 'nowrap'}}>
        <thead>
          <tr>
            {
              headers.map((header, index) => {
                if (headers.length == index + 1) {
                  return(
                    <th>{header}<img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px', position: 'relative', left: '9%', top: '6px'}} onClick={ () => exportToExcel(headers, [rows], "GapSitesAlertsTotalsTable")}></img></th>
                  )
                }
                return (
                  <th style={{ color: header === selectedCategory ? 'black' : 'inherit',  cursor: ["Split Property", "Flat Above Shop", "New Connection", "Potential Gap"].includes(header) ? 'pointer' : 'default'}} onClick={() => { if(["Split Property", "Flat Above Shop", "New Connection", "Potential Gap"].includes(header)) {setSelectedCategory(header)}}}>{header}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            <tr>
              {
                isLoading ?
                  <i style={{ marginTop: '10px', marginLeft: '10px' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
                :
                  totals ? 
                    rows.map(total => {
                      return (
                        <td>{total}</td>
                      )
                    })
                  :
                  <td></td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

const GapSitesAlertsAddressEvidenceTable = ({ uprnEvidence: data, setUprnEvidence, isLoading, selectedUPRN, exportToExcel, selectedPostcode, translations }) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [sortBy, setSortBy] = useState({ value: "Potential Gap", order: "dsc" });
  const headers = [
    ["Client Ref", translations ? translations['gsa.client_ref'] : ''], 
    ["Client Address", translations ? translations['gsa.client_address'] : ''], 
    ["ABP Address", translations ? translations['gsa.abp_address'] : ''], 
    ["ABP UPRN", translations ? translations['gsa.abp_uprn'] : ''], 
    ["CT Match", translations ? translations['gsa.ct_type'] : ''], 
    ["CT Address", translations ? translations['gsa.ct_address'] : ''], 
    ["Confidence", translations ? translations['gsa.confidence'] : ''], 
    ["Category", translations ? translations['gsa.category'] : ''], 
    ["State", translations ? translations['gsa.state'] : ''], 
  ]

  const rawHeaders = headers.map(header =>header[1]);

  const rows = data ? data.map(property => 
    [
      property.Client_Reference,
      property.Client_Address,
      property.ABP_Address,
      property.ABP_UPRN,
      property.CT_Match,
      property.CT_Address,
      property.Occupier_Score,
      property.Category
    ]) : null;

  const updateCategory = (event, uprn, index) => {

    setUprnEvidence((uprnEvidence) =>{
      const evidence = [...uprnEvidence];
      evidence[index].state = event.target.value;
      return evidence;
    })

    request(true).post(endpoints.GAP_SITES_ALERTS_UPDATE_STATUS, {
      state: event.target.value,
      uprn: uprn
    }).catch(e => {
      console.log(e);
    });
  }

  const sortTable = () => {
    if(!sortBy.value || sortBy.value === "Address Matched") {
      setSortBy({...sortBy, value: "Potential Gap"});
    } else if (sortBy.value === "Potential Gap") {
      setSortBy({...sortBy, value: "Address Matched"})
    }
  }

  useEffect(() => {
    if(!data) {
      return;
    }
    const sorted = [...data].sort((a, b) => {
      if (sortBy.value) {
        if (a.Category === sortBy.value && b.Category !== sortBy.value) return -1;
        if (a.Category !== sortBy.value && b.Category === sortBy.value) return 1;
      }
      return a.Category.localeCompare(b.Category);
    });

    setUprnEvidence(sorted);
  }, [sortBy]);

  return (
    <div style={{overflowX: 'auto', maxHeight: "626px", width: '90vw'}}>
      <table className='table smaller-text suish' style={{width: 'fit-content', minWidth: '100%', tableLayout: 'auto'}}>
        <thead style={{position: 'sticky', top: '0'}}>
          <tr>
            {
              headers.map((header, index) => {
                if (headers.length == index + 1) {
                  return(
                    <th>{header[1]}<img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px', position: 'absolute', right: '0.75%', top: '25%'}} onClick={ () => exportToExcel(rawHeaders, rows, "GapSitesAlertsAddressEvidenceTable")}></img></th>
                  )
                }
                return(
                  header[0] === "Category" ? 
                    <th onClick={() => sortTable()}>{header[1]}</th>
                  :
                  <th>{header[1]}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody style={{whiteSpace: 'nowrap'}}>
          {
            isLoading ?
              <i style={{ marginTop: '10px', marginLeft: '10px' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
            :
              rows ? rows.map((row, index) => {
                return (
                  <tr style={{fontWeight: data[index].ABP_UPRN == selectedUPRN ? 'bold' : 'normal' }}>
                    {
                      row.map((data, index) => {
                        if (index === 3) {
                          return (
                            <td style={{textAlign: 'left', color: 'black'}}>
                              <Link style={{textDecoration: 'none', color: 'black'}}
                                to={{pathname: `/portal/gap-sites-alerts/workflow/${data}`, state: { data }}}
                              >
                                {data}
                              </Link>
                            </td>
                          )
                        } else {
                          return <td style={{textAlign: 'left'}}>{data}</td>
                        }
                      })
                    }
                    <td>
                      <select 
                        key={index}
                        disabled={["Split Property", "Flat Above Shop", "New Connections", "Potential Gap"].includes(data[index].Category) && hasPermission('GSA', 'PURCHASE') ? false : true}
                        style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5, width: 130, textAlign: 'center', opacity: '1'}} 
                        onChange={(event) => updateCategory(event, data[index].ABP_UPRN, index)} 
                        value={selectedOptions[index] ? selectedOptions[index] : data[index].Category == "Potential Gap" ? data[index].state ?? "New" : "Not available"} 
                        >
                        {data[index].Category !== "Address Matched" ? (
                          <>
                            <option value="New">New</option> 
                            <option value="In progress">In progress</option>
                            <option value="GAP Accepted">Gap Accepted</option>
                            <option value="GAP Rejected">Gap Rejected</option>
                          </>
                        ) : (
                          <option value="Not available"> Not available</option>
                        )}
                      </select>
                    </td>
                  </tr>
                )
              }) : null
          }
        </tbody>
      </table>
    </div>
  )
}

export default function GapSitesAlerts() {
  const [translations, setTranslations] = useState(null);
  const [selectedOutward, setSelectedOutward] = useState(null);
  const [selectedPostcode, setSelectedPostcode] = useState(null);
  const [postcodeAddresses, setPostcodeAddresses] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [selectedUPRN, setSelectedUPRN] = useState(null);
  const [uprnEvidence, setUprnEvidence] = useState(null);
  const [uprnEvidenceLoading, setUprnEvidenceLoading] = useState(true);

  useEffect(() => {
    request(true).get('/translate?key=gsa.').then(r => {
      setTranslations(r.data);
  }).catch(e => {
      console.error(e);
  })
  }, []);

  useEffect(() => {
    console.log('Postcode Change', selectedPostcode)
    setUprnEvidenceLoading(true);
    if (selectedPostcode) {
      request(true).get(endpoints.GAP_SITES_ALERTS_DATA, {
        params: {
          postcode: selectedPostcode
        }
      }).then(e => {
        const sorted = [...e.data].sort((a, b) => {
          if (a.Category === "Potential Gap" && b.Category !== "Potential Gap") return -1;
          if (a.Category !== "Potential Gap" && b.Category === "Potential Gap") return 1;
          return a.Category.localeCompare(b.Category);
        });
    
        setUprnEvidence(sorted);
        setUprnEvidenceLoading(false);
      });
    }
  }, [selectedPostcode]);

  useEffect(() => {
    setUprnEvidence(null);
    setSelectedPostcode(null);
    setSelectedUPRN(null);
  }, [selectedOutward])

  const exportToExcel = (headers, rows, filename) => {
    const csv = [
      headers.map(header => {
        if (typeof header === 'string') {
          header = `"${header}"`;
          header = header.replace(String.fromCharCode(163), '£');
        }
        return header;
      }).join(','), 
      ...rows.map(row => 
        row.map((value) => {
          if (typeof value === 'string') {
            value = `"${value}"`;
            value = value.replace(String.fromCharCode(160), ' ');
          }
          return value;
        }).join(','))].join('\n');

  
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, `${filename}.csv`);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${filename}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return <GenericPageContainer 
      title={'GAP Sites Alerts'}
      titleRight={<div>
        <Link className="navigate" to="/portal/gap-sites-alerts">
          To Summary
        </Link>
        { (hasPermission('GSA', 'WORKFLOW')) ?
          <Link className="navigate" to="/portal/gap-sites-alerts/workflow">
            To Workflow
          </Link>
        : null }
      </div>} containerWidth='90vw'>
    <div style={{ marginBottom: '15px' }}>
      <ProductChartInfo path={window.location.pathname} parentStyle={{display: 'flex', justifyContent:'flex-end'}} style={{margin: '0px', cursor: 'pointer', color: 'var(--primary)', paddingLeft: '15px'}} />
      <GapSitesAlertsTitleBar selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} exportToExcel={exportToExcel} translations={translations} />
    </div>
    <div style={{display: 'grid', gridTemplateColumns: '3fr 2fr', gap: 15, marginBottom: '15px', minHeight: "718px"}}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 15}}>
        {
          selectedCategory ? <>
            <GapSitesAlertsPostcodeTable selectedCategory={selectedCategory} selectedPostcode={selectedPostcode} setSelectedPostcode={setSelectedPostcode} exportToExcel={exportToExcel} translations={translations} />
          </> : null
        }
      </div>
      <div>
        <GapSitesAlertsHouseMap selectedPostcode={selectedPostcode} setSelectedUPRN={setSelectedUPRN} selectedOutward={selectedOutward} translations={translations} />
      </div>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', gap: 15}}>
        <div>
          {
            selectedPostcode ? <>
              <GapSitesAlertsAddressEvidenceTable uprnEvidence={uprnEvidence} setUprnEvidence={setUprnEvidence} isLoading={uprnEvidenceLoading} selectedUPRN={selectedUPRN} exportToExcel={exportToExcel} selectedPostcode={selectedPostcode} translations={translations} />
            </> : null
          }
        </div>
      </div>
  </GenericPageContainer>;
}
