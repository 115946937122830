import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../helpers/endpoints'
import request, { cancelAllRequests } from '../../../helpers/request'
// import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import Button from '../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'
import hasPermission from '../../../helpers/permissions'
import classCodes from '../../../assets/classification-codes.json'
import { useHistory } from 'react-router-dom';
import { GenericTable } from '../AutomatedAddressCleanse/shared'
import session from '../../../stores/session'

export const syncRowHeights = () => {
  const tables = document.querySelectorAll('.sync');
  const rowCount = tables[0]?.rows.length;
  const maxHeights = Array(rowCount).fill(0);

  tables.forEach((table) => {
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      const row = table.rows[rowIndex];
      if (!row) return
      if (row.style) {
        row.style.height=0;
      }
      let maxHeightForRow = 0;

      for (const cell of row.cells) {
        const contentHeight = cell.offsetHeight;
        if (cell.querySelector('textarea')) {
          const textareaHeight = cell.querySelector('textarea').scrollHeight;
          maxHeightForRow = Math.max(maxHeightForRow, contentHeight, textareaHeight);
        } else {
          maxHeightForRow = Math.max(maxHeightForRow, contentHeight);
        }
      }

      maxHeights[rowIndex] = Math.max(maxHeights[rowIndex], maxHeightForRow);
    }
  });

  tables.forEach((table) => {
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      if (table.rows[rowIndex] && table.rows[rowIndex].style) {
        table.rows[rowIndex].style.height = `${maxHeights[rowIndex]}px`;
      }
    }
  });
};


export const Summary = ({summaryData, setSummaryData, workflow = false, setMdsData, setMdsDataReady, translations = {}, getMDSData, setConfirmModal, setAddressType, setDisabledByUserType, prefix, suffix = ""}) => {
  const history = useHistory();
  const { state } = history.location;
  const [meter, setMeter] = useState(state?.meter)
  const [loadingWorkflow, setLoadingWorkflow] = useState(false)
  const [search, setSearch] = useState()
  const [spidSearch, setSpidSearch] = useState(null)
  const [wSpid, setWSpid] = useState()
  const [sSpid, setSSpid] = useState()
  const [retOutcome, setRetOutcome] = useState('')
  const [wsOutcome, setWsOutcome] = useState('')
  const [wholesalerNotes, setWholesalerNotes] = useState('')
  const [retailerNotes, setRetailerNotes] = useState('')
  const [wsNoteIndex, setWsNoteIndex] =  useState()
  const [retNoteIndex, setRetNoteIndex] =  useState()
  const [prevWsNotes, setPrevWsNotes] =  useState([])
  const [prevRetNotes, setPrevRetNotes] =  useState([])
  const [workflowItems, setWorkflowItems] = useState([])
  const [workflowCounts, setWorkflowCounts] = useState([])
  const [workflowOutcomeSetting, setWorkflowOutcomeSetting] = useState('New')

  const reset = () => {
    setSummaryData({})
    setWholesalerNotes('')
    setRetailerNotes('')
    setWsOutcome('')
    setRetOutcome('')
    setMdsData(false)
    setMdsDataReady(true)
    setPrevWsNotes([])
    setPrevRetNotes([])
    setWsNoteIndex(0)
    setRetNoteIndex(0)
  }

  const getSummaryData = useCallback(() => {
    setSearch()
    setSummaryData({})
    setRetOutcome('')
    setWsOutcome('')
    setAddressType(null)
    request(true).get(endpoints[`${prefix}_SEARCH_BY_SPID`] +  suffix, {
      doesCancel: true,
      params: {
        meter: meter,
        nonPrioritySearch: spidSearch ? true : false
      }
    }).then(e => {
      setSummaryData(e.data.customer ?? {})
      setMeter(e.data.customer.Meter_ID)
      setWsNoteIndex(e.data.notes?.filter(note => note.Note_Type === "Wholesaler").length)
      setRetNoteIndex(e.data.notes?.filter(note => note.Note_Type === "Retailer").length)
      setPrevWsNotes(e.data.notes?.filter(note => note.Note_Type === "Wholesaler"))
      setPrevRetNotes(e.data.notes?.filter(note => note.Note_Type === "Retailer"))
      setWsOutcome(e.data.customer?.WS_Outcome)
      setRetOutcome(e.data.customer?.Ret_Outcome)
    }).catch(e => {
      reset()
      window.alert('Could not find this Meter.')
    })
  }, [meter, setSummaryData])

  useEffect(() => {
    if (syncRowHeights) {
      syncRowHeights()
    }
  }, [summaryData])

  useEffect(() => {
      window.reloadSummaryData = getSummaryData
  }, [getSummaryData])

  const getWorkflowItems = (spid) => {
    setSpidSearch(spid)
    setLoadingWorkflow(true)
    request(true).get(spidSearch || spidSearch === "" || spid ? endpoints[`${prefix}_SEARCH_BY_CORE_SPID`] + suffix : endpoints[`${prefix}_WF_ITEMS`] + suffix, {
      doesCancel: true,
      params: {
        outcome: workflowOutcomeSetting,
        spid: spid ?? spidSearch
      }
    }).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        if (spid) {
          setMeter(e.data[0])
        }
        const currentMeter = e.data[_cur && _cur !== -1 ? _cur : 0]
        setMeter(currentMeter ?? e.data[0])
      } else {
        reset()
        setMeter()
        setSearch()
      }
      setLoadingWorkflow(false);
    }).catch(e => {
      console.log(e);
      window.alert('No results found')
      setLoadingWorkflow(false)
    })
  }

  const getWorkflowCounts = () => {
    setSearch()
    setSummaryData({})
    setRetOutcome('')
    setWsOutcome('')
    request(true).get(endpoints[`${prefix}_WF_COUNTTS`] + suffix).then(r => {
      setWorkflowCounts(r.data);
    })
  }

  useEffect(() => {
    window.reloadCounts = () => {
      getWorkflowItems()
      getWorkflowCounts()
    }
}, [getWorkflowCounts, getWorkflowItems])

  useEffect(() => {
      window.reloadWorkflow = getWorkflowItems
  }, [getWorkflowItems])

  useEffect(() => {
    window.onload = syncRowHeights;
    window.onresize = syncRowHeights;
}, [getWorkflowItems])

  var _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === meter) ?? 0;
  }, [workflowItems, meter]);

  const refreshCounts = () => {
    if (summaryData.WS_Outcome !== wsOutcome && wsOutcome !== '') {
      getWorkflowItems()
      getWorkflowCounts()
    }
  }

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length-1) {
      refreshCounts()
      setMeter(workflowItems[_cur+1]);
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      refreshCounts()
      setMeter(workflowItems[_cur-1]);
    }
  }

  const updateOutcome = () => {
    request(true).post(endpoints[`${prefix}_UPDATE_OUTCOMES`] + suffix, {
      Meter_ID: summaryData.Meter_ID,
      WS_Outcome: wsOutcome,
      Ret_Outcome: retOutcome
    }).then(e => {
      // setSummaryData({
      //   ...summaryData, 
      //   Ret_Outcome: retOutcome,
      //   WS_Outcome: wsOutcome,
      // })
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  const submitNotes= (noteType) =>{
    request(true).post(endpoints[`${prefix}_SUBMIT_NOTES`], {
      SPID: meter,
      notes: noteType === "Wholesaler" ? wholesalerNotes : retailerNotes,
      noteType: noteType
    }).then(e => {
      // window.alert(e.data);
      setWsNoteIndex(e.data.filter(note => note.Note_Type === "Wholesaler").length)
      setRetNoteIndex(e.data.filter(note => note.Note_Type === "Retailer").length)
      setPrevWsNotes(e.data.filter(note => note.Note_Type === "Wholesaler"))
      setPrevRetNotes(e.data.filter(note => note.Note_Type === "Retailer"))
      setRetailerNotes('')
      setWholesalerNotes('')
      window.alert("Note submitted")
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  useEffect(() => {
    if (workflow) {
      getWorkflowCounts()
    }
  }, [])

  useEffect(() => {
    if (summaryData.Meter_ID) {
      setMdsDataReady(false)
      getMDSData()
    }
  }, [summaryData])

  useEffect(() => {
    if ((retOutcome && retOutcome !== summaryData?.Ret_Outcome) || (wsOutcome && wsOutcome !== summaryData?.WS_Outcome)) {
      updateOutcome()
    }
  }, [retOutcome, wsOutcome])

  useEffect(() => {
    _cur = 0;
  }, [workflowOutcomeSetting])

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [workflowOutcomeSetting])

  useEffect(() => {
    if (meter && meter !== summaryData.Meter_ID) {
      getSummaryData()
      setMdsDataReady(false);
    }
  }, [meter])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.target.id === "spid") {
        _cur = 0;
        getWorkflowItems(event.target.value);
      } else {
        setMeter(search ? search : meter);
      }
    }
  }

  useEffect(() => {
    if (typeof spidSearch === "undefined") {
      _cur = 0;
      if (workflow) {
        getWorkflowItems()
      } else {
        setSummaryData()
        setWorkflowItems([])
        setSpidSearch()
        reset()
      }
    }
  }, [spidSearch])

  useEffect(() => {
    if (summaryData.WS_Outcome === "Complete" || summaryData.WS_Outcome === "Assured") {
      setDisabledByUserType(true)
    } else if (session.wholesaler) {
      if ( (summaryData.W_Wholesaler_ID && summaryData.W_Wholesaler_ID === session.wholesaler) || (summaryData.Wholesaler && summaryData.Wholesaler === session.wholesaler) ) {
        setDisabledByUserType(false)
      } else if (!summaryData.W_Wholesaler_ID || !summaryData.Wholesaler) {
        if (summaryData.S_Wholesaler_ID && summaryData.S_Wholesaler_ID === session.wholesaler) {
          setDisabledByUserType(false)
        }
      } else {
        setDisabledByUserType(true)
      }
    }
    if (session.retailer) {
      if ( (summaryData.W_Retailer_ID && summaryData.W_Retailer_ID === session.retailer) || (summaryData.Retailer_ID && summaryData.Retailer_ID === session.retailer) ) {
        setDisabledByUserType(false)
      } else if (!summaryData.W_Retailer_ID || !summaryData.Retailer_ID) {
        if (summaryData.S_Retailer_ID && summaryData.S_Retailer_ID === session.retailer) {
          setDisabledByUserType(false)
        }
      } else {
        setDisabledByUserType(true)
      }
    }
  }, summaryData['Core_SPID'])

  const outcomes = ["New", "In Progress", "Visit Required", "Deregister", "Complete", "Deregistered", "Dead End", "Reject", "Assured"];

  return (
    <div style={{ display: 'grid', gap: '10px' }}>
      { workflow && 
        <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: `repeat(${outcomes.length}, auto)`, margin: '0em 5em' }}>
          {
            outcomes.map(outcome => {
              const count = workflowCounts.find(wfCount => wfCount?.WS_Outcome === outcome);
              return (
                <button className={`button compact smaller-text ${workflowOutcomeSetting === outcome ? "bulk-buy-button" : "background-primary"} colour-white`} onClick={() => {
                  refreshCounts()
                  setWorkflowOutcomeSetting(outcome)
                }}>
                  {outcome} ({count?.count ?? 0})
                </button>
              )
            })
          }
        </div>
      }
      <SummaryTable 
        translations={translations} 
        setSearch={setSearch} 
        loadingWorkflow={loadingWorkflow} workflow={workflow}
        search={search} meter={meter} 
        handleKeyDown={handleKeyDown} 
        getWorkflowLast={getWorkflowLast} workflowItems={workflowItems} getWorkflowNext={getWorkflowNext}
        _cur={_cur}
        setMeter={setMeter}
        summaryData={summaryData}
        wsOutcome={wsOutcome}
        setConfirmModal={setConfirmModal}
        setWsOutcome={setWsOutcome}
        prefix={[prefix]}
        setSpidSearch={setSpidSearch} spidSearch={spidSearch}
        getWorkflowItems={getWorkflowItems} setCur={(target) => _cur = target}
      />
    </div>
  )
}

export const SummaryTable = ({ 
  translations, setSearch, loadingWorkflow, workflow, search, spidSearch, meter, 
  handleKeyDown, getWorkflowLast, _cur, setCur, workflowItems, getWorkflowNext, getWorkflowItems, 
  setMeter, setSpidSearch, summaryData, wsOutcome, setConfirmModal, setWsOutcome, qualityAssurance, modules, prefix }) => {
  
  const handleMeterSearch = () => {
    setCur(0); 
    if (!spidSearch && !workflow) {
      setSearch()
      setMeter()
    } else {
      getWorkflowItems(spidSearch ? spidSearch : summaryData['Core_SPID']);
    }
  } 

  return (
    <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
    {/* <input type='text' /> */}
      <col width={180} />
      <thead>
        <tr>
          <th colSpan={10}>{translations['HP.Header_Summary']}{qualityAssurance && summaryData.Module && `: ${modules.find(module => module.value === summaryData.Module).label}`}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td width={400}>{translations['HP.Unique_Identifier']}</td>
          <td colSpan={2}>
            <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input style={{border: 'none'}} type="text" onChange={e => setSearch(e.target.value)} value={loadingWorkflow ? '' : search ? search : meter ? meter : ''} onKeyDown={handleKeyDown} />
                { loadingWorkflow && <i className="fa fa-spinner fa-spin" style={{ position: 'absolute', paddingLeft: '2px' }}/> }
                {
                  workflow || workflowItems.length ? <>
                    <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                    <span>{_cur+1}/{workflowItems.length}</span>
                    <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                  </> : <>
                  </>
                }
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => setMeter(search)}></span>
            </div>
          </td>
          <td>{translations['HP.Meter_Serial_Number']}</td>
          <td>{summaryData['Manufacturer_Meter_Serial_Number']}</td>
          <td>{translations['HP.Meter_Manufacturer']}</td>
          <td>{summaryData['Meter_Manufacturer']}</td>
          <td>{translations['HP.Workflow_Status']}</td>
          <td colSpan={2}>
            <select disabled={!hasPermission(prefix, 'WHOLESALER')} value={wsOutcome ? wsOutcome : summaryData.WS_Outcome ?? 'New'} 
            onChange={(event) => {
              if (event.target.value === "Complete" || event.target.value === "Assured") {
                setConfirmModal(event.target.value)
              } else {
                setWsOutcome(event.target.value)
              }
            }} 
            style={{paddingLeft: 0}}>
              <option value="New">New</option>
              <option value="In Progress">In Progress</option>
              <option value="Visit Required">Visit Required</option>
              <option value="Deregister">Deregister</option>
              <option value="Complete">Complete</option>
              <option value="Deregistered">Deregistered</option>
              <option value="Dead End">Dead End</option>
              <option value="Reject">Reject</option>
              <option value="Assured">Assured</option>
            </select>
          </td>
        </tr>
        <tr>
          <td width={400}>{translations['HP.Core_SPID']}</td>
          <td colSpan={2}>
            <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input id={"spid"} style={{border: 'none'}} type="text" onChange={e => setSpidSearch(e.target.value)} value={spidSearch ?? summaryData['Core_SPID'] ?? ''} onKeyDown={(event) => event.key === "Enter" ? handleMeterSearch() : null}/>
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => handleMeterSearch()}></span>
                <span className='fas fa-trash-undo' style={{ color: spidSearch || spidSearch === "" ? '#fa0000' : 'unset', cursor: spidSearch || spidSearch === "" ? 'pointer' : 'unset' }} onClick={() => {  cancelAllRequests(); setCur(0); setMeter(); setSpidSearch(); }} title="Restore workflow"></span>
              </div>
          </td>
          <td>{translations['HP.CMOS_Category']}</td>
          <td colSpan={'2'}>
            {summaryData['CMOS_UPRN_DISTANCE_CATEGORY']}
          </td>
          <td colSpan={2}>{translations['HP.Replacement_ABP_Category']}</td>
          <td colSpan={2}>
            {summaryData['ABP_UPRN_REPLACEMENT_DISTANCE_CATAGORY']}
          </td>
        </tr>
        <tr>
          <td width='(100/4)%'>{translations['HP.CMOS_UPRN_GIS_X']}</td>
          <td width='(100/4)%'>{summaryData['CMOS_UPRN_ABP_GISX']}</td>
          <td width='(100/4)%'>{translations['HP.Meter_GIS_X']}</td>
          <td width='(100/4)%'>{summaryData['CL_GISX']}</td>
          <td width='(100/4)%'>{translations['HP.GIS_X_Matched']}</td>
          <td width='(100/4)%'>{summaryData['CMOS_UPRN_ABP_GISX'] && summaryData['CL_GISX'] ? parseInt(summaryData['CMOS_UPRN_ABP_GISX']) == parseInt(summaryData['CL_GISX']) ? 'Yes' : 'No' : ''}</td>
          <td colSpan={2} width='(100/4)%'>{translations['HP.GIS_X_Difference']}</td>
          <td colSpan={2} width='(100/4)%'>{summaryData['Meter_Distance_from_CMOS_X']}</td>
        </tr>
        <tr>
          <td width='(100/4)%'>{translations['HP.CMOS_UPRN_GIS_Y']}</td>
          <td width='(100/4)%'>{summaryData['CMOS_UPRN_ABP_GISY']}</td>
          <td width='(100/4)%'>{translations['HP.Meter_GIS_Y']}</td>
          <td width='(100/4)%'>{summaryData['CL_GISY']}</td>
          <td width='(100/4)%'>{translations['HP.GIS_Y_Matched']}</td>
          <td width='(100/4)%'>{summaryData['CMOS_UPRN_ABP_GISY'] && summaryData['CL_GISY'] ? parseInt(summaryData['CMOS_UPRN_ABP_GISY']) == parseInt(summaryData['CL_GISY']) ? 'Yes' : 'No' : ''}</td>
          <td colSpan={2} width='(100/4)%'>{translations['HP.GIS_Y_Difference']}</td>
          <td colSpan={2} width='(100/4)%'>{summaryData['Meter_Distance_from_CMOS_Y']}</td>
        </tr>
        <tr>
          <td width='(100/4)%'>{translations['HP.Matched_UPRN_GIS_X']}</td>
          <td width='(100/4)%'>{summaryData['ABP_Easting']}</td>
          <td width='(100/4)%'>{translations['HP.Meter_GIS_X']}</td>
          <td width='(100/4)%'>{summaryData['CL_GISX']}</td>
          <td width='(100/4)%'>{translations['HP.GIS_X_Matched']}</td>
          <td width='(100/4)%'>{summaryData['ABP_Easting'] && summaryData['CL_GISX']  ? parseInt(summaryData['ABP_Easting']) == parseInt(summaryData['CL_GISX']) ? 'Yes' : 'No' : ''}</td>
          <td colSpan={2} width='(100/4)%'>{translations['HP.GIS_X_Difference']}</td>
          <td colSpan={2} width='(100/4)%'>{summaryData['Meter_Distance_from_ABP_X']}</td>
        </tr>
        <tr>
          <td width='(100/4)%'>{translations['HP.Matched_UPRN_GIS_Y']}</td>
          <td width='(100/4)%'>{summaryData['ABP_Northing']}</td>
          <td width='(100/4)%'>{translations['HP.Meter_GIS_Y']}</td>
          <td width='(100/4)%'>{summaryData['CL_GISY']}</td>
          <td width='(100/4)%'>{translations['HP.GIS_Y_Matched']}</td>
          <td width='(100/4)%'>{summaryData['ABP_Northing'] && summaryData['CL_GISY'] ? parseInt(summaryData['ABP_Northing']) == parseInt(summaryData['CL_GISY']) ? 'Yes' : 'No' : ''}</td>
          <td colSpan={2} width='(100/4)%'>{translations['HP.GIS_Y_Difference']}</td>
          <td colSpan={2} width='(100/4)%'>{summaryData['Meter_Distance_from_ABP_Y']}</td>
        </tr>
      </tbody>
    </table>
  )
}

export const CurrentMeter = ({ translations, summaryData, loading, toggle }) => {
  const rows = useMemo(() => {
    return [
      [translations['HP.Outreader_Protocol'], 'Outreader_Protocol'],
      [translations['HP.Meter_Location_Free_Desc'], 'CL_Meter_Address_Free_Desc'],
      [translations['HP.Meter_Location_Code'], ''],
      [translations['HP.GISX'], 'CL_GISX'],
      [translations['HP.GISY'], 'CL_GISY'],
      [translations['HP.Occupier'], ''],
      [translations['HP.CL_Secondary_Addressable_Object'], 'CL_Meter_Address_Secondary_Addressable_Object'],
      [translations['HP.CL_Primary_Addressable_Object'], 'CL_Meter_Address_Primary_Addressable_Object'],
      [translations['HP.CL_Address_Line_1'], 'CL_Meter_Address_Address_Line_1'],
      [translations['HP.CL_Address_Line_2'], 'CL_Meter_Address_Address_Line_2'],
      [translations['HP.CL_Address_Line_3'], 'CL_Meter_Address_Address_Line_3'],
      [translations['HP.CL_Address_Line_4'], 'CL_Meter_Address_Address_Line_4'],
      [translations['HP.CL_Address_Line_5'], 'CL_Meter_Address_Address_Line_5'],
      [translations['HP.CL_Postcode'], 'CL_Meter_Address_Postcode'],
    ]
  }, [translations])

  return [
    <GenericTable title={translations['HP.Current_Meter']} rows={rows} data={summaryData} loading={loading} toggle={toggle} />,
  ]
}

export const Premises = ({ translations, summaryData: data, premisesData, loading }) => {
  const rows = useMemo(() => {
    return [
      [translations['HP.CL_UPRN'], 'CL_UPRN'],
      [translations['HP.GISX'], 'CL_GISX'],
      [translations['HP.GISY'], 'CL_GISY'],
      [translations['HP.CL_Free_Descriptor'], 'CL_Free_Descriptor'],
      [translations['HP.CL_Secondary_Addressable_Object'], 'CL_Secondary_Addressable_Object'],
      [translations['HP.CL_Primary_Addressable_Object'], 'CL_Primary_Addressable_Object'],
      [translations['HP.CL_Address_Line_1'], 'CL_Address_Line_1'],
      [translations['HP.CL_Address_Line_2'], 'CL_Address_Line_2'],
      [translations['HP.CL_Address_Line_3'], 'CL_Address_Line_3'],
      [translations['HP.CL_Address_Line_4'], 'CL_Address_Line_4'],
      [translations['HP.CL_Address_Line_5'], 'CL_Address_Line_5'],
      [translations['HP.CL_Postcode'], 'CL_Postcode'],
    ]
  }, [translations])
  return (
    <div style={{ height: 'fit-content' }}>
      <table className='table borders squish smaller-text left sync' style={{height: '100%', tableLayout: 'fixed', wordBreak: 'break-word'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['HP.Premises']}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) => {
              return (
                <tr key={index} style={{ height: 'auto' }}>
                  <td>{row[0]}</td>
                  <td style={{ minWidth: '60px', paddingTop: '4px', paddingBottom: '4px', textAlign: loading ? 'center' : 'unset', height: 'auto' }}>
                    {loading ? (
                      index === 0 ? <i className="fa fa-spinner fa-spin"></i> : ''
                    ) : (
                      premisesData &&
                      premisesData[row[1]]
                    )}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export const CMOSUprn = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses([]);
    setIndex(0)
    request(true)
      .get(endpoints.METER_COORDINATION_VALIDAITON_GET_CMOS_UPRN, {
        doesCancel: true,
        params: {
          postcode: customInput || customPostcode,
          UPRN: data.CMOS_UPRN || data.CF_UPRN
        }
      })
      .then(e => {
        const _data = e.data;
        setAddresses(_data);
        if (data.CMOS_UPRN) {
          const displayFirst = _data.findIndex(_ => _.UPRN === parseInt(data.CMOS_UPRN))
          setIndex(displayFirst)
          // setSelectedMatches((prevMatches) => {
          //   return {
          //     ...prevMatches,
          //     Matched_UPRN: data.CMOS_UPRN.toString()
          //   };
          // });
        }
        setLoading(false)
      })
      .catch(_ => { 
        console.log(_);
        window.alert('Could not get Address Base data.'); 
        setAddresses([]); 
        setLoading(false) 
      });
  };

  useEffect(() => {
    if (syncRowHeights) {
      syncRowHeights()
    }
  }, [addresses])

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.Meter_ID) {
      setIndex(-1)
      setCustomPostcode('');
      setLoading(true)
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.Meter_ID]);

  useEffect(() => {
    if (customPostcode !== '') return
    if (!data.Meter_ID) {
      setLoading(false)
      setAddresses([])
    } else {
      getData();
    }
  }, [customPostcode])

  const originalPostcode = useMemo(() => {
    return data.ABP_Postcode || data.CL_Postcode;
  }, [data.ABP_Postcode, data.CL_Postcode]);

  const selectMatch = useCallback(() => {
    if (!addresses[index]?.UPRN) return
    if (selectedMatches.Matched_UPRN == addresses[index].UPRN) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          Matched_UPRN: ''
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          Matched_UPRN: typeof addresses[index].UPRN === "number" ? addresses[index].UPRN.toString() : addresses[index].UPRN
        };
      });
    }
  }, [addresses, selectedMatches, setSelectedMatches, index])

  return (
    <div style={{ height: 'fit-content' }}>
      <table className='table borders squish smaller-text left sync' style={{height: '100%', tableLayout: 'fixed', wordBreak: 'break-word'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['HP.CMOS_UPRN']}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{translations['HP.UPRN']}</td>
            <td style={{minWidth: '60px', textAlign: loading ? 'center' : 'unset'}}>
              { 
                loading ?
                  <i className="fa fa-spinner fa-spin"></i>
                  :
                  addresses[index]?.UPRN ?? '' 
              }</td>
          </tr>
          {/* <tr>
            <td>{translations['HP.CLASSIFICATION_CODE']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CLASSIFICATION_CODE ? classCodes.find(classCode => classCode.Concatenated === addresses[index]?.CLASSIFICATION_CODE)?.Class_Desc : '' }</td>
          </tr> */}
          {/* <tr>
            <td>{translations['HP.UDPRN']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.UDPRN ?? '' }</td>
          </tr> */}
          <tr>
            <td>{translations['HP.GISX']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.EASTING ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.GISY']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.NORTHING ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.ORGANISATION']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.ORGANISATION ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.SUB_BUILDING']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.SUB_BUILDING ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.BUILDING_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.BUILDING_NUMBER']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NUMBER ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.STREET_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.STREET_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.LOCALITY']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.LOCALITY ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.TOWN_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.TOWN_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.POST_TOWN']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.POST_TOWN ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['HP.Postcode']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.POSTCODE ?? '' }</td>
          </tr>
          {/* <tr>
            <td style={{minWidth: '60px', width: 'max-content'}}><button className={`button compact smaller-text ${data?.CMOS_UPRN && addresses[index]?.UPRN == data?.CMOS_UPRN ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data?.CMOS_UPRN && addresses[index]?.UPRN == data?.Matched_UPRN ? true : false) }>Clear Match</button></td>
            <td style={{minWidth: '60px'}}><button className={`button compact smaller-text ${selectedMatches.Matched_UPRN && selectedMatches.Matched_UPRN == addresses[index]?.UPRN ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
          </tr> */}
        </tbody>
      </table>
      <ReactModal
        isOpen={postcodeModal}
        onRequestClose={() => setPostcodeModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Postcode Search</h3>
          <input value={customPostcode ? customPostcode : originalPostcode} onChange={_ => setCustomPostcode(_.target.value.toUpperCase())} style={{ border: '1px solid gray', width: '100%', borderRadius: 3 }} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => { getData(customPostcode); setPostcodeModal(false); setLoading(true) }}>Search</button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={clearMatchModal}
        onRequestClose={() => setClearMatchModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Clear existing match</h3>
          <p>Please confirm you wish to clear the following matched UPRN for ABP on this Meter.</p>
          <ul className='spid-list'>
            <li>Meter ID: {data.Meter_ID}</li>
            <li>ABP UPRN: {data.CF_UPRN}</li>
          </ul>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              request(true).post(endpoints.METER_COORDINATION_VALIDAITON_CLEAR_MATCH, {
                spid: data.Meter_ID,
                matchType: 'abp'
              }).then(r => {
                window.reloadSummaryData()
                setClearMatchModal(false); 
              })
            }}
            >Confirm</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export const MatchedUPRN = ({ translations, summaryData: data, selectedMatches, setSelectedMatches, addressType }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([])
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses([])
    setIndex(0)
    request(true).get(endpoints.METER_COORDINATION_VALIDAITON_GET_MATCHED_UPRN, {
      doesCancel: true,
      params: {
        ...((customInput || customPostcode) && {postcode: customInput || customPostcode }),
        ...((!customInput && !customPostcode) && {UPRN: data.Matched_UPRN || data.CF_UPRN})
      }
    }).then(e => {
      const _data = e.data
      setAddresses(_data);
      if (data.Matched_UPRN) {
        const displayFirst = _data.findIndex(_ => _.UPRN === parseInt(data.Matched_UPRN))
        setIndex(displayFirst);
        console.log(addresses)
        // setSelectedMatches((prevMatches) => {
        //   return {
        //     ...prevMatches,
        //     Matched_UPRN: data.Matched_UPRN.toString()
        //   };
        // });
      }
      setLoading(false)
    }).catch(() => {
      window.alert('Could not get Valuation Office data');
      setAddresses([])
      setLoading(false)
    })
  }

  useEffect(() => {
    if (syncRowHeights) {
      syncRowHeights()
    }
  }, [addresses])

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('')
      setLoading(true)
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.Core_SPID])

  // const selectedVOA = useMemo(() => {
  //   const selected = addresses.length > -1 ? addresses[index] : null;
  //   if (!selected ) return {}
  //   selected.Source = data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'SAA' : 'Business Rates'
  //   selected.Deletion_Date = selected.Effective_Status === 'DELETED' ? selected.Current_From_Date : ''
  //   setPostcode(selected.Postcode)
  //   return selected
  // }, [addresses, index, data, setPostcode]);

  useEffect(() => {
    if (customPostcode !== '') return 
    if (!data.Core_SPID) {
      setAddresses([])
      setLoading(false)
    } else {
      getData();
    }
  }, [customPostcode])

  const selectMatch = useCallback(() => {
    console.log(selectedMatches)
    if (!addresses[index]?.UPRN) return
    if (selectedMatches.Matched_UPRN == addresses[index].UPRN) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          Matched_UPRN: '',
          Address: ''
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          Matched_UPRN: typeof addresses[index].UPRN === "number" ? addresses[index].UPRN.toString() : addresses[index].UPRN,
          Address: addresses[index]
        };
      });
    }
  }, [addresses, selectedMatches, setSelectedMatches, index])

  const originalPostcode = useMemo(() => {
    return data.VO_Postcode || data.CL_Postcode
  }, [data.ABP_Postcode, data.CL_Postcode])

  return [
    <div style={{ height: 'fit-content' }}>
      <table className='table borders squish smaller-text left sync' style={{height: '100%', tableLayout: 'fixed', wordBreak: 'break-word'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['HP.Matched_UPRN']}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                  {customPostcode && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); setLoading(true); }} title="Restore original postcode search"></span>
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                  </div>
                  {
                    addresses.length ? <>
                      <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                        <span style={{minWidth: 60, textAlign: 'center'}}>{index+1}/{addresses.length}</span>
                        <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                      </span>
                    </> : null
                  }
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
        <tr>
              <td>{translations['HP.UPRN']}</td>
              <td style={{minWidth: '60px', textAlign: loading ? 'center' : 'unset'}}>
                { 
                  loading ?
                    <i className="fa fa-spinner fa-spin"></i>
                    :
                    addresses[index]?.UPRN ?? '' 
                }</td>
            </tr>
            {/* <tr>
              <td>{translations['HP.CLASSIFICATION_CODE']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.CLASSIFICATION_CODE ? classCodes.find(classCode => classCode.Concatenated === addresses[index]?.CLASSIFICATION_CODE)?.Class_Desc : '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.UDPRN']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.UDPRN ?? '' }</td>
            </tr> */}
            <tr>
              <td>{translations['HP.GISX']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.EASTING ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.GISY']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.NORTHING ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.ORGANISATION']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.ORGANISATION ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.SUB_BUILDING']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.SUB_BUILDING ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.BUILDING_NAME']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NAME ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.BUILDING_NUMBER']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NUMBER ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.STREET_NAME']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.STREET_NAME ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.LOCALITY']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.LOCALITY ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.TOWN_NAME']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.TOWN_NAME ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.POST_TOWN']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.POST_TOWN ?? '' }</td>
            </tr>
            <tr>
              <td>{translations['HP.Postcode']}</td>
              <td style={{minWidth: '60px'}}>{ addresses[index]?.POSTCODE ?? '' }</td>
            </tr>
          <tr>
            {/* <td style={{minWidth: '60px', width: 'max-content'}}><button className={`button compact smaller-text ${data?.CMOS_UPRN && addresses[index]?.UPRN == data?.CMOS_UPRN ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data?.CMOS_UPRN && addresses[index]?.UPRN == data?.Matched_UPRN ? true : false) }>Clear Match</button></td> */}
            <td></td>
            <td style={{minWidth: '60px'}}><button disabled={addressType === ""} className={`button compact smaller-text ${addressType === "" && selectedMatches.Matched_UPRN && selectedMatches.Matched_UPRN == addresses[index]?.UPRN ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
          </tr>
        </tbody>
      </table>
    </div>,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Bulk Allocate COT Alert Data"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input value={customPostcode} onChange={_ => setCustomPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => {getData(customPostcode || undefined); setLoading(true); setPostcodeModal(false)}}>Search</button> 
        </div>
      </div>
    </ReactModal>,
    <ReactModal
      isOpen={clearMatchModal}
      onRequestClose={() => setClearMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h3>Clear existing match</h3>
        <p>Please confirm you wish to clear the following matched Reference for VOA on this SPID.</p>
        <ul className='spid-list'>
          <li>SPID: {data.Core_SPID}</li>
          <li>VOA Reference: {data.Matched_VOA_No}</li>
        </ul>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            request(true).post(endpoints.METER_COORDINATION_VALIDAITON_CLEAR_MATCH, {
              spid: data.Core_SPID,
              matchType: 'voa'
            }).then(r => {
              window.reloadSummaryData()
              setClearMatchModal(false); 
            })
          }}
          >Confirm</button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const Meter = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses([])
    setIndex(0)
    request(true).get(endpoints.METER_COORDINATION_VALIDAITON_GET_CT, {
      doesCancel: true,
      params: {
        postcode: customInput || data.ABP_Postcode || data.CL_Postcode
      }
    })
    .then(e => {
      const _data = e.data;
      setAddresses(_data)
      if (data.au_reference_number) {
        const displayFirst = _data.findIndex(_ => _.au_reference_number === data.au_reference_number) 
        setIndex(displayFirst);
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            ct: typeof data.au_reference_number === "nummber" ? data.au_reference_number.toString() : data.au_reference_number 
          };
        });
      }
      setLoading(false)
    })
    .catch(_ => { window.alert('Could not get Council Tax data.'); setAddresses([]); setLoading(false) })
  };

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('')
      setLoading(true)
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.Core_SPID])

  const tableData = useMemo(() => {
    const selected = addresses.length > -1 ?  addresses[index] : null;
    if (!selected ) return {}
    selected.STATUS = selected.CHANGE_CODE === 'D' ? 'Deleted' : 'Active';
    // setCustomPostcode(selected.Postcode)
    if (selected.address) {
      const addressLines = parseAddress(selected.address)
      addressLines.forEach((line, index) => {
        selected['CT_Address_Line_' + (index + 1)] = line
      })
    }
    return selected
  }, [addresses, index]);

  const selectMatch = useCallback(() => {
    if (!addresses[index]?.au_reference_number) return
    if (selectedMatches.ct == addresses[index].au_reference_number) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          ct: ''
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          ct: typeof addresses[index].au_reference_number === "number" ? addresses[index].au_reference_number.toString() : addresses[index].au_reference_number
        };
      });
    }
  }, [addresses, selectedMatches, setSelectedMatches, index])

  function parseAddress(address) {

    const addressLines = address.split(", ");

    return addressLines;
}

  useEffect(() => {
    if (syncRowHeights()) {
      syncRowHeights()
    }
  }, [data])

  const rows = useMemo(() => {
    return [
      [translations['HP.CL_UPRN'], 'CL_UPRN'],
      [translations['HP.GISX'], 'CL_GISX'],
      [translations['HP.GISY'], 'CL_GISY'],
      [translations['HP.CL_Free_Descriptor'], 'CL_Meter_Address_Free_Desc'],
      [translations['HP.CL_Secondary_Addressable_Object'], 'CL_Meter_Address_Secondary_Addressable_Object'],
      [translations['HP.CL_Primary_Addressable_Object'], 'CL_Meter_Address_Primary_Addressable_Object'],
      [translations['HP.CL_Address_Line_1'], 'CL_Meter_Address_Address_Line_1'],
      [translations['HP.CL_Address_Line_2'], 'CL_Meter_Address_Address_Line_2'],
      [translations['HP.CL_Address_Line_3'], 'CL_Meter_Address_Address_Line_3'],
      [translations['HP.CL_Address_Line_4'], 'CL_Meter_Address_Address_Line_4'],
      [translations['HP.CL_Address_Line_5'], 'CL_Meter_Address_Address_Line_5'],
      [translations['HP.CL_Postcode'], 'CL_Meter_Address_Postcode'],
    ]
  }, [translations, window.reloadSummaryData])
  return (
    <div style={{ height: 'fit-content' }}>
      <table className='table borders squish smaller-text left sync' style={{height: '100%', tableLayout: 'fixed', wordBreak: 'break-word'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['HP.Meter']}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) => {
              return (
                <tr key={index} style={{ height: 'auto' }}>
                  <td>{row[0]}</td>
                  <td style={{ minWidth: '60px', paddingTop: '4px', paddingBottom: '4px', textAlign: loading ? 'center' : 'unset', height: 'auto' }}>
                    {loading ? (
                      index === 0 ? <i className="fa fa-spinner fa-spin"></i> : ''
                    ) : (
                      data &&
                      data[row[1]]
                    )}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export const PremisesFormatted = ({ translations, summaryData, selectedMatches, premisesData, loading, addressType, setAddressType, userInput, setUserInput, setSelectedMatches }) => {
  const textareaRefs = useRef([]);
  const previousHeights = useRef([]);
  const prevAddressType = useRef(null)

  useEffect(() => {
    if (prevAddressType.current !== null && addressType !== null) {
      const prefix = addressType === 'meter' ? 'CF_Meter_Address_' : 'CF_';
      const alternatePrefix = addressType === 'meter' ? 'CF_' : 'CF_Meter_Address_';
      
      setUserInput({
        ...userInput,
        [`${prefix}Free_Desc`]: userInput[`${alternatePrefix}Free_Desc`] ?? '',
        [`${prefix}Secondary_Addressable_Object`]: userInput[`${alternatePrefix}Secondary_Addressable_Object`] ?? '',
        [`${prefix}Primary_Addressable_Object`]: userInput[`${alternatePrefix}Primary_Addressable_Object`] ?? '',
        [`${prefix}Address_Line_1`]: userInput[`${alternatePrefix}Address_Line_1`] ?? '',
        [`${prefix}Address_Line_2`]: userInput[`${alternatePrefix}Address_Line_2`] ?? '',
        [`${prefix}Address_Line_3`]: userInput[`${alternatePrefix}Address_Line_3`] ?? '',
        [`${prefix}Address_Line_4`]: userInput[`${alternatePrefix}Address_Line_4`] ?? '',
        [`${prefix}Address_Line_5`]: userInput[`${alternatePrefix}Address_Line_5`] ?? '',
        [`${prefix}Postcode`]: userInput[`${alternatePrefix}Postcode`] ?? '',
      });
    } 
    prevAddressType.current = addressType
  }, [addressType])

  const handleAddressTypeChange = (newType) => {
    const prefix = newType === 'meter' ? 'CF_Meter_Address_' : 'premises' ? 'CF_' : '';
    const alternatePrefix = newType === 'meter' ? 'CF_' : 'premises' ? 'CF_Meter_Address_' :'';

    if (addressType === "") {
      setUserInput(summaryData)
    } else if (newType !== "") {
      setUserInput({
        ...userInput,
        [`${prefix}Free_Desc`]: userInput[`${alternatePrefix}Free_Desc`] ?? '',
        [`${prefix}Secondary_Addressable_Object`]: userInput[`${alternatePrefix}Secondary_Addressable_Object`] ?? '',
        [`${prefix}Primary_Addressable_Object`]: userInput[`${alternatePrefix}Primary_Addressable_Object`] ?? '',
        [`${prefix}Address_Line_1`]: userInput[`${alternatePrefix}Address_Line_1`] ?? '',
        [`${prefix}Address_Line_2`]: userInput[`${alternatePrefix}Address_Line_2`] ?? '',
        [`${prefix}Address_Line_3`]: userInput[`${alternatePrefix}Address_Line_3`] ?? '',
        [`${prefix}Address_Line_4`]: userInput[`${alternatePrefix}Address_Line_4`] ?? '',
        [`${prefix}Address_Line_5`]: userInput[`${alternatePrefix}Address_Line_5`] ?? '',
        [`${prefix}Postcode`]: userInput[`${alternatePrefix}Postcode`] ?? '',
      });
    } else {
      setUserInput({})
      setSelectedMatches({abp: ''})
    }

    setAddressType(newType)
  }

  const adjustHeight = (index) => {
    const textarea = textareaRefs.current[index];
    if (textarea) {
      textarea.style.height = '0px';
      const newHeight = `${textarea.scrollHeight}px`;
  
      if (previousHeights.current[index] !== newHeight) {
        previousHeights.current[index] = newHeight;
        textarea.style.height = newHeight;
        textarea.parentNode.parentNode.style.height = "0px"
        syncRowHeights();
      } else {
        textarea.style.height = newHeight;
      }
    }
  };

  const rows = [
    [translations['HP.CL_UPRN'], 'CF_UPRN'],
    [translations['HP.Meter_GIS_X'], 'CF_GISX'],
    [translations['HP.Meter_GIS_Y'], 'CF_GISY'],
    [translations['HP.CL_Free_Descriptor'], addressType === 'meter' ? 'CF_Meter_Address_Free_Desc' : 'CF_Free_Descriptor'],
    [translations['HP.CL_Secondary_Addressable_Object'], addressType === 'meter' ? 'CF_Meter_Address_Secondary_Addressable_Object' : 'CF_Secondary_Addressable_Object'],
    [translations['HP.CL_Primary_Addressable_Object'], addressType === 'meter' ? 'CF_Meter_Address_Primary_Addressable_Object' : 'CF_Primary_Addressable_Object'],
    [translations['HP.CL_Address_Line_1'], addressType === 'meter' ? 'CF_Meter_Address_Address_Line_1' : 'CF_Address_Line_1'],
    [translations['HP.CL_Address_Line_2'], addressType === 'meter' ? 'CF_Meter_Address_Address_Line_2' : 'CF_Address_Line_2'],
    [translations['HP.CL_Address_Line_3'], addressType === 'meter' ? 'CF_Meter_Address_Address_Line_3' : 'CF_Address_Line_3'],
    [translations['HP.CL_Address_Line_4'], addressType === 'meter' ? 'CF_Meter_Address_Address_Line_4' : 'CF_Address_Line_4'],
    [translations['HP.CL_Address_Line_5'], addressType === 'meter' ? 'CF_Meter_Address_Address_Line_5' : 'CF_Address_Line_5'],
    [translations['HP.CL_Postcode'], addressType === 'meter' ? 'CF_Meter_Address_Postcode' : 'CF_Postcode']
  ];

  useEffect(() => {
    if (summaryData) {
      setUserInput({ ...summaryData });
    }
  }, [summaryData]);

  let disabled = true;
  if (summaryData) {
    if (selectedMatches.Matched_UPRN !== summaryData.CMOS_UPRN) {
      disabled = false;
    }
  }

  useEffect(() => {
    textareaRefs.current.forEach((_, index) => adjustHeight(index));
  }, [userInput, premisesData]);

  useEffect(() => {
    if (selectedMatches.Address) {
      const fields = [
        'SUB_BUILDING',
        'BUILDING_NAME',
        'BUILDING_NUMBER',
        'STREET_NAME',
        'TOWN_NAME',
        'POST_TOWN',
      ];

      const addressLines = [
        'CF_Meter_Address_Address_Line_1',
        'CF_Meter_Address_Address_Line_2',
        'CF_Meter_Address_Address_Line_3',
        'CF_Meter_Address_Address_Line_4',
        'CF_Meter_Address_Address_Line_5',
      ];

      const components = fields.map(field => selectedMatches.Address[field]).filter(value => value && value.trim() !== '');

      components.forEach((component, index) => {
        if (index < addressLines.length) {
          selectedMatches.Address[addressLines[index]] = component;
        }
      });

      for (let i = components.length; i < addressLines.length; i++) {
        selectedMatches.Address[addressLines[i]] = '';
      }

      setUserInput({
        'CF_UPRN': selectedMatches?.Address?.UPRN,
        'CF_GISX': selectedMatches?.Address?.EASTING,
        'CF_GISY': selectedMatches?.Address?.NORTHING,
        [addressType === 'meter' ? 'CF_Meter_Address_Free_Desc' : 'CF_Free_Descriptor']: '',
        [addressType === 'meter' ? 'CF_Meter_Address_Secondary_Addressable_Object' : 'CF_Secondary_Addressable_Object']: '',
        [addressType === 'meter' ? 'CF_Meter_Address_Primary_Addressable_Object' : 'CF_Primary_Addressable_Object']: '',
        [addressType === 'meter' ? 'CF_Meter_Address_Address_Line_1' : 'CF_Address_Line_1']: selectedMatches?.Address?.CF_Meter_Address_Address_Line_1,
        [addressType === 'meter' ? 'CF_Meter_Address_Address_Line_2' : 'CF_Address_Line_2']: selectedMatches?.Address?.CF_Meter_Address_Address_Line_2,
        [addressType === 'meter' ? 'CF_Meter_Address_Address_Line_3' : 'CF_Address_Line_3']: selectedMatches?.Address?.CF_Meter_Address_Address_Line_3,
        [addressType === 'meter' ? 'CF_Meter_Address_Address_Line_4' : 'CF_Address_Line_4']: selectedMatches?.Address?.CF_Meter_Address_Address_Line_4,
        [addressType === 'meter' ? 'CF_Meter_Address_Address_Line_5' : 'CF_Address_Line_5']: selectedMatches?.Address?.CF_Meter_Address_Address_Line_5,
        [addressType === 'meter' ? 'CF_Meter_Address_Postcode' : 'CF_Postcode']: selectedMatches?.Address?.POSTCODE,
      })
    }
  }, [selectedMatches.Matched_UPRN])

  return (
    <div style={{ height: 'fit-content' }}>
      <table className='table borders squish smaller-text left sync' style={{height: '100%', tableLayout: 'fixed', wordBreak: 'break-word'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              {translations['HP.Updated_Meter']}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index} style={{ height: 'auto' }}>
              <td>{row[0]}</td>
              <td style={{ minWidth: '60px', paddingTop: '4px', paddingBottom: '4px', textAlign: loading ? 'center' : 'unset', height: 'auto' }}>
                {loading ? (
                  index === 0 ? <i className="fa fa-spinner fa-spin"></i> : ''
                ) : (
                  <textarea
                    id={row[0]}
                    name={row[1]}
                    ref={(el) => (textareaRefs.current[index] = el)}
                    value={userInput[row[1]] ?? ''}
                    onChange={(event) => {
                      setUserInput({ ...userInput, [row[1]]: event.target.value })
                      adjustHeight(index);
                    }}
                    disabled={summaryData['WS_Outcome'] === 'Complete'}
                    style={{ resize: 'none', height: 'fit-content', paddingTop: '0px', paddingBottom: '0px', marginBottom: '-1px' }}
                  />
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ minWidth: '60px' }}>
              <button
                className={`button compact smaller-text ${!disabled && addressType === "premises" ? 'background-primary colour-white' : ''}`}
                // onClick={() => !disabled && setAddressType(addressType !== "premises" ? "premises" : null)}
                onClick={() => !disabled && handleAddressTypeChange(addressType !== "premises" ? "premises" : "")}
              >
                Update Premises
              </button>
            </td>
            <td style={{ minWidth: '60px' }}>
              <button
                className={`button compact smaller-text ${!disabled && addressType === "meter" ? 'background-primary colour-white' : ''}`}
                // onClick={() => !disabled && setAddressType(addressType !== "meter" ? "meter" : null)}
                onClick={() => !disabled && handleAddressTypeChange(addressType !== "meter" ? "meter" : "")}
              >
                Update Meter
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const ConfirmModal = ({confirmModal, setConfirmModal, summaryData, setSummaryData, userInput, addressType, prefix, suffix = ""}) => {
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  return (
    <ReactModal 
    isOpen={confirmModal} 
    onRequestClose={() => setConfirmModal(false)}
    className="card bulk-allocate"
    contentLabel="Wholesaler GAPs No Match"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Confirm</h3>
        <p>Please confirm you are finished working on this Meter.</p>
        {!addressType && <p>No address details will be updated.</p> }
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setConfirmModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            setLoadingConfirm(true)
            request(true).post(endpoints[`${prefix}_SELECT_MATCH`] + suffix, {
              Meter_ID: summaryData.Meter_ID,
              status: confirmModal,
              User_Input: userInput ?? '',
              Address_Type: addressType ?? '',
            }).then(r => {
              setLoadingConfirm(false)
              setSummaryData(null)
              window.reloadCounts()
              setConfirmModal(false); 
            }).catch(error => {
              console.log(error)
              setLoadingConfirm(false)
            })
          }}
          >
            { !loadingConfirm ?
                "Confirm"
              :
                <div className="spinner" style={{ margin: '0em 1em' }}>
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
            }
          </button> 
        </div>
      </div>
    </ReactModal>
  )
}
