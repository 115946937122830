import React from 'react'
import { useRef, useState, useEffect, useCallback } from 'react';
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';
import ReactModal from 'react-modal'

export function GenericTable({ 
    data, rows, title, 
    extraClass, style={}, evidence = true, 
    subtitle = 'Evidence', align, 
    firstColWidth, biggerSecondRow = true,
    leftOfTitleContent, setCustomerNumber = null,
    updateCustomerProperty=null
 }) {
//   return (
//     <table className={`table borders ${extraClass}`} style={{ height: '100%', ...style }}>
//       <thead>
//         <tr>
//             {
//                 leftOfTitleContent ? <>
//                     <th>
//                         {leftOfTitleContent}
//                     </th>
//                 </> : null
//             }
//           <th style={{textAlign: leftOfTitleContent ? 'center' : 'start' }} colSpan={evidence ? 1 : 2}>
//             {title}
//           </th>
//           {
//             evidence ? <>
//               <th>
//                 {subtitle}
//               </th>
//             </> : null
//           }
//         </tr>
//       </thead>
//       <tbody>
//         {
//           rows.map((row, i) => {
//             const Value = row[1]
//             if (row[0] === "Phone") {
//                 return (
//                 <tr key={`gtable_${Math.random()}_${i}`}>
//                     <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
//                     <td style={{ minWidth: evidence ? 'unset' : 60 }}>
//                         {data ? (typeof Value === 'string') ?  data[Value] : <Value data={data}></Value> : null} 
//                         { data && setCustomerNumber !== null
//                             ? data[Value] ? 
//                                 <i onClick={() => setCustomerNumber(data[Value])} className="fa-solid fa-phone" style={{color: "var(--primary)", paddingLeft: "10px", cursor: 'pointer'}}></i> 
//                             : null 
//                         : null }
//                         { updateCustomerProperty !== null ?
//                             <i onClick={() => data?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: data.SPID, property: Value, [Value]: data[Value]}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '3%', top: '20%'}}></i>
//                         : null }
//                     </td>
//                 </tr>
//                 )
//             } else if (row[0] === "Website") {
//                 return (
//                     <tr key={`gtable_${Math.random()}_${i}`}>
//                       <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
//                       <td style={{ minWidth: evidence ? 'unset' : 60 }}>{data ? (typeof Value === 'string') ?  
//                             <a style={{color: 'var(--blue)', textDecoration: 'none'}} href={data[Value] && !data[Value].includes('http://') && !data[Value].includes('https://') ? "https://" + data[Value] : data[Value]} target='_blank'>{data[Value]}</a> 
//                         : 
//                             <Value data={data}></Value> : null}</td>
//                     </tr>
//                   )
//             } else {
//                 return (
//                   <tr key={`gtable_${Math.random()}_${i}`}>
//                     <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
//                     <td style={{ minWidth: evidence ? 'unset' : 60, padding: updateCustomerProperty !== null ? '0px' : 'auto' }}>
//                     <input type="text" className="data-row-field" style={{padding: '0px', height: '-webkit-fill-available', width: '-webkit-fill-available', border: '0px'}} value={ data?.[Value] ? `${data?.[Value]}` : '' } onChange={(event) => data?.SPID ? updateCustomerProperty(event, Value) : null} /> 
//                         { 
//                             updateCustomerProperty !== null ?
//                                 <div style={{height: '100%', width: '100%', position: 'relative'}}>
//                                     <input type="text" className="data-row-field" style={{padding: '0px', height: '-webkit-fill-available', width: '-webkit-fill-available', border: '0px'}} value={ data?.[Value] ? `${data?.[Value]}` : '' } onChange={(event) => data?.SPID ? updateCustomerProperty(event, Value) : null} /> 
//                                     {/* <i onClick={() => data?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: data.SPID, property: Value, [Value]: data[Value]}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '3%', top: '25%'}}></i> */}
//                                 </div>
//                             :
//                                 data ? (typeof Value === 'string') ?  data[Value] : <Value data={data}></Value> : null
//                         }
//                     </td>
//                   </tr>
//                 )
//             }
//           })
//         }
//       </tbody>
//     </table>
//   )
    return (
        <table className={`table borders ${extraClass}`} style={{ height: '100%', ...style }}>
            <thead>
                <tr>
                    {
                        leftOfTitleContent ? <>
                            <th>
                                {leftOfTitleContent}
                            </th>
                        </> : null
                    }
                <th style={{textAlign: leftOfTitleContent ? 'center' : 'start' }} colSpan={evidence ? 1 : 2}>
                    {title}
                </th>
                {
                    evidence ? <>
                    <th>
                        {subtitle}
                    </th>
                    </> : null
                }
                </tr>
            </thead>
            <tbody>
                {
                    rows.map((row, i) => {
                        const Value = row[1]
                        return (
                            <tr style={{position: 'relative'}} >
                                <td>{row[0]}</td>
                                <td style={{padding: '0px'}}>
                                    <input type="text" disabled={updateCustomerProperty === null ? true : false} style={{padding: '0px', height: '100%', width: '-webkit-fill-available', border: '0px', borderTop: '1px solid #e8e8e8', borderLeft: '1px solid #e8e8e8', backgroundColor: 'transparent'}} className="data-row-field" value={ data ? data[Value] ? `${data[Value]}` : '' : '' } onChange={(event) => data?.SPID ? updateCustomerProperty(event, [Value]) : null} /> 
                                    { row[0]?.toLowerCase().includes('phone') ? 
                                        <i onClick={() => data?.[Value] ? setCustomerNumber(data[Value]) : ''} className="fa-solid fa-phone" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '9%', top: '33%'}}></i>
                                    : ''}
                                    { row[0]?.toLowerCase().includes('website') ? 
                                        <i onClick={() => data?.[Value] ? window.open(!data[Value].includes('http://') && !data[Value].includes('https://') ? "https://" + data[Value] : data[Value]) : ''} className="fa-solid fa-up-right-from-square" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '9%', top: '33%'}}></i>
                                    : ''}
                                    <i onClick={() => data?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: data.SPID, property: Value, [Value]: data[Value]}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '2%', top: '33%'}}></i>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export function DVSAEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company', 'DVSA_Name'],['Address', 'DVSA_Address'],['Phone', 'DVSA_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="DVSA" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty} />
    )
}

export function CharityCommisionEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Name', 'CC_Name'],['Address', 'CC_Address'],['Phone', 'CC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Trip Advisor" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function BTDirectoryEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company', 'BT_Name'],['Address', 'BT_Address'],['Phone', 'BT_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="BT Directory" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function FoodStandardsEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company', 'FSA_Name'],['Address', 'FSA_Address'],['Rating Date', 'FSA_Rating_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Food Standards Agency" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function GoogleEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company','Google_Company'],['Address','Google_Address'],['Business Status', 'Google_Status'],['Phone','Google_Phone'],['First Review Date','Google_First_Date'],['Last Review Date','Google_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Google" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function CompaniesHouseEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company','Comp_Hse_Company'],['Address','Comp_Hse_Address'],['Business Status','Comp_Hse_Status'],['SIC','Comp_Hse_SIC'],['Incorporated Date','Comp_Hse_Inc_Date'],['Last Statement Date','Comp_Hse_LS_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Companies House" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function YellComEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company','Yell_Company'],['Address','Yell_Address'],['Business Status','Yell_Status'],['Phone','Yell_Phone'],['Website','Yell_Website'],['Email','Yell_Email']];
    return (
        <GenericTable data={data} rows={rows} title="Yell.Com" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function AddressEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company','AD_Name'],['UPRN Address','AddressBase_Address'],['UPRN','UPRN'],['VOA Number','AD_VOA_No'],['VOA End Date','AD_VOA_End'],['CT Start Date','AD_CT_Start']];
    return (
        <GenericTable data={data} rows={rows} title="Address Data" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function CQCEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company', 'CQC_Name'],['Address', 'CQC_Address'],['Phone', 'CQC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Quality Care Comm" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function JustEatEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company', 'JE_Company'],['Address', 'JE_Address'],['Phone', 'JE_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Just Eat" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function One92Evidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company', 'One92_Company'],['Address', 'One92_Address'],['Phone', 'One92_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="192.com" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function FacebookEvidence({data, setCustomerNumber, updateCustomerProperty}) {
    const rows = [['Company', 'FB_Company'],['Address', 'FB_Address'],['Phone', 'FB_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Facebook" setCustomerNumber={setCustomerNumber} updateCustomerProperty={updateCustomerProperty}  />
    )
}

export function MDS({data, MDSDataReady, customPostcode, setCustomPostcode, getData, classes, style, SPID}) {
    const [postcodeModal, setPostcodeModal] = useState(false);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [triggerGetData, setTriggerGetData] = useState(false);
    const handleSort = (columnName) => {
        if (sortedColumn === columnName) {
          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
          setSortedColumn(columnName);
          setSortDirection('asc');
        }
      };

      const handleGetData = () => {
        setCustomPostcode(''); 
        setTriggerGetData(true);
    };

    useEffect(() => {
        if (triggerGetData) {
            getData();
            setTriggerGetData(false);
        }
    }, [customPostcode, triggerGetData]);
    return (
        <div style={{overflowY: 'auto', maxHeight: '70vh'}}>
            <table className={`table borders ` + classes ?? ''} style={{ ...style, tableLayout: 'fixed' }}>
            <thead style={{position: 'sticky', top: '0px'}}>
                <tr>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        SPID
                        </th>
                    <th onClick={() => handleSort("Customer_Name")} style={{textAlign: 'start' }} colSpan= '1' >
                        Customer Name
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        UPRN
                    </th>            
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        VOA BA Reference
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        SAO
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        PAO
                    </th>
                    <th onClick={() => handleSort("Premises_Address_Address_Line_1")} style={{textAlign: 'start' }} colSpan= '1' >
                        Address 1
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        Address 2
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        Address 3
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        Address 4
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        Address 5
                    </th>
                    <th style={{textAlign: 'start' }} colSpan= '1' >
                        {
                            setCustomPostcode ? 
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Address - Postcode
                                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                                    {customPostcode && (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <span className='fas fa-trash-undo' onClick={() => { handleGetData() }} title="Restore original postcode search"></span>
                                        </div>
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                                    </div>
                                    </div>
                                </div>
                            :
                                "Postcode"
                        }
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    MDSDataReady === true ? (
                        data && data.length ? (
                        data
                        .sort((a, b) => {
                            const coreA = a.SPID.match(/^\d+/)[0];
                            const coreB = b.SPID.match(/^\d+/)[0];
                          
                            const aMatches = a.SPID.startsWith(SPID);
                            const bMatches = b.SPID.startsWith(SPID);
                            
                            if (aMatches && !bMatches) return -1;
                            if (!aMatches && bMatches) return 1;
                          
                            if (coreA !== coreB) return parseInt(coreA) - parseInt(coreB);
                          
                            const aLetter = a.SPID.includes('W') ? 'W' : 'S';
                            const bLetter = b.SPID.includes('W') ? 'W' : 'S';
                          
                            if (aLetter !== bLetter) {
                              return aLetter === 'W' ? -1 : 1;
                            }
                          
                            const aNumber = parseInt(a.SPID.replace(/\D/g, ''), 10);
                            const bNumber = parseInt(b.SPID.replace(/\D/g, ''), 10);
                          
                            return aNumber - bNumber;
                          })
                        .map((row, index) => (
                            <tr key={index}>
                            <td>{row.SPID}</td>
                            <td>{row.Customer_Name}</td>
                            <td>{row.UPRN}</td>
                            <td>{row.VOA_BA_Reference}</td>
                            <td>{row.Premises_Address_Secondary_Address_Obj}</td>
                            <td>{row.Premises_Address_Primary_Address_Obj}</td>
                            <td>{row.Premises_Address_Address_Line_1}</td>
                            <td>{row.Premises_Address_Address_Line_2}</td>
                            <td>{row.Premises_Address_Address_Line_3}</td>
                            <td>{row.Premises_Address_Address_Line_4}</td>
                            <td>{row.Premises_Address_Address_Line_5}</td>
                            <td>{row.Premises_Address_Postcode}</td>
                            </tr>
                        ))
                        ) : (
                            <tr>
                                <td>{data?.SPID || ''}</td>
                                <td>{data?.Customer_Name || ''}</td>
                                <td>{data?.UPRN || ''}</td>
                                <td>{data?.VOA_BA_Reference || ''}</td>
                                <td>{data?.Premises_Address_Secondary_Address_Obj || ''}</td>
                                <td>{data?.Premises_Address_Primary_Address_Obj || ''}</td>
                                <td>{data?.Premises_Address_Address_Line_1 || ''}</td>
                                <td>{data?.Premises_Address_Address_Line_2 || ''}</td>
                                <td>{data?.Premises_Address_Address_Line_3 || ''}</td>
                                <td>{data?.Premises_Address_Address_Line_4 || ''}</td>
                                <td>{data?.Premises_Address_Address_Line_5 || ''}</td>
                                <td>{data?.Premises_Address_Postcode || ''}</td>
                            </tr>
                        )
                    ) : (
                        <i className="fa fa-spinner fa-spin"></i>
                    )
                }
                </tbody>
                <ReactModal
                isOpen={postcodeModal}
                onRequestClose={() => setPostcodeModal(false)}
                className="card bulk-allocate"
                contentLabel="Bulk Allocate COT Alert Data"
                style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <h3>Postcode Search</h3>
                        <input value={customPostcode ? customPostcode : data?.[0]?.Premises_Address_Postcode} onChange={_ => setCustomPostcode(_.target.value.toUpperCase())} style={{ border: '1px solid gray', width: '100%', borderRadius: 3 }} />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => { getData(customPostcode); setPostcodeModal(false); }}>Search</button>
                        </div>
                    </div>
                </ReactModal>
            </table>
        </div>
    )
}

export function TitleOwnerRow({ state, updateCustomerProperty }) {
    return (
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
            <div className="left-grid-bottom" style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 5}}>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.title-number-owner'] : '' }
                    </div>
                    <input type="text" className="data-row-field" value={ state.customer ? state.customer.LR_Name_No ? `${state.customer.LR_Name_No}` : '' : '' } onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'LR_Name_No') : null} /> 
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'LR_Name_No', LR_Name_No: state.customer.LR_Name_No}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '2%', top: '20%'}}></i>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.title-owner-address'] : '' }
                    </div>
                    <input type="text" className="data-row-field" value={ state.customer ? state.customer.LR_Address ? `${state.customer.LR_Address}` : '' : '' } onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'LR_Address') : null} /> 
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'LR_Address', LR_Address: state.customer.LR_Address}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '2%', top: '20%'}}></i>
                </div>
            </div>
        </div>
    )
}

export function LeftColumn({ state, children, setCustomerNumber, updateCustomerProperty }) {
    return (
        <>
            {
                children
            }
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.client_address'] : '' }
                </div>
                <input type="text" className="data-row-field" value={ state.customer ? state.customer.SPID_Address : '' } onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'SPID_Address') : null} /> 
                <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'SPID_Address', SPID_Address: state.customer.SPID_Address}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '1%', top: '20%'}}></i>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.cmos_uprn_address'] : '' }
                </div>
                <input type="text" className="data-row-field" value={ state.customer ? state.customer.CMOS_UPRN_Single_Line ? state.customer.CMOS_UPRN_Single_Line : '' : ''} onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'CMOS_UPRN_Single_Line') : null} />
                <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'CMOS_UPRN_Single_Line', CMOS_UPRN_Single_Line: state.customer.CMOS_UPRN_Single_Line}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '1%', top: '20%'}}></i>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.cmos_voa_address'] : '' }
                </div>
                <input type="text" className="data-row-field" value={ state.customer ? state.customer.CMOS_VOA_Single_Line ? state.customer.CMOS_VOA_Single_Line : '' : '' } onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'CMOS_VOA_Single_Line') : null} /> 
                <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'CMOS_VOA_Single_Line', CMOS_VOA_Single_Line: state.customer.CMOS_VOA_Single_Line}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '1%', top: '20%'}}></i>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.matched_address'] : '' }
                </div>
                <div style={{display: 'grid', cursor: 'pointer'}} onClick={(e) => {window.blur(); if (state.customer?.AddressBase_Address) window.open(`https://www.google.com/maps/search/?api=1&query=${state.customer ? state.customer.AddressBase_Address : ''}`)}} >
                    <input style={{cursor: 'pointer'}} type="text" className="data-row-field colour-blue" value={ state.customer ? state.customer.AddressBase_Address : '' } onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'AddressBase_Address') : null} /> 
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'AddressBase_Address', AddressBase_Address: state.customer.AddressBase_Address}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '1%', top: '20%'}}></i>
                </div>
            </div>
            <div className="left-grid-bottom">
            <div className="data-row" style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.company_name'] : '' }
                    </div>
                    <input type="text" className="data-row-field" value={ state.customer ? state.customer.Company_Name ? `${state.customer.Company_Name}` : '' : '' } onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'Company_Name') : null} /> 
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'Company_Name', Company_Name: state.customer.Company_Name}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '1%', top: '20%'}}></i>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.company_number'] : '' }
                    </div>
                    <input type="text" className="data-row-field" value={ state.customer ? state.customer.Company_Number ? `${state.customer.Company_Number}` : '' : '' } onChange={(event) => state.customer?.SPID ? updateCustomerProperty(event, 'Company_Number') : null} /> 
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'Company_Number', Company_Number: state.customer.Company_Number}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '3%', top: '20%'}}></i>
                </div>
            </div>
            <div className="left-grid-bottom"> 
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.website'] : '' }
                    </div>
                    <input type="text" className="data-row-field" value={ state.customer?.Website ?? ''} onChange={(event) => parseInt(event.target?.value?.replace(' ', '')) && state.customer?.SPID ? updateCustomerProperty(event, 'Website') : null} /> 
                    <i onClick={() => state.customer?.Website ? window.open(!state.customer.Website.includes('http://') && !state.customer.Website.includes('https://') ? "https://" + state.customer.Website : state.customer.Website) : ''} className="fa-solid fa-up-right-from-square" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '10%', top: '20%'}}></i>
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'Website', Website: state.customer.Website}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '2%', top: '20%'}}></i>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.landline'] : '' }
                    </div>
                    {/* <div className="data-row-field input-like-div">{ state.customer && state.customer.Landline && ( <>{state.customer.Landline} <i onClick={() => setCustomerNumber(state.customer.Landline)} className="fa-solid fa-phone" style={{color: "var(--primary)", paddingLeft: "10px", cursor: 'pointer'}}></i></> )}</div> */}
                    <input type="text" className="data-row-field" value={ state.customer?.Landline ?? ''} onChange={(event) => parseInt(event.target?.value?.replace(' ', '')) && state.customer?.SPID ? updateCustomerProperty(event, 'Landline') : null} /> 
                    <i onClick={() => setCustomerNumber(state.customer.Landline)} className="fa-solid fa-phone" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '10%', top: '20%'}}></i>
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'Landline', Landline: state.customer.Landline}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '2%', top: '20%'}}></i>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.mobile'] : '' }
                    </div>
                    <input className="data-row-field" value={ state.customer?.Mobile ?? ''} onChange={(event) => parseInt(event.target?.value?.replace(' ', '')) && state.customer?.SPID ? updateCustomerProperty(event, 'Mobile') : null} /> 
                    <i onClick={() => setCustomerNumber(state.customer.Mobile)} className="fa-solid fa-phone" style={{color: "var(--primary)", paddingLeft: "10px", cursor: 'pointer', position: 'absolute', right: '10%', top: '20%'}}></i>
                    <i onClick={() => state.customer?.SPID ? request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY, {customer: state.customer.SPID, property: 'Mobile', Mobile: state.customer.Mobile}) : null} className="fa-solid fa-circle-check" style={{color: "var(--primary)", cursor: 'pointer', position: 'absolute', right: '3%', top: '20%'}}></i>
                </div>
            </div>
        </>
    )
}

export function RightColumn({ state, children }) {
    return (
        <div className="right-grid">
            {
                children
            }
            {/* <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.type'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={state.customer ? state.customer.Type : ''}/>
            </div> */}
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.void_age_band'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Void_Age_Band : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    Assigned to
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.assigned_to : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.wholesaler'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Wholesaler : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.occupier_score'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Occupier_Score : '' }/>
            </div>
        </div>
    )
}

// const MapsLink = ({ children, address, className }) => {
//     const [center, setCenter] = React.useState({});
//     const [reload, setReload] = React.useState(false)
//     const [current, setCurrent] = React.useState('')

//     const newChild = useMemo(() => {
//         const child = React.cloneElement(children, {
//             key: new Date().getTime(),
//             style: {
//                 cursor: 'pointer'
//             },
//             onClick: () => alert('open')
//         });
//         // child.style.cursor = 'pointer';
//         return child
//     }, [children])

//     const {isLoaded, loadError} = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
//     })

//     React.useEffect(() => {
//         if (isLoaded && (address !== current)) {
//             updateMap()
//             setCurrent(address)
//         }
//     }, [address, isLoaded])

//     const updateMap = () => {
//         setReload(true)
//         const geocoder = new window.google.maps.Geocoder();

//         geocoder.geocode({address: address}, (res, status) => {
//             if (status === "OK") {
//                 setCenter(res[0].geometry.location);
//                 setReload(false)
//             }
//         });
//     }

//     return (
//         !reload ? isLoaded ? newChild : children : children
//     )
// }