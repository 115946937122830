import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function Dropzone(props) {
    const onDrop = useCallback((acceptedFiles) => {
        props.onDrop(acceptedFiles);
    }, [props]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()}  style={ props.style ?? {} } className={"dropzone " + props.className ?? ''}>
            <input {...getInputProps()} multiple={false}/>
            {props.customText ?
                props.customText
            :
                isDragActive ? (
                    <p>Drop the file here ...</p>
                ) : (
                        <p>Drop file here, or click to select a file</p>
                    )
            }
        </div>
    );
}
