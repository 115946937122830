import React, { Component } from 'react'
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import request from '../helpers/request'
import Card from '../components/card/card'
import { view } from '@risingstack/react-easy-state'
import HelpVideo from '../components/help-video/helpVideo'

export default view(class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                id: null,
                name: '',
                current_password: '',
                password: '',
                repeat_password: '',
            },
            message: null,
            errors: {
                name: [],
                email: [],
                password: [],
                repeat_password: [],
            },
            passwordStrong: false,
            passwordMatch: false,
            expires_soon: false,
            days_to_expire: 0,
        }
    }

    componentDidMount() {
        request(true).get('/self').then(e => {
            this.setState({
                user: {
                    id: e.data.id,
                    name: e.data.name,
                },
                expires_soon: e.data.password_expires_in < 5,
                days_to_expire: e.data.password_expires_in
            });
        }).catch(e => {
            console.error(e);
        })
    }

    updateFormValue = (e) => {
        let passwordStrong = this.state.passwordStrong;

        if (e.target.name === 'password') {
            if (e.target.value.match(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/)) {
                passwordStrong = true;
            } else {
                passwordStrong = false;
            }
        }

        this.setState({
            user: {
                ...this.state.user,
                [e.target.name]: e.target.value ? e.target.value : undefined
            },
            passwordStrong: passwordStrong
        }, () => {
            this.setState({
                passwordMatch: this.state.passwordStrong && this.state.user.password === this.state.user.repeat_password
            });
        });
    }

    formSubmit = (e) => {
        if (this.state.user.password !== this.state.user.repeat_password) {
            this.setState({
                errors: {
                    repeat_password: ['This password does not match.'],
                    ...this.state.errors
                }
            });

            return;
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    repeat_password: []
                }
            });
        }

        request(true).post('/self', this.state.user).then(r => {
            this.setState({ message: 'User Updated' });
            window.location.href = '/portal';
        }).catch(r => {
            this.setState({
                errors: r.response.data.errors
            });
            console.error(r);
        });
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                <Container>
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Your Account</p>
                    </div>
                    <Card>
                        <div className="grid grid-gap-15">
                            <div className="form-row">
                                <label htmlFor="name">Company:</label>
                                <input name="name" placeholder="Company" className="input readonly" type="text" value={ this.state.user.company ? this.state.user.company.name : 'Administrator' } required readOnly={true}/>
                            </div>
                            <div className="form-row">
                                <label htmlFor="name">Name:</label>
                                <input onChange={this.updateFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.user.name} required/>
                                { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            { this.state.expires_soon ? <p className="text-center background-yellow colour-white" style={{padding: 5, borderRadius: '5px'}}><strong>Important Notice:</strong>
                                <br/>
                                Your password will expire in {this.state.days_to_expire} day{this.state.days_to_expire === 1 ? '' : 's'}. Please update it soon to ensure uninterrupted access.
                                <br/>
                                If you need assistance, contact support. Thank you!</p> : null } 
                            <div className="form-row">
                                <label htmlFor="current_password">Current Password:</label>
                                <input onChange={this.updateFormValue} name="current_password" placeholder="Current Password" className="input" type="password" value={this.state.user.current_password} required/>
                                { this.state.errors.current_password ? this.state.errors.current_password.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <div className="form-row">
                                <label htmlFor="password">New Password:</label>
                                <input onChange={this.updateFormValue} name="password" placeholder="New Password" className={`input ${this.state.passwordStrong ? 'color-green' : ''}`} type="password" value={this.state.user.password} required/>
                                <small>Passwords must be at least 12 characters long and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</small>
                                { this.state.errors.password ? this.state.errors.password.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <div className="form-row">
                                <label htmlFor="repeat_password">Repeat Password:</label>
                                <input onChange={this.updateFormValue} name="repeat_password" placeholder="Repeat New Password" className={`input ${this.state.passwordMatch ? 'color-green' : ''}`} type="password" value={this.state.user.repeat_password} required/>
                                { this.state.errors.repeat_password ? this.state.errors.repeat_password.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <button onClick={this.formSubmit} className="button background-primary colour-white">Update</button>
                            <p className="text-center">{ this.state.message }</p>
                        </div>
                    </Card>
                    <HelpVideo video={sessionStorage.getItem('login-video-path')}/>
                </Container>
                <Footer />
            </div>
        )
    }
})
