import React, { useEffect, useState } from 'react'
import Page from '../../../components/page/page'
import PropertyMap from '../../../components/property-map/property-map';
import request from '../../../helpers/request';
import { DataRow } from '../CompaniesHouseAlerts/sharedComponents';
import { CotValidationTitle } from './cotValidation'
import { CotValidationAccountDetails, CotValidationClientDetails, CotValidationMatchedDetails, CotValidationMatchStatus, CotValidationOccupierFootprint, SearchRow } from './cotValidationShared'
import './cotValidation.scss';
import endpoints from '../../../helpers/endpoints';
export default function CotValidationSearch() {
  const [translations, setTranslations] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [customer, setCustomer] = useState(null)

  useEffect(() => {
    request(true).get('/translate?key=cotval.').then(r => {
      setTranslations(r.data)
    }).catch(e => {
        console.error(e);
    })
  }, [])

  const getData = () => {
    request(true).get(endpoints.COT_VALIDATION_GET_CUSTOMER, {
      params: {
        search: searchValue
      }
    }).then(e => {
      if (!e.data.customer) window.alert('Account not found.');
      setCustomer(e.data.customer)
    })
  }

  return (
    <Page title={'Cleanse & Validation - Search'} titleRight={<CotValidationTitle showS3={false} />}>
      <div className="CotValidation">
        <div className="grid-overview">
          <div className="left">
            <div className="grid grid-columns-2 grid-gap-15">
              <CotValidationClientDetails data={customer} translations={translations}>
                <SearchRow
                  rowTitle={'Account Number'}
                  value={searchValue}
                  onChange={setSearchValue}
                  doAction={getData}
                />
              </CotValidationClientDetails>
              <CotValidationMatchedDetails data={customer} translations={translations}>
                <DataRow 
                title={'cotval.status'} 
                translations={translations} 
                customValue={customer ? `${customer.Residency_Status} ${customer.Date_F}` : ''} />
              </CotValidationMatchedDetails>
            </div>
          </div>
          <div className="right">
            <CotValidationMatchStatus data={customer} translations={translations} />
          </div>
        </div>
        <div className="grid grid-columns-4 grid-gap-15">
          <div style={{gridColumnStart: 1, gridColumnEnd: 3, display: 'grid'}}>
            <PropertyMap address={customer ? customer.Address_Client : null}  />
          </div>
          <CotValidationOccupierFootprint data={customer} />
          <CotValidationAccountDetails data={customer} />
        </div>
      </div>
    </Page>
  )
}
