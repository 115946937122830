import React from 'react'

import styles from './GenericDataRow.module.scss'

export const GenericDataRowContent = ({children, contentColour}) => {
  return (
    <div className={styles['data-row-field']} style={{backgroundColor: contentColour || undefined}}>
      {children}
    </div>
  )
}

export const GenericDataRowTitle = ({title}) => {
  return (
    <div className={styles['data-row-title']}>{title}</div>
  )
}

export default function GenericDataRow({
  title,
  children,
  contentColour
}) {
  return (
    <div className={styles.container}>
      <div className={styles['data-row-title']}>{title}</div>
      <GenericDataRowContent children={children} contentColour={contentColour} />
    </div>
  )
}
