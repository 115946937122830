import React, { Component, useMemo, useEffect } from "react";
import session from "../../stores/session";
import { view } from "@risingstack/react-easy-state";
import request from "../../helpers/request";
import endpoints from "../../helpers/endpoints";
import { useState } from 'react';

export default view(function WelcomeText({showNotes}) {
  const [versionDate, setVersionDate] = useState();
  const [refreshDate, setRefreshDate] = useState();
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth(); 
    let dd = date.getDate();
    return `${dd}-${months[mm]}-${yyyy}`;
  }

  useEffect(() => {
    if (versionDate) return
    request(true).get(endpoints.ADMIN_VERSION_HISTORY_RECENT_DATE).then(r => {
      if (r.data) {
        const date = new Date(r.data.Note_Date)
        const yyyy = date.getFullYear();
        let mm = date.getMonth(); 
        let dd = date.getDate();
        setVersionDate(`${dd}-${months[mm]}-${yyyy}`)
      }
    });
  })

  const currentGreeting = useMemo(() => {
    let currentHour = new Date().getHours();

    if (currentHour >= 18) {
      return "Good evening";
    } else if (currentHour >= 12) {
      return "Good afternoon";
    } else {
      return "Good morning";
    }
  }, []);

  return (
    <div className="font-align-center">
      <p
        className="font-weight-600 font-size-2 colour-secondary"
        style={{ padding: "0 1em 0 1em" }}
      >
        {currentGreeting}, {session.user.name}!
      </p>
      <p className="colour-gray">Welcome to the {session.company?.branding?.name}, version {process.env.REACT_APP_VERSION}{versionDate && ` released on the ${versionDate}`}.</p>
      {refreshDate && <p className="colour-gray">Last refreshed on {refreshDate}.</p>}
      <p className="colour-gray">Click <u style={{ color: 'blue', cursor: 'pointer' }} onClick={showNotes}>here</u> to see release notes.</p>
    </div>
  );
})
