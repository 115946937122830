import React from 'react'
import Page from '../../../components/page/page'
import { CotValidationTitle } from './cotValidation'
import './cotValidation.scss';
export default function CotValidationWorkflow() {
  return (
    <Page title={'Cleanse & Validation - Workflow'} titleRight={<CotValidationTitle showS3={false} />}>
      
    </Page>
  )
}
