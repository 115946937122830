import React from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request';
import { matchPath, withRouter } from 'react-router-dom';
import endpoints from '../../helpers/endpoints';

export default withRouter(function ({ ...props }) {
    const [products, setProducts] = React.useState();
    const [tutorials, setTutorials] = React.useState([]);

    const loadTutorials = React.useCallback(() => {
        request(true).get(endpoints.PRODUCTS).then(r => {
            const tutorialsArray = [];
            r.data.map(tutorial => {
                tutorialsArray.push({
                    tutorial_url: tutorial.chart_explainer_image_url,
                    video_link_url: tutorial.video_explainer_url,
                    name: tutorial.name,
                    portal: tutorial.icon ? tutorial.icon === "fak fa-id-business-icon" ? "BD" : "COT" : null,
                    id: tutorial.id
                })
            });
            setTutorials(tutorialsArray);
            setProducts(r.data)
        })
    })

    const updateTutorialLinks = (event) => {
        let tutorialsArray = [].concat(tutorials);
        const tutorial = tutorials.find(tutorial => tutorial.id == event.target.id);
        tutorial[event.target.name == "i_screen_bucket" ? 'tutorial_url' : 'video_link_url'] = event.target.value;
        setTutorials(tutorialsArray);
    }

    const submit = () => {
        let productsArray = products;
        productsArray.map(product => {
            const tutorial = tutorials.find(tutorial => tutorial.id == product.id);
            if (tutorial) {
                product.chart_explainer_image_url = tutorial.tutorial_url ? tutorial.tutorial_url : null
                product.video_explainer_url = tutorial.video_link_url ? tutorial.video_link_url : null
            }
            return product;
        })

        setProducts(productsArray);

        request(true).post(endpoints.ADMIN_PRODUCTS_UPDATE_TUTORIALS, {
            'products': products
        }).catch(r => {
            console.log(r);
        });
    }

    React.useEffect(() => {
        loadTutorials();
    }, []);

    return (
        <div className="grid grid-gap-20">
            <Navigation/>
            <Container>
                <div className="flex middle">
                    <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Tutorials</p>
                </div>
                <div>
                    { tutorials.filter(tutorial => tutorial.portal === "COT").length ?
                        <>
                            <div>
                                <h3>COT Portal</h3>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Information Screen Bucket</th>
                                        <th>Video Link Bucket</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { tutorials.filter(tutorial => tutorial.portal === "COT").map(tutorial => {
                                        return (
                                            <tr>
                                                <td>{tutorial.name}</td>
                                                <td>
                                                    <input id={tutorial.id} onChange={updateTutorialLinks} name="i_screen_bucket" placeholder="Information Screen Bucket" className="input slim" type="text" value={tutorial.tutorial_url ?? ""} />
                                                </td>
                                                <td>
                                                    <input id={tutorial.id} onChange={updateTutorialLinks} name="video_link_bucket" placeholder="Video Link Bucket" className="input slim" type="text" value={tutorial.video_link_url ?? ""} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        <button onClick={submit} className="button background-primary colour-white">Update</button>
                        </>
                    :
                        null
                    }
                    { tutorials.filter(tutorial => tutorial.portal === "BD").length ?
                        <>
                            <div>
                                <h3>BD Portal</h3>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Information Screen Bucket</th>
                                        <th>Video Link Bucket</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { tutorials.filter(tutorial => tutorial.portal === "BD").map(tutorial => {
                                        return (
                                            <tr>
                                                <td>{tutorial.name}</td>
                                                <td>
                                                    <input id={tutorial.id} onChange={updateTutorialLinks} name="i_screen_bucket" placeholder="Information Screen Bucket" className="input slim" type="text" value={tutorial.tutorial_url ?? ""} />
                                                </td>
                                                <td>
                                                    <input id={tutorial.id} onChange={updateTutorialLinks} name="video_link_bucket" placeholder="Video Link Bucket" className="input slim" type="text" value={tutorial.video_link_url ?? ""} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        <button onClick={submit} className="button background-primary colour-white">Update</button>
                        </>
                    :
                        null
                    }
                </div>
            </Container>
            <Footer/>
        </div>
    )
})