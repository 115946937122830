import React, { useEffect, useState, useRef } from "react";
import endpoints from "../../helpers/endpoints";
import { loadModules } from 'esri-loader';
import Modal from 'react-modal'
import './arcgis.scss'

export default function ArcgisMap({ address, isModal=false, closeModal }) {
    const [searchWidget, setSearchWidget] = useState();
    const mapContainerRef = useRef(null);
    const mapContainerModalRef = useRef(null);

    useEffect(() => {
        if (searchWidget && address) {
            searchWidget.search(address)
        }
    }, [address, searchWidget])

    useEffect(() => {
        initMap()
    }, [])

    useEffect(() => {
        if (!isModal && mapContainerModalRef.current) {
            const mapWrapper = document.getElementById('map-wrapper');
            mapWrapper.appendChild(mapContainerModalRef.current.firstChild);
        }
    }, [isModal])


    const initMap = () => {
        loadModules(['esri/Map', 'esri/views/MapView', 'esri/WebMap', 'esri/layers/WMSLayer', 'esri/config', 'esri/widgets/Swipe', 'esri/widgets/Search'], { css: true })
        .then(([Map, MapView, WebMap, WMSLayer, esriConfig, Swipe, Search]) => {
    
            const proxyUrl = `${endpoints.BASE + endpoints.PROPRIETOR_VALIDATION_ARCGIS_PROXY}?url=`;
    
            esriConfig.request.interceptors.push({
                urls: 'https://inspire.landregistry.gov.uk',
                before: function(params) {
                    console.log(params)
                    if (params.url.includes('https://inspire.landregistry.gov.uk/inspire/ows')) {
                        params.url = params.url.replace('https://inspire.landregistry.gov.uk/inspire/ows', proxyUrl + 'https://inspire.landregistry.gov.uk/inspire/ows'); 
                    }
                }
            });
    
            const map = new WebMap({
                portalItem: {
                  id: '8a371f9c68634ff3b4235f0a6ae90430'
                },
                authentication: {
                  apiKey: process.env.ARCGIS_API_KEY
                }
            });
    
            const view = new MapView({
                container: 'mapContainer',
                map: map,
                center: [-0.1402, 51.4908],
                zoom: 15
            });
    
            map.when(() => {
                const existingLayers = map.layers.toArray();
          
                const wmsLayer = new WMSLayer({
                    url: proxyUrl + 'https://inspire.landregistry.gov.uk/inspire/ows',
                    sublayers: [{
                        name: 'inspire:CP.CadastralParcel',
                        title: 'CP.CadastralParcel'
                    }],
                    version: '1.3.0',
                    format: 'image/png',
                    transparent: true
                });
    
                map.add(wmsLayer, existingLayers.length);
        
                const swipe = new Swipe({
                    view: view,
                    leadingLayers: [wmsLayer, existingLayers[1]],
                    trailingLayers: [wmsLayer, existingLayers[0], existingLayers[1]]
                });
    
                view.ui.add(swipe);
    
                const searchWidget = new Search({
                    view: view,
                    sources: 
                        {
                          url: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer",
                          singleLineFieldName: "SingleLine",
                          outFields: ["Addr_type"],
                          placeholder: "Address",
                          resultSymbol: {
                             type: "picture-marker",
                             size: 24,
                             width: 24,
                             height: 24,
                             xoffset: 0,
                             yoffset: 0
                         }
                    },
                    popupEnabled: false
                });
                
                view.ui.add(searchWidget, 'top-right');
    
                setSearchWidget(searchWidget)

                if (address) {
                    searchWidget.search(address)
                }
            });
        }).catch((err) => console.error(err));
    }

    return    [

        <Modal
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' }, content: { height: '92vh', width: '92vw' } }}
            className="card"
            isOpen={isModal}
            onRequestClose={() => closeModal()}
            contentLabel="Add A User"
            appElement={document.getElementById('root')}
            onAfterOpen={() => {
                const mapWrapperModal = document.getElementById('map-wrapper-modal');
                mapWrapperModal.appendChild(mapContainerRef.current.firstChild);
            }}
        >
            <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                <div className='mapContainer' ref={mapContainerModalRef} id='map-wrapper-modal'>

                </div>
                <i class="fa-solid fa-xmark" style={{cursor: 'pointer', marginLeft: '10px'}} onClick={() => closeModal()}></i>
            </div>
        </Modal>,
        <div className='mapWrapper' ref={mapContainerRef} id='map-wrapper'>
            <div className='mapContainer' id='mapContainer'></div>
        </div>
    ]    

}
