import React, { useMemo, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation';
import ProductSelect from '../../components/product-select/productSelect'
import request from '../../helpers/request';
import session from '../../stores/session'

import {
    ColumnDef,
    Row,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
  
import {
DndContext,
KeyboardSensor,
MouseSensor,
TouchSensor,
closestCenter,
useSensor,
useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
arrayMove,
SortableContext,
verticalListSortingStrategy,
} from '@dnd-kit/sortable';
  
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function Groupings() {
    const [product, setProduct] = useState(undefined);
    const [groupings, setGroupings] = useState([]);
    const dataIds = useMemo(() => groupings.map(({ id }) => id), [groupings]);
    const [loading, setLoading] = useState(false)

    const OrderCell = ({ row }) => {
      const [input, setInput] = useState(row.original.order);
      return (
        <input
        size={3}
          onChange={(event) => {
            console.log(row)
            if (!event.target.value.match(/^[0-9]*$/)) return
            setInput(Number(event.target.value))
          }}
          onBlur={() => {
            const index = groupings.findIndex(item => item.id === row.original.id)
            const newGroupings = [...groupings];
            newGroupings[index].order = input;
            newGroupings.sort((a, b) => a.order - b.order);
            setGroupings(newGroupings);
            submitNewOrder([groupings[index]]);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              const index = groupings.findIndex(item => item.id === row.original.id)
              const newGroupings = [...groupings];
              newGroupings[index].order = input;
              newGroupings.sort((a, b) => a.order - b.order);
              setGroupings(newGroupings);
              submitNewOrder([groupings[index]]);
            }
          }}
          value={input} 
          className="button bulk-buy-button compact" 
          style={{ margin: '0px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}
        />
      )
    }

    const columns = useMemo(
        () => [
          {
            accessorKey: 'internal_name',
            header: () => 'Internal Name',
          },
          {
            accessorKey: 'title',
            header: () => 'Title',
          },
          {
            accessorKey: 'target_column',
            header: "Targets"
          },
          {
            cell: ({ row }) =>
              <div className="grid grid-columns-2 grid-gap-10">
                <Link to={`/admin/portal/groupings/${row.id}`}><button className="button compact background-primary colour-white">Edit</button></Link>
                <button onClick={() => deleteGroup(row.id)} className="button compact background-yellow colour-white">Delete</button>
              </div>,
            header: 'Actions',
          },
          {
            cell: ({ row }) => <OrderCell row={row} />,
            header: 'Order',
          },
          {
            id: 'copy',
            cell: ({ row }) => 
              <button className='button compact' onClick={() => copyGroup(row.id)}><span className='fas fa-copy'></span></button>
          },
          {
            id: 'drag-handle',
            header: '',
            cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
          },
        ],
        [groupings]
      );
    

    useEffect(() => {
        setProduct(session.groupSelectedProduct)
    }, [])

    const getDataGroupings = () => {
        setLoading(true)

        request(true).get('/datagroupings', {
            params: {
                ...(product && { real_product_id: product }),
            }
        }).then(e => {
            setGroupings(e.data.sort((a, b) => a.order - b.order));
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setGroupings([])
            setLoading(false)
            window.alert("Could not retrieve groups")
          })
    }

    const addQueryData = useEffect(() => {
        session.groupSelectedProduct = product
        if (product !== undefined) {
          getDataGroupings();
        } else {
          setGroupings([])
        }
      }, [product])

    const deleteGroup = (id) => {
        if (!window.confirm('Delete grouping?')) return
        request(true).delete('/datagroupings/'+id).then(() => {
            getDataGroupings()
        })
    }

    const copyGroup = (id) => {
      request(true).post(`/datagroupings/${id}/copy`, {
        newName: window.prompt('Copy Name Key')
      }).then(() => {
        getDataGroupings()
        window.alert('Coppied')
      }).catch(() => {
        window.alert('Failed to copy')
      })
    }

    const submitNewOrder = (rows) => {
        const newGroupings = [...groupings];
        rows.forEach(row => {
          newGroupings[groupings.findIndex(group => group.id === row.id)] = row
        })
        setGroupings(newGroupings.sort((a, b) => a.order - b.order));
    
        rows.forEach(row => {
          request(true).post(`/datagroupings/${row.id}`, {
            ...row
          })
        });
    
      }
    
      const table = useReactTable({
        data: groupings,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        autoResetAll: false
      });
    
      const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
          const activeIndex = groupings.findIndex(item => item.id === active.id);
          const overIndex = groupings.findIndex(item => item.id === over.id);
    
          const activeItem = groupings[activeIndex];
          const overItem = groupings[overIndex];
    
          if (activeItem.order <= overItem.order) {
            activeItem.order = overItem.order + 1;
          } else if (activeItem.order > overItem.order) {
            activeItem.order = overItem.order - 1;
            if (activeItem.order === -1) {
              activeItem.order = 0
              overItem.order = 1
            }
          }
    
          submitNewOrder([activeItem, overItem])
        }
      }
      
      const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor)
      );
    
      const RowDragHandleCell = React.memo(({ rowId }) => {
        const { attributes, listeners } = useSortable({ id: rowId });
        return (
          <i {...attributes} {...listeners} class="fa-solid fa-bars fa-lg"></i>
        );
      });
      
      const DraggableRow = ({ row }) => {
        const { transform, transition, setNodeRef, isDragging } = useSortable({
          id: row.original.id,
        });
    
        const style = {
          transform: CSS.Transform.toString(transform),
          transition: transition,
          opacity: isDragging ? 0.8 : 1,
          zIndex: isDragging ? 1 : 0,
          position: 'relative',
        };
    
        return (
          <tr
            className={product ? 'tr-drag-bars' : ''}
            ref={setNodeRef}
            style={style}
          >
            {row.getVisibleCells().map(cell => (
              <td
                key={cell.id}
                className={cell.column.id === 'drag-handle' ? 'drag-bars' : ''}
              >
                {cell.column.id === 'drag-handle' ? (
                    <RowDragHandleCell rowId={row.original.id} />
                  )
                : (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                )}
              </td>
            ))}
          </tr>
        );
      }

    return (
        <div className="grid grid-gap-20">
            <Navigation />
            <Container>
                <div className="flex middle">
                    <p className="colour-secondary font-weight-600" style={{ fontSize: 20 }}>Data Groupings</p>
                    <div className="flex" style={{ marginLeft: 'auto', gap: '10px' }}>
                        <div>
                            <Link to="/admin/portal/groupings/new"><button className="button compact background-primary colour-white">New Data Grouping</button></Link>
                        </div>
                        <div style={{display: 'flex'}}>
                            <ProductSelect product={product} onChange={(e) => setProduct(e ? e.value : undefined)} />
                        </div>
                    </div>
                </div>
            </Container>
            <Container>
                {/* <table className="table">
                    <thead>
                        <tr>
                            <th>Internal Name</th>
                            <th>Title</th>
                            <th>Target</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            loading ? 
                                <td colSpan={4} style={{ textAlign: 'center' }} >
                                    <i className="fa fa-spinner fa-spin" style={{ margin: '1em' }} />
                                </td>
                            : ''
                        }
                        {
                            groupings.map(grouping => {
                                return (
                                    <tr key={grouping.id}>
                                        <td>{grouping.internal_name}</td>
                                        <td>{grouping.title}</td>
                                        <td>{grouping.target_column}</td>
                                        <td>
                                            <div className="grid grid-columns-3 grid-gap-5">
                                                <Link to={`/admin/portal/groupings/${grouping.id}`}><button className="button compact background-primary colour-white">Edit</button></Link>
                                                <button onClick={() => deleteGroup(grouping.id)} className="button compact background-red colour-white">Delete</button>
                                                <button className='button compact' onClick={() => copyGroup(grouping.id)}><span className='fas fa-copy'></span></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table> */}
                <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={handleDragEnd}
                sensors={sensors}
                >
                <table className="table">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    header.id !== "drag-handle" &&
                                    <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            { loading && <tr><td colSpan={'6'}><i className="fa fa-spinner fa-spin fa-lg" /></td></tr> }
                            <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                                {table.getRowModel().rows.map(row => (
                                    <DraggableRow key={row.id} row={row} />
                                ))}
                            </SortableContext>
                        </tbody>
                    </table>
                </DndContext>
            </Container>
        </div>
    )
}
