import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import endpoints from "../../../helpers/endpoints";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import Text from 'recharts/lib/component/Text';
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';

export default function InvalidUprnCmosVoa() {
    const [filters, setFilters] = useState({});
    const [selectedGroupBy, setSelectectGroupBy] = useState("Connection_Type");
    const [data, setData] = useState(null);
    const [dates, setDates] = useState([]);
    const [date, setDate] = useState(null);
    const [S3Model, setS3Model] = useState(null);
    const [bulkModel, setBulkModel] = useState('')
    
    const CustomizedAxisTickMultiLine = ({x, y, payload}) => {
        console.log(x);
        console.log(y);
        console.log(payload);
        return (
           <Text x={x} y={y} width={90} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
        )
    };

    const applyFilter = (column, value) => {
        setFilters((current) => {
            return {
                ...current,
                [column]: value,
            };
        });
    };

    useEffect(() => {
        request(true)
            .get(endpoints.INVALID_UPRN_VOA_CMOS_DATES)
            .then((e) => {
                setDates(e.data.dates);
                if (e.data.dates.length > 0) setDate("All_Refreshes");
            });
    }, []);

    useEffect(() => {
        if (!date) return;
        request(true)
            .get(endpoints.INVALID_UPRN_VOA_CMOS_DATA, {
                doesCancel: true,
                params: {
                    date: date,
                    filter: filters,
                    group: selectedGroupBy,
                },
            })
            .then((e) => {
                setData(e.data);
            });
    }, [filters, selectedGroupBy, date]);

    return [
        <GenericPageContainer
        title="Invalid UPRN/VOA References - Summary"
        titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
        titleRight={
            <div>
                <Link className="navigate" to="/portal/invalid-uprn-voa-cmos/search">
                    To Search
                </Link>
                { (hasPermission('INVALID_UPRN_VOA_CMOS', 'WORKFLOW')) ?
                <Link className="navigate" to="/portal/invalid-uprn-voa-cmos/workflow">
                    To Workflow
                </Link>
                : null }
                {hasPermission('INVALID_UPRN_VOA_CMOS', 'DOWNLOAD') ? (
                <button className="navigate" onClick={() => setS3Model("download")}>
                    Download Return File
                </button>
                ) : null}
            </div>
        }>
        <div style={{maxWidth: '20vw', marginBottom: 15}}>
            <GenericDataRow title={"File Date"} contentColour={"white"}>
            <select
                onChange={(_) => setDate(_.target.value)}
                value={date}
                style={{
                width: "100%",
                height: "100%",
                border: "none",
                backgroundColor: "white",
                paddingLeft: "10px",
                }}
            >
                <option key={"All_Refreshes"} value={"All_Refreshes"}>All Refreshes</option>
                {dates.map((item) => {
                    return (
                        <option key={item.raw} value={item.raw}>
                        {item.pretty}
                        </option>
                    );
                })}
            </select>
        </GenericDataRow>
        </div>
        <div className='HP'>
            <GenericDataChart
                dataReady={!!data}
                data={data}
                selectedGroupBy={selectedGroupBy}
                setSelectectGroupBy={setSelectectGroupBy}
                groupingsEndpoint={endpoints.INVALID_UPRN_VOA_CMOS_GROUPINGS}
                sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
                xAxisProps={{interval: 0}}
                customBarTick={CustomizedAxisTickMultiLine}
            />
            <GenericProductFilterContainer clearFilters={() => setFilters({})}>
                <ProductDataFilter
                filterState={filters}
                applyFilter={applyFilter}
                dataFilterEndpoint={endpoints.INVALID_UPRN_VOA_CMOS_FILTERS}
                />
                <div className='grid grid-columns-2 grid-gap-5 mta'>
                    { (hasPermission('INVALID_UPRN_VOA_CMOS', 'BULK-BUY')) ?
                        <button style={{  gridColumn: 'span 2' }} className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                            Create Download File
                        </button>
                    : '' }
                    { (hasPermission('INVALID_UPRN_VOA_CMOS', 'ALLOCATE')) ?
                        <button style={{ gridColumn: !hasPermission('INVALID_UPRN_VOA_CMOS', 'UNALLOCATE') && 'span 2' }} 
                            className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                            Allocate
                        </button>
                    : '' }
                    { (hasPermission('INVALID_UPRN_VOA_CMOS', 'UNALLOCATE')) ?
                        <button style={{ gridColumn: !hasPermission('INVALID_UPRN_VOA_CMOS', 'ALLOCATE') && 'span 2' }}
                        className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate')}>
                            Unallocate
                        </button>
                    : '' }
                </div>
            </GenericProductFilterContainer>
        </div>
      </GenericPageContainer>,
        <S3Modal
        closeModal={() => setS3Model(null)}
        modalMode={S3Model}
        showModal={!!S3Model}
        listingUrl={endpoints.INVALID_UPRN_VOA_CMOS_FILES}
        uploadUrl={endpoints.INVALID_UPRN_VOA_CMOS_FILES}
        />,
        <BulkBuy 
        SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
        SentenceSecond={() => <></>}
        purchaseLabel="Download Limit:"
        buyButtonText="Create File"
        completeOnFirstStep={true} 
        workflowUsersEndpoint={endpoints.INVALID_UPRN_VOA_CMOS_WF_USERS} 
        bulkEndpoint={endpoints.INVALID_UPRN_VOA_CMOS_BULK_BASE} 
        modalIsOpen={bulkModel === 'purchase'} 
        closeModal={() => setBulkModel('')} 
        filters={filters} 
        date={date} />,
        <BulkAllocate 
        workflowUsersEndpoint={endpoints.INVALID_UPRN_VOA_CMOS_WF_USERS} 
        bulkEndpoint={endpoints.INVALID_UPRN_VOA_CMOS_BULK_BASE} 
        unallocateEndpoint={endpoints.INVALID_UPRN_VOA_CMOS_UNALLOCATE} 
        modalIsOpen={bulkModel === 'allocate' || bulkModel === "unallocate"} 
        mode={bulkModel === "unallocate" ? "unallocate" : null}
        closeModal={() => setBulkModel('')} 
        filters={filters} 
        date={date} />,
    ];
}