module.exports = Object.freeze({
    BASE: process.env.REACT_APP_BASE_URL || 'http://identeq-app.local:8000',
    DOMAIN: '/domain',
    LOGIN: '/auth/login',
    TWOFA: '/auth/token',

    AZURE_CALLBACK: '/auth/azure/callback',

    ACTIVATE: '/auth/activate',
    ACTIVATE_POST: '/auth/activate/post',

    PRODUCTS: '/products',

    AUDITS: '/audits',

    USAGE: '/usage',

    BOOKMARKS: '/bookmarks',
    SET_BOOKMARK_ORDER: 'bookmarks/order',

    CONTACT_VALIDATION: '/products/contact-validation',
    CONTACT_VALIDATION_UPLOAD: '/products/contact-validation/files',
    CONTACT_VALIDATION_INDEX: '/products/contact-validation/files',

    VOID_SALES_ALERTS_GET_CUSTOMER: '/products/void-sales-alerts/search',
    VOID_SALES_ALERTS_GET_DATES: '/products/void-sales-alerts/dates',
    VOID_SALES_ALERTS_SUMMARY: '/products/void-sales-alerts',
    VOID_SALES_ALERTS_SUMMARY_BULK: '/products/void-sales-alerts/bulk',
    VOID_SALES_ALERTS_BUY_CUSTOMER: '/products/void-sales-alerts/buy',
    VOID_SALES_ALERTS_FILE_GET: '/products/void-sales-alerts/files',
    VOID_SALES_ALERTS_FILE_UPLOAD: '/products/void-sales-alerts/files',
    VOID_SALES_ALERTS_WORKFLOW_USERS: '/products/void-sales-alerts/workflow/users',
    VOID_SALES_ALERTS_WORKFLOW: '/products/void-sales-alerts/workflow',
    VOID_SALES_ALERTS_EXCLUDE_CUSTOMER: '/products/void-sales-alerts/exclude',
    VOID_SALES_ALERTS_DATA_FILTERS: '/products/void-sales-alerts/data-filters',
    VOID_SALES_ALERTS_GROUPINGS: '/products/void-sales-alerts/groupings',

    PROPRIETOR_VALIDATION_GET_CUSTOMER: '/products/proprietor-validation/search',
    PROPRIETOR_VALIDATION_GET_DATES: '/products/proprietor-validation/dates',
    PROPRIETOR_VALIDATION_SUMMARY: '/products/proprietor-validation',
    PROPRIETOR_VALIDATION_SUMMARY_BULK: '/products/proprietor-validation/bulk',
    PROPRIETOR_VALIDATION_BUY_CUSTOMER: '/products/proprietor-validation/buy',
    PROPRIETOR_VALIDATION_FILE_GET: '/products/proprietor-validation/files',
    PROPRIETOR_VALIDATION_FILE_UPLOAD: '/products/proprietor-validation/files',
    PROPRIETOR_VALIDATION_WORKFLOW_USERS: '/products/proprietor-validation/workflow/users',
    PROPRIETOR_VALIDATION_WORKFLOW: '/products/proprietor-validation/workflow',
    PROPRIETOR_VALIDATION_EXCLUDE_CUSTOMER: '/products/proprietor-validation/exclude',
    PROPRIETOR_VALIDATION_DATA_FILTERS: '/products/proprietor-validation/data-filters',
    PROPRIETOR_VALIDATION_GROUPINGS: '/products/proprietor-validation/groupings',
    PROPRIETOR_VALIDATION_ARCGIS_PROXY: '/arcgis-proxy',

    OCCUPIED_SALE_ALERTS_GET_CUSTOMER: '/products/occupied-sale-alert/search',
    OCCUPIED_SALE_ALERTS_GET_DATES: '/products/occupied-sale-alert/dates',
    OCCUPIED_SALE_ALERTS_SUMMARY: '/products/occupied-sale-alert',
    OCCUPIED_SALE_ALERTS_SUMMARY_BULK: '/products/occupied-sale-alert/bulk',
    OCCUPIED_SALE_ALERTS_BUY_CUSTOMER: '/products/occupied-sale-alert/buy',
    OCCUPIED_SALE_ALERTS_FILE_GET: '/products/occupied-sale-alert/files',
    OCCUPIED_SALE_ALERTS_FILE_UPLOAD: '/products/occupied-sale-alert/files',
    OCCUPIED_SALE_ALERTS_WORKFLOW_USERS: '/products/occupied-sale-alert/workflow/users',
    OCCUPIED_SALE_ALERTS_WORKFLOW: '/products/occupied-sale-alert/workflow',
    OCCUPIED_SALE_ALERTS_EXCLUDE_CUSTOMER: '/products/occupied-sale-alert/exclude',
    OCCUPIED_SALE_ALERTS_DATA_FILTERS: '/products/occupied-sale-alert/data-filters',
    OCCUPIED_SALE_ALERTS_GROUPINGS: '/products/occupied-sale-alert/groupings',

    VOID_COT_ALERTS_GET_CUSTOMER: '/products/void-cot-alerts/search',
    VOID_COT_ALERTS_GET_DATES: '/products/void-cot-alerts/dates',
    VOID_COT_ALERTS_SUMMARY: '/products/void-cot-alerts',
    VOID_COT_ALERTS_SUMMARY_BULK: '/products/void-cot-alerts/bulk',
    VOID_COT_ALERTS_BUY_CUSTOMER: '/products/void-cot-alerts/buy',
    VOID_COT_ALERTS_FILE_GET: '/products/void-cot-alerts/files',
    VOID_COT_ALERTS_FILE_UPLOAD: '/products/void-cot-alerts/files',
    VOID_COT_ALERTS_WORKFLOW_USERS: '/products/void-cot-alerts/workflow/users',
    VOID_COT_ALERTS_WORKFLOW: '/products/void-cot-alerts/workflow',
    VOID_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/void-cot-alerts/exclude',
    VOID_COT_ALERTS_SUMMARY_WATERFALL: '/products/void-cot-alerts/report',
    VOID_COT_ALERTS_SUMMARY_WATERFALL_DATES: '/products/void-cot-alerts/report-dates',
    VOID_COT_ALERTS_DATA_FILTERS: '/products/void-cot-alerts/data-filters',
    VOID_COT_ALERTS_GROUPINGS: '/products/void-cot-alerts/groupings',
    
    VOID_COT_ALERTS_NFI_GET_CUSTOMER: '/products/void-cot-alerts/nfi/search',
    VOID_COT_ALERTS_NFI_GET_CUSTOMER_COMBINED: '/products/void-cot-alerts/nfi/search-combined',
    VOID_COT_ALERTS_NFI_WORKFLOW_COMBINED: '/products/void-cot-alerts/nfi/search-combined-workflow',
    VOID_COT_ALERTS_NFI_EXCLUDE: '/products/void-cot-alerts/nfi/exclude',
    VOID_COT_ALERTS_NFI_SUMMARY_BULK: '/products/void-cot-alerts/nfi/bulk',
    VOID_COT_ALERTS_NFI_BUY_CUSTOMER: '/products/void-cot-alerts/nfi/buy',

    PREVIOUS_OCCUPIER_GET_CUSTOMER: '/products/previous-occupier/search',
    PREVIOUS_OCCUPIER_GET_CUSTOMER_WORKFLOW: '/products/previous-occupier/search-workflow',
    PREVIOUS_OCCUPIER_GET_DATES: '/products/previous-occupier/dates',
    PREVIOUS_OCCUPIER_SUMMARY: '/products/previous-occupier',
    PREVIOUS_OCCUPIER_SUMMARY_BULK: '/products/previous-occupier/bulk',
    PREVIOUS_OCCUPIER_BUY_CUSTOMER: '/products/previous-occupier/buy',
    PREVIOUS_OCCUPIER_FILE_GET: '/products/previous-occupier/files',
    PREVIOUS_OCCUPIER_FILE_UPLOAD: '/products/previous-occupier/files',
    PREVIOUS_OCCUPIER_WORKFLOW_USERS: '/products/previous-occupier/workflow/users',
    PREVIOUS_OCCUPIER_WORKFLOW: '/products/previous-occupier/workflow',
    PREVIOUS_OCCUPIER_EXCLUDE_CUSTOMER: '/products/previous-occupier/exclude',
    PREVIOUS_OCCUPIER_SUMMARY_WATERFALL: '/products/previous-occupier/report',
    PREVIOUS_OCCUPIER_SUMMARY_WATERFALL_DATES: '/products/previous-occupier/report-dates',
    PREVIOUS_OCCUPIER_DATA_FILTERS: '/products/previous-occupier/data-filters',
    PREVIOUS_OCCUPIER_GROUPINGS: '/products/previous-occupier/groupings',
    
    PREVIOUS_OCCUPIER_NFI_GET_CUSTOMER: '/products/previous-occupier/nfi/search',
    PREVIOUS_OCCUPIER_NFI_SUMMARY_BULK: '/products/previous-occupier/nfi/bulk',
    PREVIOUS_OCCUPIER_NFI_BUY_CUSTOMER: '/products/previous-occupier/nfi/buy',

    OCCUPIER_COT_ALERTS_GET_CUSTOMER: '/products/occupier-cot-alerts/search',
    OCCUPIER_COT_ALERTS_GET_DATES: '/products/occupier-cot-alerts/dates',
    OCCUPIER_COT_ALERTS_SUMMARY: '/products/occupier-cot-alerts',
    OCCUPIER_COT_ALERTS_SUMMARY_BULK: '/products/occupier-cot-alerts/bulk',
    OCCUPIER_COT_ALERTS_BUY_CUSTOMER: '/products/occupier-cot-alerts/buy',
    OCCUPIER_COT_ALERTS_FILE_GET: '/products/occupier-cot-alerts/files',
    OCCUPIER_COT_ALERTS_FILE_UPLOAD: '/products/occupier-cot-alerts/files',
    OCCUPIER_COT_ALERTS_WORKFLOW_USERS: '/products/occupier-cot-alerts/workflow/users',
    OCCUPIER_COT_ALERTS_WORKFLOW: '/products/occupier-cot-alerts/workflow',
    OCCUPIER_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/occupier-cot-alerts/exclude',
    OCCUPIER_COT_ALERTS_SUMMARY_WATERFALL: '/products/occupier-cot-alerts/report',
    OCCUPIER_COT_ALERTS_SUMMARY_WATERFALL_DATES: '/products/occupier-cot-alerts/report-dates',
    OCCUPIER_COT_ALERTS_DATA_FILTERS: '/products/occupier-cot-alerts/data-filters',
    OCCUPIER_COT_ALERTS_GROUPINGS: '/products/occupier-cot-alerts/groupings',

    OCCUPIED_COT_ALERTS_GET_CUSTOMER: '/products/occupied-cot-alerts/search',
    OCCUPIED_COT_ALERTS_GET_DATES: '/products/occupied-cot-alerts/dates',
    OCCUPIED_COT_ALERTS_SUMMARY: '/products/occupied-cot-alerts',
    OCCUPIED_COT_ALERTS_SUMMARY_BULK: '/products/occupied-cot-alerts/bulk',
    OCCUPIED_COT_ALERTS_BUY_CUSTOMER: '/products/occupied-cot-alerts/buy',
    OCCUPIED_COT_ALERTS_FILE_GET: '/products/occupied-cot-alerts/files',
    OCCUPIED_COT_ALERTS_FILE_UPLOAD: '/products/occupied-cot-alerts/files',
    OCCUPIED_COT_ALERTS_WORKFLOW_USERS: '/products/occupied-cot-alerts/workflow/users',
    OCCUPIED_COT_ALERTS_WORKFLOW: '/products/occupied-cot-alerts/workflow',
    OCCUPIED_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/occupied-cot-alerts/exclude',
    OCCUPIED_COT_ALERTS_DATA_FILTERS: '/products/occupied-cot-alerts/data-filters',
    OCCUPIED_COT_ALERTS_GROUPINGS: '/products/occupied-cot-alerts/groupings',

    AFFORDABILITY_ALERTS_GET_CUSTOMER: '/products/affordability-alerts/search',
    AFFORDABILITY_ALERTS_GET_DATES: '/products/affordability-alerts/dates',
    AFFORDABILITY_ALERTS_SUMMARY: '/products/affordability-alerts',
    AFFORDABILITY_ALERTS_SUMMARY_BULK: '/products/affordability-alerts/bulk',
    AFFORDABILITY_ALERTS_BUY_CUSTOMER: '/products/affordability-alerts/buy',
    AFFORDABILITY_ALERTS_FILE_GET: '/products/affordability-alerts/files',
    AFFORDABILITY_ALERTS_FILE_UPLOAD: '/products/affordability-alerts/files',
    AFFORDABILITY_ALERTS_WORKFLOW_USERS: '/products/affordability-alerts/workflow/users',
    AFFORDABILITY_ALERTS_WORKFLOW: '/products/affordability-alerts/workflow',
    AFFORDABILITY_ALERTS_EXCLUDE_CUSTOMER: '/products/affordability-alerts/exclude',
    AFFORDABILITY_ALERTS_DATA_FILTERS: '/products/affordability-alerts/data-filters',
    AFFORDABILITY_ALERTS_GROUPINGS: '/products/affordability-alerts/groupings',

    VACANT_COT_ALERTS_GET_CUSTOMER: '/products/vacant-cot-alerts/search',
    VACANT_COT_ALERTS_GET_DATES: '/products/vacant-cot-alerts/dates',
    VACANT_COT_ALERTS_SUMMARY: '/products/vacant-cot-alerts',
    VACANT_COT_ALERTS_SUMMARY_BULK: '/products/vacant-cot-alerts/bulk',
    VACANT_COT_ALERTS_BUY_CUSTOMER: '/products/vacant-cot-alerts/buy',
    VACANT_COT_ALERTS_FILE_GET: '/products/vacant-cot-alerts/files',
    VACANT_COT_ALERTS_FILE_UPLOAD: '/products/vacant-cot-alerts/files',
    VACANT_COT_ALERTS_WORKFLOW_USERS: '/products/vacant-cot-alerts/workflow/users',
    VACANT_COT_ALERTS_WORKFLOW: '/products/vacant-cot-alerts/workflow',
    VACANT_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/vacant-cot-alerts/exclude',
    VACANT_COT_ALERTS_DATA_FILTERS: '/products/vacant-cot-alerts/data-filters',
    VACANT_COT_ALERTS_GROUPINGS: '/products/vacant-cot-alerts/groupings',
    VACANT_COT_ALERTS_UPDATE_STATUS: '/products/vacant-cot-alerts/updateStatus',
    VACANT_COT_ALERTS_CONFIRMATION: '/products/vacant-cot-alerts/confirm',
    VACANT_COT_ALERTS_ABP_BY_POSTCODE: '/products/vacant-cot-alerts/abp/postcode',

    VACANT_COT_ALERTS_UNALLOCATE_GET: '/products/vacant-cot-alerts/unallocate',
    VACANT_COT_ALERTS_UNALLOCATE_POST: '/products/vacant-cot-alerts/unallocate',

    VACANT_COT_ALERTS_DASHBOARD_MONTH_AND_YEAR: '/products/vacant-cot-alerts/dashboard/monthAndYear',
    VACANT_COT_ALERTS_DASHBOARD_MOVEINS_PERFORMED: '/products/vacant-cot-alerts/dashboard/moveinsPerformed',
    VACANT_COT_ALERTS_DASHBOARD_MOVEINS_TEAM_PERFORMED: '/products/vacant-cot-alerts/dashboard/teamPerformance',

    VACANT_COT_ALERTS_DASHBOARD_WORKFLOW: '/products/vacant-cot-alerts/dashboard/workflow',
    VACANT_COT_ALERTS_DASHBOARD_PERFORMANCE: '/products/vacant-cot-alerts/dashboard/performance',
    VACANT_COT_ALERTS_DASHBOARD_CONFIDENCE: '/products/vacant-cot-alerts/dashboard/confidence',
    VACANT_COT_ALERTS_DASHBOARD_REFRESH: '/products/vacant-cot-alerts/dashboard/refresh',

    VACANT_COT_ALERTS_DASHBOARD_WHOLESALER: '/products/vacant-cot-alerts/dashboard/wholesalers',

    VACANT_COT_ALERTS_SUBMIT_NOTES:  '/products/vacant-cot-alerts/submit-notes',
    VACANT_COT_ALERTS_ISSUE_TO_USER:  '/products/vacant-cot-alerts/issue-to-user',
    VACANT_COT_ALERTS_UPDATE_ASSESSMENT:  '/products/vacant-cot-alerts/update-assessment',

    OCCUPIER_TELEPHONE_VALIDATION_GET_CUSTOMER: '/products/occupier-telephone-validation/search',
    OCCUPIER_TELEPHONE_VALIDATION_GET_DATES: '/products/occupier-telephone-validation/dates',
    OCCUPIER_TELEPHONE_VALIDATION_SUMMARY: '/products/occupier-telephone-validation',
    OCCUPIER_TELEPHONE_VALIDATION_SUMMARY_BULK: '/products/occupier-telephone-validation/bulk',
    OCCUPIER_TELEPHONE_VALIDATION_BUY_CUSTOMER: '/products/occupier-telephone-validation/buy',
    OCCUPIER_TELEPHONE_VALIDATION_FILE_GET: '/products/occupier-telephone-validation/files',
    OCCUPIER_TELEPHONE_VALIDATION_FILE_UPLOAD: '/products/occupier-telephone-validation/files',
    OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW_USERS: '/products/occupier-telephone-validation/workflow/users',
    OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW: '/products/occupier-telephone-validation/workflow',
    OCCUPIER_TELEPHONE_VALIDATION_EXCLUDE_CUSTOMER: '/products/occupier-telephone-validation/exclude',
    OCCUPIER_TELEPHONE_VALIDATION_DATA_FILTERS: '/products/occupier-telephone-validation/data-filters',
    OCCUPIER_TELEPHONE_VALIDATION_GROUPINGS: '/products/occupier-telephone-validation/groupings',
    OCCUPIER_TELEPHONE_VALIDATION_UPDATE_STATUS: '/products/occupier-telephone-validation/updateStatus',
    OCCUPIER_TELEPHONE_VALIDATION_SUBMIT_ANSWERS: '/products/occupier-telephone-validation/submitSurveyAnswers',
    OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY: '/products/occupier-telephone-validation/update-customer-property',

    OCCUPIER_TELEPHONE_VALIDATION_UNALLOCATE_GET: '/products/occupier-telephone-validation/unallocate',
    OCCUPIER_TELEPHONE_VALIDATION_UNALLOCATE_POST: '/products/occupier-telephone-validation/unallocate',

    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_MONTH_AND_YEAR: '/products/occupier-telephone-validation/dashboard/monthAndYear',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_MOVEINS_PERFORMED: '/products/occupier-telephone-validation/dashboard/moveinsPerformed',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_MOVEINS_TEAM_PERFORMED: '/products/occupier-telephone-validation/dashboard/teamPerformance',

    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_WORKFLOW: '/products/occupier-telephone-validation/dashboard/workflow',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_PERFORMANCE: '/products/occupier-telephone-validation/dashboard/performance',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_CONFIDENCE: '/products/occupier-telephone-validation/dashboard/confidence',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_REFRESH: '/products/occupier-telephone-validation/dashboard/refresh',

    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_WHOLESALER: '/products/occupier-telephone-validation/dashboard/wholesalers',
    OCCUPIER_TELEPHONE_VALIDATION_MDS_SPID: '/products/occupier-telephone-validation/mds-spid',

    TWILIO_TOKEN: '/twilio/token',
    TWILIO_GET_RECORDINGS: '/twilio/getRecordings',
    TWILIO_DOWNLOAD_RECORDING: '/twilio/download',
    TWILIO_RTV_LOOKUP: '/twilio/getAddressesFromPostcode',

    COMPANIES_HOUSE_ALERTS_GET_CUSTOMER: '/products/companies-house-alerts/search',
    COMPANIES_HOUSE_ALERTS_GET_DATES: '/products/companies-house-alerts/dates',
    COMPANIES_HOUSE_ALERTS_SUMMARY: '/products/companies-house-alerts',
    COMPANIES_HOUSE_ALERTS_STATS: '/products/companies-house-alerts/stats',
    COMPANIES_HOUSE_ALERTS_STATS_QUALITY: '/products/companies-house-alerts/stats/quality',
    COMPANIES_HOUSE_ALERTS_STATS_FILING: '/products/companies-house-alerts/stats/filing',
    COMPANIES_HOUSE_ALERTS_STATS_COMPANY: '/products/companies-house-alerts/stats/company',
    COMPANIES_HOUSE_ALERTS_SUMMARY_BULK: '/products/companies-house-alerts/bulk',
    COMPANIES_HOUSE_ALERTS_BUY_CUSTOMER: '/products/companies-house-alerts/buy',
    COMPANIES_HOUSE_ALERTS_FILE_GET: '/products/companies-house-alerts/files',
    COMPANIES_HOUSE_ALERTS_FILE_UPLOAD: '/products/companies-house-alerts/files',
    COMPANIES_HOUSE_ALERTS_WORKFLOW_USERS: '/products/companies-house-alerts/workflow/users',
    COMPANIES_HOUSE_ALERTS_WORKFLOW: '/products/companies-house-alerts/workflow',
    COMPANIES_HOUSE_ALERTS_EXCLUDE_CUSTOMER: '/products/companies-house-alerts/exclude',
    COMPANIES_HOUSE_ALERTS_DATA_FILTERS: '/products/companies-house-alerts/data-filters',
    COMPANIES_HOUSE_ALERTS_GROUPINGS: '/products/companies-house-alerts/groupings',
    COMPANIES_HOUSE_ALERTS_WHOLESALER: '/products/companies-house-alerts/wholesaler',

    COT_VALIDATION_GET_CUSTOMER: '/products/cot-validation/search',
    COT_VALIDATION_GET_DATES: '/products/cot-validation/dates',
    COT_VALIDATION_SUMMARY: '/products/cot-validation',
    COT_VALIDATION_STATS: '/products/cot-validation/stats',
    COT_VALIDATION_STATS_QUALITY: '/products/cot-validation/stats/quality',
    COT_VALIDATION_STATS_FILING: '/products/cot-validation/stats/filing',
    COT_VALIDATION_SUMMARY_BULK: '/products/cot-validation/bulk',
    COT_VALIDATION_BUY_CUSTOMER: '/products/cot-validation/buy',
    COT_VALIDATION_FILE_GET: '/products/cot-validation/files',
    COT_VALIDATION_FILE_UPLOAD: '/products/cot-validation/files',
    COT_VALIDATION_WORKFLOW_USERS: '/products/cot-validation/workflow/users',
    COT_VALIDATION_WORKFLOW: '/products/cot-validation/workflow',
    COT_VALIDATION_EXCLUDE_CUSTOMER: '/products/cot-validation/exclude',
    COT_VALIDATION_DATA_FILTERS: '/products/cot-validation/data-filters',
    COT_VALIDATION_GROUPINGS: '/products/cot-validation/groupings',
    COT_VALIDATION_GET_DATA: '/products/cot-validation/data',
    COT_VALIDATION_SUMMARY_WATERFALL: '/products/cot-validation/report',
    COT_VALIDATION_SUMMARY_WATERFALL_DATES: '/products/cot-validation/report-dates',

    COT_VALIDATION_GET_MANUAL_REVIEW: '/products/cot-validation/manual-review',
    COT_VALIDATION_GET_WORKFLOW_MANUAL_REVIEW: '/products/cot-validation/manual-review/workflow',
    COT_VALIDATION_MANUAL_REVIEW_BULK: '/products/cot-validation/manual-review/bulk',
    COT_VALIDATION_UPDATE_MANUAL_REVIEW: '/products/cot-validation/manual-review',

    TARIFF_VALIDATION_GET_CUSTOMER: '/products/tariff-validation/search',
    TARIFF_VALIDATION_GET_DATES: '/products/tariff-validation/dates',
    TARIFF_VALIDATION_SUMMARY: '/products/tariff-validation',
    TARIFF_VALIDATION_SUMMARY_BULK: '/products/tariff-validation/bulk',
    TARIFF_VALIDATION_BUY_CUSTOMER: '/products/tariff-validation/buy',
    TARIFF_VALIDATION_FILE_GET: '/products/tariff-validation/files',
    TARIFF_VALIDATION_FILE_UPLOAD: '/products/tariff-validation/files',
    TARIFF_VALIDATION_WORKFLOW_USERS: '/products/tariff-validation/workflow/users',
    TARIFF_VALIDATION_WORKFLOW: '/products/tariff-validation/workflow',
    TARIFF_VALIDATION_EXCLUDE_CUSTOMER: '/products/tariff-validation/exclude',
    TARIFF_VALIDATION_SUMMARY_WATERFALL: '/products/tariff-validation/report',
    TARIFF_VALIDATION_SUMMARY_WATERFALL_DATES: '/products/tariff-validation/report-dates',
    TARIFF_VALIDATION_DATA_FILTERS: '/products/tariff-validation/data-filters',
    TARIFF_VALIDATION_GROUPINGS: '/products/tariff-validation/groupings',

    ADDRESS_ALERTS_GET_KPIS: '/products/address-alerts/kpi',
    ADDRESS_ALERTS_DATA_FILTERS: '/products/address-alerts/data-filters',
    ADDRESS_ALERTS_DATA_SUMMARY: '/products/address-alerts/summary',
    ADDRESS_ALERTS_FILE_GET: '/products/address-alerts/files',
    ADDRESS_ALERTS_FILE_UPLOAD: '/products/address-alerts/files',
    ADDRESS_ALERTS_GROUPINGS: '/products/address-alerts/groupings',

    BUSINESS_TELE_APPEND_DATES: '/products/business-tele-append/file-dates',
    BUSINESS_TELE_APPEND_DATA: '/products/business-tele-append/data',
    BUSINESS_TELE_APPEND_GRAPH: '/products/business-tele-append/graph',
    BUSINESS_TELE_APPEND_TABLE: '/products/business-tele-append/table',
    BUSINESS_TELE_FILE_DOWNLOAD: '/products/business-tele-append/files',
    BUSINESS_TELE_FILE_UPLOAD: '/products/business-tele-append/files',

    HOME_MOVER_ALERTS_GROUPINGS: '/products/home-mover-alerts/groupings',
    HOME_MOVER_ALERTS_FILTERS: '/products/home-mover-alerts/filters',
    HOME_MOVER_ALERTS_DATA: '/products/home-mover-alerts/data',
    HOME_MOVER_ALERTS_DATES: '/products/home-mover-alerts/dates',
    HOME_MOVER_ALERTS_FILES: '/products/home-mover-alerts/files',

    GAP_SITES_ALERTS_OUTWARDS: '/products/gap-sites-alerts/outwards',
    GAP_SITES_ALERTS_POSTCODES: '/products/gap-sites-alerts/postcodes',
    GAP_SITES_ALERTS_MAP_DATA: '/products/gap-sites-alerts/map-data',
    GAP_SITES_ALERTS_DATA: '/products/gap-sites-alerts/data',
    GAP_SITES_ALERTS_TOTALS: '/products/gap-sites-alerts/totals',
    GAP_SITES_ALERTS_UPDATE_STATUS: '/products/gap-sites-alerts/updateStatus',
    GAP_SITES_ALERTS_UPDATE_CUSTOMER: 'products/gap-sites-alerts/updateCustomer',
    GAP_SITES_ALERTS_SUMMARY: '/products/gap-sites-alerts/summary',
    GAP_SITES_ALERTS_GROUPINGS: '/products/gap-sites-alerts/groupings',
    GAP_SITES_ALERTS_FILTERS: '/products/gap-sites-alerts/filters',

    GAP_SITES_ALERTS_WORKFLOW: '/products/gap-sites-alerts/workflow',
    GAP_SITES_ALERTS_WORKFLOW_USERS: '/products/gap-sites-alerts/workflow/users',
    GAP_SITES_ALERTS_BULK: '/products/gap-sites-alerts/bulk',
    GAP_SITES_ALERTS_GET_CUSTOMER: '/products/gap-sites-alerts/search',
    GAP_SITES_ALERTS_BUY_CUSTOMER: '/products/gap-sites-alerts/buy',
    GAP_SITES_ALERTS_EXCLUDE_CUSTOMER: '/products/gap-sites-alerts/exclude',
    GAP_SITES_ALERTS_DOWNLOAD: '/products/gap-sites-alerts/files',
    GAP_SITES_ALERTS_UPLOAD: '/products/gap-sites-alerts/files',

    REAL_TIME_VALIDATION_SEARCH: '/products/real-time-validation/search',
    REAL_TIME_VALIDATION_LOOKUP: '/products/real-time-validation/lookup',

    // Summary
    BUSINESS_ADDRESS_ALERTS_SEARCH_BY_SPID: '/products/business-address-alerts/search',
    BUSINESS_ADDRESS_ALERTS_GET_ADDRESSES: '/products/business-address-alerts/addresses',
    BUSINESS_ADDRESS_ALERTS_GET_VOAS: '/products/business-address-alerts/voas',
    BUSINESS_ADDRESS_ALERTS_GET_SAA: '/products/business-address-alerts/saa',
    BUSINESS_ADDRESS_ALERTS_GET_CT: '/products/business-address-alerts/ct',
    BUSINESS_ADDRESS_ALERTS_MDS_SPID: '/products/business-address-alerts/mds-spid',
    BUSINESS_ADDRESS_ALERTS_GET_VO_CT_COMBINED: '/products/business-address-alerts/voas-ct',
    BUSINESS_ADDRESS_ALERTS_GET_METER: '/products/business-address-alerts/meter',

    // Generics
    BUSINESS_ADDRESS_ALERTS_GROUPINGS: '/products/business-address-alerts/groupings',
    BUSINESS_ADDRESS_ALERTS_FILTERS: '/products/business-address-alerts/filters',
    BUSINESS_ADDRESS_ALERTS_DATA: '/products/business-address-alerts/data',
    BUSINESS_ADDRESS_ALERTS_DATES: '/products/business-address-alerts/dates',
    BUSINESS_ADDRESS_ALERTS_FILES: '/products/business-address-alerts/files',
    // Workflow
    BUSINESS_ADDRESS_ALERTS_PURCHASE: '/products/business-address-alerts/buy',
    BUSINESS_ADDRESS_ALERTS_EXCLUDE: '/products/business-address-alerts/exclude',
    BUSINESS_ADDRESS_ALERTS_WF_USERS: '/products/business-address-alerts/workflow/users',
    BUSINESS_ADDRESS_ALERTS_WF_ITEMS: '/products/business-address-alerts/workflow',
    BUSINESS_ADDRESS_ALERTS_WF_COUNTTS: '/products/business-address-alerts/workflow-counts',
    // Bulk
    BUSINESS_ADDRESS_ALERTS_BULK_BASE: '/products/business-address-alerts/bulk',
    BUSINESS_ADDRESS_ALERTS_UNALLOCATE: '/products/business-address-alerts/unallocate',
    //
    BUSINESS_ADDRESS_ALERTS_SELECT_MATCH: '/products/business-address-alerts/select-match',
    BUSINESS_ADDRESS_ALERTS_CLEAR_MATCH: '/products/business-address-alerts/clear-match',
    BUSINESS_ADDRESS_ALERTS_UPDATE_OUTCOMES: '/products/business-address-alerts/update-outcomes',
    BUSINESS_ADDRESS_ALERTS_SUBMIT_NOTES: '/products/business-address-alerts/submit-notes',

    // Summary
    PORTFOLIO_SUMMARY_SEARCH_BY_SPID: '/products/portfolio-summary/search',
    PORTFOLIO_SUMMARY_MDS_UPLOAD: '/products/portfolio-summary/mds-upload',
    PORTFOLIO_SUMMARY_BATCH_SPID_UPLOAD: '/products/portfolio-summary/batch-spid-upload',

    // Generics
    PORTFOLIO_SUMMARY_GROUPINGS: '/products/portfolio-summary/groupings',
    PORTFOLIO_SUMMARY_FILTERS: '/products/portfolio-summary/filters',
    PORTFOLIO_SUMMARY_DATA: '/products/portfolio-summary/data',
    PORTFOLIO_SUMMARY_DATES: '/products/portfolio-summary/dates',
    PORTFOLIO_SUMMARY_FILES: '/products/portfolio-summary/files',
    // Workflow
    PORTFOLIO_SUMMARY_PURCHASE: '/products/portfolio-summary/buy',
    PORTFOLIO_SUMMARY_EXCLUDE: '/products/portfolio-summary/exclude',
    PORTFOLIO_SUMMARY_WF_USERS: '/products/portfolio-summary/workflow/users',
    PORTFOLIO_SUMMARY_WF_ITEMS: '/products/portfolio-summary/workflow',
    // Bulk
    PORTFOLIO_SUMMARY_BULK_BASE: '/products/portfolio-summary/bulk',
    PORTFOLIO_SUMMARY_UNALLOCATE: '/products/portfolio-summary/unallocate',
    //
    PORTFOLIO_SUMMARY_SELECT_MATCH: '/products/portfolio-summary/select-match',
    PORTFOLIO_SUMMARY_CLEAR_MATCH: '/products/portfolio-summary/clear-match',
    PORTFOLIO_SUMMARY_UPDATE_OUTCOMES: '/products/portfolio-summary/update-outcomes',
    PORTFOLIO_SUMMARY_SUBMIT_NOTES: '/products/portfolio-summary/submit-notes',

    // Summary
    INVALID_POSTCODE_STREET_SEARCH_BY_SPID: '/products/invalid-postcode-street/search',
    INVALID_POSTCODE_STREET_GET_ADDRESSES: '/products/invalid-postcode-street/addresses',
    INVALID_POSTCODE_STREET_GET_VOAS: '/products/invalid-postcode-street/voas',
    INVALID_POSTCODE_STREET_GET_SAA: '/products/invalid-postcode-street/saa',
    INVALID_POSTCODE_STREET_GET_CT: '/products/invalid-postcode-street/ct',
    INVALID_POSTCODE_STREET_MDS_SPID: '/products/invalid-postcode-street/mds-spid',
    INVALID_POSTCODE_STREET_GET_VO_CT_COMBINED: '/products/invalid-postcode-street/voas-ct',
    INVALID_POSTCODE_STREET_GET_METER: '/products/invalid-postcode-street/meter',
    // Generics
    INVALID_POSTCODE_STREET_GROUPINGS: '/products/invalid-postcode-street/groupings',
    INVALID_POSTCODE_STREET_FILTERS: '/products/invalid-postcode-street/filters',
    INVALID_POSTCODE_STREET_DATA: '/products/invalid-postcode-street/data',
    INVALID_POSTCODE_STREET_DATES: '/products/invalid-postcode-street/dates',
    INVALID_POSTCODE_STREET_FILES: '/products/invalid-postcode-street/files',
    // Workflow
    INVALID_POSTCODE_STREET_PURCHASE: '/products/invalid-postcode-street/buy',
    INVALID_POSTCODE_STREET_EXCLUDE: '/products/invalid-postcode-street/exclude',
    INVALID_POSTCODE_STREET_WF_USERS: '/products/invalid-postcode-street/workflow/users',
    INVALID_POSTCODE_STREET_WF_ITEMS: '/products/invalid-postcode-street/workflow',
    INVALID_POSTCODE_STREET_WF_COUNTTS: '/products/invalid-postcode-street/workflow-counts',
    // Bulk
    INVALID_POSTCODE_STREET_BULK_BASE: '/products/invalid-postcode-street/bulk',
    INVALID_POSTCODE_STREET_UNALLOCATE: '/products/invalid-postcode-street/unallocate',
    //
    INVALID_POSTCODE_STREET_SELECT_MATCH: '/products/invalid-postcode-street/select-match',
    INVALID_POSTCODE_STREET_CLEAR_MATCH: '/products/invalid-postcode-street/clear-match',
    INVALID_POSTCODE_STREET_UPDATE_OUTCOMES: '/products/invalid-postcode-street/update-outcomes',
    INVALID_POSTCODE_STREET_SUBMIT_NOTES: '/products/invalid-postcode-street/submit-notes',

    // Summary
    AUTOMATED_ADDRESS_CLEANSE_SEARCH_BY_SPID: '/products/automated-address-cleanse/search',
    AUTOMATED_ADDRESS_CLEANSE_GET_ADDRESSES: '/products/automated-address-cleanse/addresses',
    AUTOMATED_ADDRESS_CLEANSE_GET_VOAS: '/products/automated-address-cleanse/voas',
    AUTOMATED_ADDRESS_CLEANSE_GET_SAA: '/products/automated-address-cleanse/saa',
    AUTOMATED_ADDRESS_CLEANSE_GET_CT: '/products/automated-address-cleanse/ct',
    AUTOMATED_ADDRESS_CLEANSE_MDS_SPID: '/products/automated-address-cleanse/mds-spid',
    AUTOMATED_ADDRESS_CLEANSE_GET_VO_CT_COMBINED: '/products/automated-address-cleanse/voas-ct',
    AUTOMATED_ADDRESS_CLEANSE_GET_METER: '/products/automated-address-cleanse/meter',

    // Generics
    AUTOMATED_ADDRESS_CLEANSE_GROUPINGS: '/products/automated-address-cleanse/groupings',
    AUTOMATED_ADDRESS_CLEANSE_FILTERS: '/products/automated-address-cleanse/filters',
    AUTOMATED_ADDRESS_CLEANSE_DATA: '/products/automated-address-cleanse/data',
    AUTOMATED_ADDRESS_CLEANSE_DATES: '/products/automated-address-cleanse/dates',
    AUTOMATED_ADDRESS_CLEANSE_FILES: '/products/automated-address-cleanse/files',
    // Workflow
    AUTOMATED_ADDRESS_CLEANSE_PURCHASE: '/products/automated-address-cleanse/buy',
    AUTOMATED_ADDRESS_CLEANSE_EXCLUDE: '/products/automated-address-cleanse/exclude',
    AUTOMATED_ADDRESS_CLEANSE_WF_USERS: '/products/automated-address-cleanse/workflow/users',
    AUTOMATED_ADDRESS_CLEANSE_WF_ITEMS: '/products/automated-address-cleanse/workflow',
    AUTOMATED_ADDRESS_CLEANSE_WF_COUNTTS: '/products/automated-address-cleanse/workflow-counts',
    // Bulk
    AUTOMATED_ADDRESS_CLEANSE_BULK_BASE: '/products/automated-address-cleanse/bulk',
    AUTOMATED_ADDRESS_CLEANSE_UNALLOCATE: '/products/automated-address-cleanse/unallocate',
    //
    AUTOMATED_ADDRESS_CLEANSE_SELECT_MATCH: '/products/automated-address-cleanse/select-match',
    AUTOMATED_ADDRESS_CLEANSE_CLEAR_MATCH: '/products/automated-address-cleanse/clear-match',
    AUTOMATED_ADDRESS_CLEANSE_UPDATE_OUTCOMES: '/products/automated-address-cleanse/update-outcomes',
    AUTOMATED_ADDRESS_CLEANSE_SUBMIT_NOTES: '/products/automated-address-cleanse/submit-notes',

    // Summary
    HOUSEHOLD_PROPERTY_SEARCH_BY_SPID: '/products/household-property/search',
    HOUSEHOLD_PROPERTY_GET_ADDRESSES: '/products/household-property/addresses',
    HOUSEHOLD_PROPERTY_GET_VOAS: '/products/household-property/voas',
    HOUSEHOLD_PROPERTY_GET_SAA: '/products/household-property/saa',
    HOUSEHOLD_PROPERTY_GET_CT: '/products/household-property/ct',
    // Generics
    HOUSEHOLD_PROPERTY_GROUPINGS: '/products/household-property/groupings',
    HOUSEHOLD_PROPERTY_FILTERS: '/products/household-property/filters',
    HOUSEHOLD_PROPERTY_DATA: '/products/household-property/data',
    HOUSEHOLD_PROPERTY_DATES: '/products/household-property/dates',
    HOUSEHOLD_PROPERTY_FILES: '/products/household-property/files',
    // Report
    HOUSEHOLD_PROPERTY_REPORT_GROUPINGS: '/products/household-property/report/groupings',
    HOUSEHOLD_PROPERTY_REPORT_FILTERS: '/products/household-property/report/filters',
    HOUSEHOLD_PROPERTY_REPORT_DATA: '/products/household-property/report/data',
    HOUSEHOLD_PROPERTY_REPORT_DATES: '/products/household-property/report/dates',
    // Workflow
    HOUSEHOLD_PROPERTY_PURCHASE: '/products/household-property/buy',
    HOUSEHOLD_PROPERTY_EXCLUDE: '/products/household-property/exclude',
    HOUSEHOLD_PROPERTY_WF_USERS: '/products/household-property/workflow/users',
    HOUSEHOLD_PROPERTY_WF_ITEMS: '/products/household-property/workflow',
    HOUSEHOLD_PROPERTY_WF_COUNTTS: '/products/household-property/workflow-counts',
    // Bulk
    HOUSEHOLD_PROPERTY_BULK_BASE: '/products/household-property/bulk',
    HOUSEHOLD_PROPERTY_UNALLOCATE: '/products/household-property/unallocate',
    //
    HOUSEHOLD_PROPERTY_SELECT_MATCH: '/products/household-property/select-match',
    HOUSEHOLD_PROPERTY_CLEAR_MATCH: '/products/household-property/clear-match',
    HOUSEHOLD_PROPERTY_UPDATE_OUTCOMES: '/products/household-property/update-outcomes',
    HOUSEHOLD_PROPERTY_SUBMIT_NOTES: '/products/household-property/submit-notes',

    // Summary
    UNDELIVERABLE_SEARCH_BY_SPID: '/products/undeliverable/search',
    UNDELIVERABLE_GET_ADDRESSES: '/products/undeliverable/addresses',
    UNDELIVERABLE_GET_VOAS: '/products/undeliverable/voas',
    UNDELIVERABLE_GET_SAA: '/products/undeliverable/saa',
    UNDELIVERABLE_GET_CT: '/products/undeliverable/ct',
    UNDELIVERABLE_MDS_SPID: '/products/undeliverable/mds-spid',
    UNDELIVERABLE_GET_VO_CT_COMBINED: '/products/undeliverable/voas-ct',
    UNDELIVERABLE_GET_METER: '/products/undeliverable/meter',

    // Generics
    UNDELIVERABLE_GROUPINGS: '/products/undeliverable/groupings',
    UNDELIVERABLE_FILTERS: '/products/undeliverable/filters',
    UNDELIVERABLE_DATA: '/products/undeliverable/data',
    UNDELIVERABLE_DATES: '/products/undeliverable/dates',
    UNDELIVERABLE_FILES: '/products/undeliverable/files',
    // Report
    UNDELIVERABLE_REPORT_GROUPINGS: '/products/undeliverable/report/groupings',
    UNDELIVERABLE_REPORT_FILTERS: '/products/undeliverable/report/filters',
    UNDELIVERABLE_REPORT_DATA: '/products/undeliverable/report/data',
    UNDELIVERABLE_REPORT_DATES: '/products/undeliverable/report/dates',
    // Workflow
    UNDELIVERABLE_PURCHASE: '/products/undeliverable/buy',
    UNDELIVERABLE_EXCLUDE: '/products/undeliverable/exclude',
    UNDELIVERABLE_WF_USERS: '/products/undeliverable/workflow/users',
    UNDELIVERABLE_WF_ITEMS: '/products/undeliverable/workflow',
    UNDELIVERABLE_WF_COUNTTS: '/products/undeliverable/workflow-counts',
    // Bulk
    UNDELIVERABLE_BULK_BASE: '/products/undeliverable/bulk',
    UNDELIVERABLE_UNALLOCATE: '/products/undeliverable/unallocate',
    //
    UNDELIVERABLE_SELECT_MATCH: '/products/undeliverable/select-match',
    UNDELIVERABLE_CLEAR_MATCH: '/products/undeliverable/clear-match',
    UNDELIVERABLE_UPDATE_OUTCOMES: '/products/undeliverable/update-outcomes',
    UNDELIVERABLE_SUBMIT_NOTES: '/products/undeliverable/submit-notes',

    // Summary
    INVALID_UPRN_VOA_SEARCH_BY_SPID: '/products/invalid-uprn-voa/search',
    INVALID_UPRN_VOA_GET_ADDRESSES: '/products/invalid-uprn-voa/addresses',
    INVALID_UPRN_VOA_GET_VOAS: '/products/invalid-uprn-voa/voas',
    INVALID_UPRN_VOA_GET_SAA: '/products/invalid-uprn-voa/saa',
    INVALID_UPRN_VOA_GET_CT: '/products/invalid-uprn-voa/ct',
    INVALID_UPRN_VOA_MDS_SPID: '/products/invalid-uprn-voa/mds-spid',
    INVALID_UPRN_VOA_GET_VO_CT_COMBINED: '/products/invalid-uprn-voa/voas-ct',
    INVALID_UPRN_VOA_GET_METER: '/products/invalid-uprn-voa/meter',

    // Generics
    INVALID_UPRN_VOA_GROUPINGS: '/products/invalid-uprn-voa/groupings',
    INVALID_UPRN_VOA_FILTERS: '/products/invalid-uprn-voa/filters',
    INVALID_UPRN_VOA_DATA: '/products/invalid-uprn-voa/data',
    INVALID_UPRN_VOA_DATES: '/products/invalid-uprn-voa/dates',
    INVALID_UPRN_VOA_FILES: '/products/invalid-uprn-voa/files',
    // Report
    INVALID_UPRN_VOA_REPORT_GROUPINGS: '/products/invalid-uprn-voa/report/groupings',
    INVALID_UPRN_VOA_REPORT_FILTERS: '/products/invalid-uprn-voa/report/filters',
    INVALID_UPRN_VOA_REPORT_DATA: '/products/invalid-uprn-voa/report/data',
    INVALID_UPRN_VOA_REPORT_DATES: '/products/invalid-uprn-voa/report/dates',
    // Workflow
    INVALID_UPRN_VOA_PURCHASE: '/products/invalid-uprn-voa/buy',
    INVALID_UPRN_VOA_EXCLUDE: '/products/invalid-uprn-voa/exclude',
    INVALID_UPRN_VOA_WF_USERS: '/products/invalid-uprn-voa/workflow/users',
    INVALID_UPRN_VOA_WF_ITEMS: '/products/invalid-uprn-voa/workflow',
    INVALID_UPRN_VOA_WF_COUNTTS: '/products/invalid-uprn-voa/workflow-counts',
    // Bulk
    INVALID_UPRN_VOA_BULK_BASE: '/products/invalid-uprn-voa/bulk',
    INVALID_UPRN_VOA_UNALLOCATE: '/products/invalid-uprn-voa/unallocate',
    //
    INVALID_UPRN_VOA_SELECT_MATCH: '/products/invalid-uprn-voa/select-match',
    INVALID_UPRN_VOA_CLEAR_MATCH: '/products/invalid-uprn-voa/clear-match',
    INVALID_UPRN_VOA_UPDATE_OUTCOMES: '/products/invalid-uprn-voa/update-outcomes',
    INVALID_UPRN_VOA_SUBMIT_NOTES: '/products/invalid-uprn-voa/submit-notes',

    // Summary
    INVALID_UPRN_VOA_CMOS_SEARCH_BY_SPID: '/products/invalid-uprn-voa-cmos/search',
    INVALID_UPRN_VOA_CMOS_GET_ADDRESSES: '/products/invalid-uprn-voa-cmos/addresses',
    INVALID_UPRN_VOA_CMOS_GET_VOAS: '/products/invalid-uprn-voa-cmos/voas',
    INVALID_UPRN_VOA_CMOS_GET_SAA: '/products/invalid-uprn-voa-cmos/saa',
    INVALID_UPRN_VOA_CMOS_GET_CT: '/products/invalid-uprn-voa-cmos/ct',
    INVALID_UPRN_VOA_CMOS_MDS_SPID: '/products/invalid-uprn-voa-cmos/mds-spid',
    INVALID_UPRN_VOA_CMOS_GET_VO_CT_COMBINED: '/products/invalid-uprn-voa-cmos/voas-ct',
    INVALID_UPRN_VOA_CMOS_GET_METER: '/products/invalid-uprn-voa-cmos/meter',

    // Generics
    INVALID_UPRN_VOA_CMOS_GROUPINGS: '/products/invalid-uprn-voa-cmos/groupings',
    INVALID_UPRN_VOA_CMOS_FILTERS: '/products/invalid-uprn-voa-cmos/filters',
    INVALID_UPRN_VOA_CMOS_DATA: '/products/invalid-uprn-voa-cmos/data',
    INVALID_UPRN_VOA_CMOS_DATES: '/products/invalid-uprn-voa-cmos/dates',
    INVALID_UPRN_VOA_CMOS_FILES: '/products/invalid-uprn-voa-cmos/files',
    // Report
    INVALID_UPRN_VOA_CMOS_REPORT_GROUPINGS: '/products/invalid-uprn-voa-cmos/report/groupings',
    INVALID_UPRN_VOA_CMOS_REPORT_FILTERS: '/products/invalid-uprn-voa-cmos/report/filters',
    INVALID_UPRN_VOA_CMOS_REPORT_DATA: '/products/invalid-uprn-voa-cmos/report/data',
    INVALID_UPRN_VOA_CMOS_REPORT_DATES: '/products/invalid-uprn-voa-cmos/report/dates',
    // Workflow
    INVALID_UPRN_VOA_CMOS_PURCHASE: '/products/invalid-uprn-voa-cmos/buy',
    INVALID_UPRN_VOA_CMOS_EXCLUDE: '/products/invalid-uprn-voa-cmos/exclude',
    INVALID_UPRN_VOA_CMOS_WF_USERS: '/products/invalid-uprn-voa-cmos/workflow/users',
    INVALID_UPRN_VOA_CMOS_WF_ITEMS: '/products/invalid-uprn-voa-cmos/workflow',
    INVALID_UPRN_VOA_CMOS_WF_COUNTTS: '/products/invalid-uprn-voa-cmos/workflow-counts',
    // Bulk
    INVALID_UPRN_VOA_CMOS_BULK_BASE: '/products/invalid-uprn-voa-cmos/bulk',
    INVALID_UPRN_VOA_CMOS_UNALLOCATE: '/products/invalid-uprn-voa-cmos/unallocate',
    //
    INVALID_UPRN_VOA_CMOS_SELECT_MATCH: '/products/invalid-uprn-voa-cmos/select-match',
    INVALID_UPRN_VOA_CMOS_CLEAR_MATCH: '/products/invalid-uprn-voa-cmos/clear-match',
    INVALID_UPRN_VOA_CMOS_UPDATE_OUTCOMES: '/products/invalid-uprn-voa-cmos/update-outcomes',
    INVALID_UPRN_VOA_CMOS_SUBMIT_NOTES: '/products/invalid-uprn-voa-cmos/submit-notes',

    // Summary
    METER_COORDINATION_VALIDAITON_SEARCH_BY_SPID: '/products/meter-coordinates-validation/search',
    METER_COORDINATION_VALIDAITON_SEARCH_BY_CORE_SPID: '/products/meter-coordinates-validation/search-spid',
    METER_COORDINATION_VALIDAITON_GET_PREMISES: '/products/meter-coordinates-validation/premises',
    METER_COORDINATION_VALIDAITON_GET_ADDRESSES: '/products/meter-coordinates-validation/addresses',
    METER_COORDINATION_VALIDAITON_GET_VOAS: '/products/meter-coordinates-validation/voas',
    METER_COORDINATION_VALIDAITON_GET_CMOS_UPRN: '/products/meter-coordinates-validation/cmos-uprn',
    METER_COORDINATION_VALIDAITON_GET_MATCHED_UPRN: '/products/meter-coordinates-validation/matched-uprn',
    METER_COORDINATION_VALIDAITON_GET_METER: '/products/meter-coordinates-validation/meter',
    METER_COORDINATION_VALIDAITON_GET_SAA: '/products/meter-coordinates-validation/saa',
    METER_COORDINATION_VALIDAITON_GET_CT: '/products/meter-coordinates-validation/ct',
    METER_COORDINATION_VALIDAITON_MDS_SPID: '/products/meter-coordinates-validation/mds-spid',
    METER_COORDINATION_VALIDAITON_GET_VO_CT_COMBINED: '/products/meter-coordinates-validation/voas-ct',

    // Generics
    METER_COORDINATION_VALIDAITON_GROUPINGS: '/products/meter-coordinates-validation/groupings',
    METER_COORDINATION_VALIDAITON_FILTERS: '/products/meter-coordinates-validation/filters',
    METER_COORDINATION_VALIDAITON_DATA: '/products/meter-coordinates-validation/data',
    METER_COORDINATION_VALIDAITON_DATES: '/products/meter-coordinates-validation/dates',
    METER_COORDINATION_VALIDAITON_FILES: '/products/meter-coordinates-validation/files',
    METER_COORDINATION_VALIDAITON_CDS_UPLOAD: '/products/meter-coordinates-validation/cds-upload',
    // Workflow
    METER_COORDINATION_VALIDAITON_PURCHASE: '/products/meter-coordinates-validation/buy',
    METER_COORDINATION_VALIDAITON_EXCLUDE: '/products/meter-coordinates-validation/exclude',
    METER_COORDINATION_VALIDAITON_WF_USERS: '/products/meter-coordinates-validation/workflow/users',
    METER_COORDINATION_VALIDAITON_WF_ITEMS: '/products/meter-coordinates-validation/workflow',
    METER_COORDINATION_VALIDAITON_WF_COUNTTS: '/products/meter-coordinates-validation/workflow-counts',
    // Bulk
    METER_COORDINATION_VALIDAITON_BULK_BASE: '/products/meter-coordinates-validation/bulk',
    METER_COORDINATION_VALIDAITON_UNALLOCATE: '/products/meter-coordinates-validation/unallocate',
    //
    METER_COORDINATION_VALIDAITON_SELECT_MATCH: '/products/meter-coordinates-validation/select-match',
    METER_COORDINATION_VALIDAITON_CLEAR_MATCH: '/products/meter-coordinates-validation/clear-match',
    METER_COORDINATION_VALIDAITON_UPDATE_OUTCOMES: '/products/meter-coordinates-validation/update-outcomes',
    METER_COORDINATION_VALIDAITON_SUBMIT_NOTES: '/products/meter-coordinates-validation/submit-notes',

    // Summary
    DATA_CLEANSE_VALIDATION_SEARCH_BY_SPID: '/products/data-cleanse-validation/search',
    DATA_CLEANSE_VALIDATION_GET_PREMISES: '/products/data-cleanse-validation/premises',
    DATA_CLEANSE_VALIDATION_GET_ADDRESSES: '/products/data-cleanse-validation/addresses',
    DATA_CLEANSE_VALIDATION_GET_VOAS: '/products/data-cleanse-validation/voas',
    DATA_CLEANSE_VALIDATION_GET_CMOS_UPRN: '/products/data-cleanse-validation/cmos-uprn',
    DATA_CLEANSE_VALIDATION_GET_MATCHED_UPRN: '/products/data-cleanse-validation/matched-uprn',
    DATA_CLEANSE_VALIDATION_GET_METER: '/products/data-cleanse-validation/meter',
    DATA_CLEANSE_VALIDATION_GET_SAA: '/products/data-cleanse-validation/saa',
    DATA_CLEANSE_VALIDATION_GET_CT: '/products/data-cleanse-validation/ct',
    DATA_CLEANSE_VALIDATION_MDS_SPID: '/products/data-cleanse-validation/mds-spid',
    DATA_CLEANSE_VALIDATION_GET_VO_CT_COMBINED: '/products/data-cleanse-validation/voas-ct',
    DATA_CLEANSE_VALIDATION_SUBMIT_NOTES: '/products/data-cleanse-validation/submit-notes',

    // Generics
    DATA_CLEANSE_VALIDATION_GROUPINGS: '/products/data-cleanse-validation/groupings',
    DATA_CLEANSE_VALIDATION_FILTERS: '/products/data-cleanse-validation/filters',
    DATA_CLEANSE_VALIDATION_DATA: '/products/data-cleanse-validation/data',
    DATA_CLEANSE_VALIDATION_DATES: '/products/data-cleanse-validation/dates',
    DATA_CLEANSE_VALIDATION_FILES: '/products/data-cleanse-validation/files',
    // Workflow
    DATA_CLEANSE_VALIDATION_WF_USERS: '/products/data-cleanse-validation/workflow/users',
    DATA_CLEANSE_VALIDATION_WF_ITEMS: '/products/data-cleanse-validation/workflow',
    DATA_CLEANSE_VALIDATION_WF_COUNTTS: '/products/data-cleanse-validation/workflow-counts',
    DATA_CLEANSE_VALIDATION_QA_USERS: '/products/data-cleanse-validation/qa/users',
    // Bulk
    DATA_CLEANSE_VALIDATION_BULK_BASE: '/products/data-cleanse-validation/bulk',

    // Summary
    PRIORITY_CLEANSING_SEARCH_BY_SPID: '/products/priority-cleansing/search',
    PRIORITY_CLEANSING_SEARCH_BY_CORE_SPID: '/products/priority-cleansing/search-spid',
    PRIORITY_CLEANSING_GET_PREMISES: '/products/priority-cleansing/premises',
    PRIORITY_CLEANSING_GET_ADDRESSES: '/products/priority-cleansing/addresses',
    PRIORITY_CLEANSING_GET_VOAS: '/products/priority-cleansing/voas',
    PRIORITY_CLEANSING_GET_CMOS_UPRN: '/products/priority-cleansing/cmos-uprn',
    PRIORITY_CLEANSING_GET_MATCHED_UPRN: '/products/priority-cleansing/matched-uprn',
    PRIORITY_CLEANSING_GET_METER: '/products/priority-cleansing/meter',
    PRIORITY_CLEANSING_GET_SAA: '/products/priority-cleansing/saa',
    PRIORITY_CLEANSING_GET_CT: '/products/priority-cleansing/ct',
    PRIORITY_CLEANSING_MDS_SPID: '/products/priority-cleansing/mds-spid',
    PRIORITY_CLEANSING_GET_VO_CT_COMBINED: '/products/priority-cleansing/voas-ct',

    // Generics
    PRIORITY_CLEANSING_GROUPINGS: '/products/priority-cleansing/groupings',
    PRIORITY_CLEANSING_FILTERS: '/products/priority-cleansing/filters',
    PRIORITY_CLEANSING_DATA: '/products/priority-cleansing/data',
    PRIORITY_CLEANSING_DATES: '/products/priority-cleansing/dates',
    PRIORITY_CLEANSING_FILES: '/products/priority-cleansing/files',
    PRIORITY_CLEANSING_UPLOAD_METERS: '/products/priority-cleansing/Meters',
    PRIORITY_CLEANSING_UPLOAD_SPIDS: '/products/priority-cleansing/Spids',
    // Workflow
    PRIORITY_CLEANSING_WF_USERS: '/products/priority-cleansing/workflow/users',
    PRIORITY_CLEANSING_WF_ITEMS: '/products/priority-cleansing/workflow',
    PRIORITY_CLEANSING_WF_COUNTTS: '/products/priority-cleansing/workflow-counts',
    // Bulk
    PRIORITY_CLEANSING_BULK_BASE: '/products/priority-cleansing/bulk',
    PRIORITY_CLEANSING_BULK_SPIDS: '/products/priority-cleansing/bulk/spids',
    PRIORITY_CLEANSING_BULK_METERS: '/products/priority-cleansing/bulk/meters',
    PRIORITY_CLEANSING_UNALLOCATE_SPIDS: '/products/priority-cleansing/unallocate/spids',
    PRIORITY_CLEANSING_UNALLOCATE_METERS: '/products/priority-cleansing/unallocate/meters',
    //
    PRIORITY_CLEANSING_SUBMIT_NOTES: '/products/priority-cleansing/submit-notes',
    PRIORITY_CLEANSING_SELECT_MATCH: '/products/priority-cleansing/select-match',
    PRIORITY_CLEANSING_CLEAR_MATCH: '/products/priority-cleansing/clear-match',
    PRIORITY_CLEANSING_UPDATE_OUTCOMES: '/products/priority-cleansing/update-outcomes',

    //
    UPRN_SEARCH_UPRN: '/products/uprn-search/uprn',
    UPRN_SEARCH_SUGGESTIONS: '/products/uprn-search/suggestions',
    UPRN_SEARCH_ADDRESS: '/products/uprn-search/address',
    UPRN_SEARCH_POSTCODE: '/products/uprn-search/postcode',
    UPRN_SEARCH_COORDINATES: '/products/uprn-search/coordinates',

    // Summary
    WHOLESALER_GAPS_SEARCH_BY_SPID: '/products/wholesaler-gaps/search',
    WHOLESALER_GAPS_SEARCH_BY_POSTCODE: '/products/wholesaler-gaps/search-postcode',
    WHOLESALER_GAPS_GET_ADDRESSES: '/products/wholesaler-gaps/addresses',
    WHOLESALER_GAPS_GET_CMOS_ADDRESSES: '/products/wholesaler-gaps/cmos-address',
    WHOLESALER_GAPS_GET_CMOS_ADDRESSES_VOA: '/products/wholesaler-gaps/cmos-address-voa',
    WHOLESALER_GAPS_GET_IDENTEQ_ADDRESSES: '/products/wholesaler-gaps/identeq',
    WHOLESALER_GAPS_GET_IDENTEQ_ADDRESSES_VOA: '/products/wholesaler-gaps/identeq-voa',
    WHOLESALER_GAPS_GET_VOAS: '/products/wholesaler-gaps/voas',
    WHOLESALER_GAPS_GET_SAA: '/products/wholesaler-gaps/saa',
    WHOLESALER_GAPS_GET_GAP: '/products/wholesaler-gaps/gap',
    // Generics
    WHOLESALER_GAPS_GROUPINGS: '/products/wholesaler-gaps/groupings',
    WHOLESALER_GAPS_FILTERS: '/products/wholesaler-gaps/filters',
    WHOLESALER_GAPS_DATA: '/products/wholesaler-gaps/data',
    WHOLESALER_GAPS_DATES: '/products/wholesaler-gaps/dates',
    WHOLESALER_GAPS_FILES: '/products/wholesaler-gaps/files',
    // Workflow
    WHOLESALER_GAPS_PURCHASE: '/products/wholesaler-gaps/buy',
    WHOLESALER_GAPS_EXCLUDE: '/products/wholesaler-gaps/exclude',
    WHOLESALER_GAPS_WF_USERS: '/products/wholesaler-gaps/workflow/users',
    WHOLESALER_GAPS_WF_ITEMS: '/products/wholesaler-gaps/workflow',
    // Bulk
    WHOLESALER_GAPS_BULK_BASE: '/products/wholesaler-gaps/bulk',
    //
    WHOLESALER_GAPS_SELECT_MATCH: '/products/wholesaler-gaps/select-match',
    WHOLESALER_GAPS_CLEAR_MATCH: '/products/wholesaler-gaps/clear-match',
    WHOLESALER_GAPS_SELECT_MATCH_VOA: '/products/wholesaler-gaps/select-match-voa',
    WHOLESALER_GAPS_SUBMIT_GAP: '/products/wholesaler-gaps/submit-gap',

    //
    WHOLESALER_GAPS_TRIGGER_BATCH: '/products/wholesaler-gaps/trigger-batch',

    //
    //
    //
    
    // Generics
    IDENTEQ_OCCUPIER_VALIDATION_GROUPINGS: '/products/identeq-occupier-validation/groupings',
    IDENTEQ_OCCUPIER_VALIDATION_FILTERS: '/products/identeq-occupier-validation/filters',
    IDENTEQ_OCCUPIER_VALIDATION_DATA: '/products/identeq-occupier-validation/data',
    IDENTEQ_OCCUPIER_VALIDATION_DATES: '/products/identeq-occupier-validation/dates',
    IDENTEQ_OCCUPIER_VALIDATION_FILES: '/products/identeq-occupier-validation/files',
    IDENTEQ_OCCUPIER_VALIDATION_UPDATE_STATUS: '/products/identeq-occupier-validation/updateStatus',
    IDENTEQ_OCCUPIER_VALIDATION_SUBMIT_ANSWERS: '/products/identeq-occupier-validation/submitSurveyAnswers',
    // Workflow
    IDENTEQ_OCCUPIER_VALIDATION_PURCHASE: '/products/identeq-occupier-validation/buy',
    IDENTEQ_OCCUPIER_VALIDATION_EXCLUDE: '/products/identeq-occupier-validation/exclude',
    IDENTEQ_OCCUPIER_VALIDATION_WF_USERS: '/products/identeq-occupier-validation/workflow/users',
    IDENTEQ_OCCUPIER_VALIDATION_WF_ITEMS: '/products/identeq-occupier-validation/workflow',
    // Bulk
    IDENTEQ_OCCUPIER_VALIDATION_BULK_BASE: '/products/identeq-occupier-validation/bulk',
    //
    IDENTEQ_OCCUPIED_VALIDATION_LOOKUP: '/products/identeq-occupier-validation/lookup',

    // Generics
    OCCUPIER_VALIDATION_GROUPINGS: '/products/occupier-validation/groupings',
    OCCUPIER_VALIDATION_FILTERS: '/products/occupier-validation/filters',
    OCCUPIER_VALIDATION_DATA: '/products/occupier-validation/data',
    OCCUPIER_VALIDATION_DATES: '/products/occupier-validation/dates',
    OCCUPIER_VALIDATION_FILES: '/products/occupier-validation/files',
    OCCUPIER_VALIDATION_UPDATE_STATUS: '/products/occupier-validation/updateStatus',
    OCCUPIER_VALIDATION_UNALLOCATE_GET: '/products/occupier-validation/unallocate',
    OCCUPIER_VALIDATION_UNALLOCATE_POST: '/products/occupier-validation/unallocate',
    // Workflow
    OCCUPIER_VALIDATION_PURCHASE: '/products/occupier-validation/buy',
    OCCUPIER_VALIDATION_EXCLUDE: '/products/occupier-validation/exclude',
    OCCUPIER_VALIDATION_WF_USERS: '/products/occupier-validation/workflow/users',
    OCCUPIER_VALIDATION_WF_ITEMS: '/products/occupier-validation/workflow',
    // Bulk
    OCCUPIER_VALIDATION_BULK_BASE: '/products/occupier-validation/bulk',
    //
    OCCUPIED_VALIDATION_LOOKUP: '/products/occupier-validation/lookup',
    //
    OCCUPIER_VALIDATION_DASHBOARD_MONTH_AND_YEAR: '/products/occupier-validation/dashboard/monthAndYear',
    OCCUPIER_VALIDATION_DASHBOARD_MOVEINS_PERFORMED: '/products/occupier-validation/dashboard/moveinsPerformed',
    OCCUPIER_VALIDATION_DASHBOARD_MOVEINS_TEAM_PERFORMED: '/products/occupier-validation/dashboard/teamPerformance',

    OCCUPIER_VALIDATION_DASHBOARD_WORKFLOW: '/products/occupier-validation/dashboard/workflow',
    OCCUPIER_VALIDATION_DASHBOARD_PERFORMANCE: '/products/occupier-validation/dashboard/performance',
    OCCUPIER_VALIDATION_DASHBOARD_CONFIDENCE: '/products/occupier-validation/dashboard/confidence',
    OCCUPIER_VALIDATION_DASHBOARD_REFRESH: '/products/occupier-validation/dashboard/refresh',

    OCCUPIER_VALIDATION_DASHBOARD_WHOLESALER: '/products/occupier-validation/dashboard/wholesalers',

    COMPANY_ADMIN_USERS: '/company-admin/users',

    ADMIN_COMPANIES_INDEX: '/companies',
    ADMIN_COMPANIES_COMPANY: '/companies',

    ADMIN_EXTRA_PERMISSIONS: '/extra-permissions',

    ADMIN_COMPANIES_COMPANY_FRONTEND: '/frontends',

    ADMIN_COMPANIES_USERS: '/users',
    ADMIN_COMPANIES_USER: '/users',

    ADMIN_USERS_INDEX: '/users',

    ADMIN_BRANDING_INDEX: '/branding',
    ADMIN_BRANDING_POST: '/branding',

    ADMIN_PRODUCTS_INDEX: '/products',
    ADMIN_PRODUCTS_UPDATE_TUTORIALS: '/tutorials/update-tutorials',
    ADMIN_REAL_PRODUCTS_INDEX: '/real-products',

    ADMIN_VERSION_HISTORY_CHANGES: '/version-history/changes',
    ADMIN_VERSION_HISTORY_VERSION_NUMBERS: '/version-history/versions',
    ADMIN_VERSION_HISTORY_INSERT: '/version-history/insert-changes',
    ADMIN_VERSION_HISTORY_EDIT: '/version-history/edit',
    ADMIN_VERSION_HISTORY_DELETE: '/version-history/delete',
    ADMIN_VERSION_HISTORY_SEEN_CHANGES: '/version-history/seen-changes',
    ADMIN_VERSION_HISTORY_RECENT_DATE: '/version-history/recent-date',

    ADMIN_ALIGN_ENVIRONMENTS: '/data-alignment',

    RESET_REQUEST: '/auth/reset/request',
    RESET_CHECK: '/auth/reset/check',
    RESET_RESET: '/auth/reset',
});
