import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './largeLinkButton.scss';

export default class LargeLinkButton extends Component {
    render() {
        /*eslint no-unused-vars: 0 */
        const linkProps = {
            [this.props.link ? 'to' : 'onClick']: this.props.link || this.props.callback
        };

        if (this.props.link) {
            return (
                <Link {...linkProps} style={{textDecoration: 'none'}}>
                    <div className="grid center middle large-link-button" style={{padding: this.props.small ? '0em' : undefined, height: this.props.small ? 165 : undefined}}>
                        { this.props.fa ? <span style={{fontSize: this.props.iconSize || undefined}} className={this.props.icon}></span> : '' }
                        { this.props.icon ?
                            <>
                                { typeof this.props.icon === 'string' ?
                                    <i style={{fontSize: this.props.iconSize || undefined}} className={ this.props.icon }/>
                                :
                                    <this.props.icon/>
                                }
                            </>
                        : '' }
                        <p style={{fontSize: this.props.textSize || undefined}}>{this.props.title}</p>
                    </div>
                </Link>
            )
        } else {
            return (
                <div {...linkProps} style={{textDecoration: 'none'}}>
                    <div className="grid center middle large-link-button" style={{padding: this.props.small ? '0em' : undefined, height: this.props.small ? 165 : undefined}}>
                        { this.props.fa ? <span style={{fontSize: this.props.iconSize || undefined}} className={this.props.fa}></span> : '' }
                        { this.props.icon ?
                            <>
                                { typeof this.props.icon === 'string' ?
                                    <i style={{fontSize: this.props.iconSize || undefined}} className={ this.props.icon }/>
                                :
                                    <this.props.icon/>
                                }
                            </>
                        : '' }
                        <p style={{fontSize: this.props.textSize || undefined}}>{this.props.title}</p>
                    </div>
                </div>
            )
        }
    }
}
