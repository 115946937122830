import { Checkbox } from '@material-ui/core';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import OSPoint from 'ospoint';
import React from 'react'

export default function PropertyMap({address, meterCoords, cmosMeterRecenter}) {
    const [center, setCenter] = React.useState({});
    const [reload, setReload] = React.useState(false);
    const [current, setCurrent] = React.useState('');
    const [currentMeterCoords, setCurrentMeterCoords] = React.useState('');
    const [meterCenter, setMeterCenter] = React.useState({})
    const [mapCenter, setMapCenter] = React.useState('cmos');
    const [showMeterMarker, setShowMeterMarker] = React.useState(true);
    const [showCMOSMarker, setShowCMOSMarker] = React.useState(true);

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
    })

    const updateMap = () => {
        setReload(true)
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({address: address}, (res, status) => {
            if (status === "OK") {
                setCenter(res[0].geometry.location);
                setReload(false)
            }
        });
    }

    React.useEffect(() => {
        if (isLoaded && (address !== current)) {
            updateMap()
            setCurrent(address)
        }
    }, [address, isLoaded])

    React.useEffect(() => {
        if (isLoaded && meterCoords?.length > 0 && !meterCoords?.every(function(value, index) { return value === currentMeterCoords[index]})) {
            const easting = meterCoords[0]
            const northing = meterCoords[1]
            const point = new OSPoint(northing, easting);
            const wgs84Meter = point.toWGS84()
            setMeterCenter({lat: wgs84Meter.latitude, lng: wgs84Meter.longitude});
            setCurrentMeterCoords([easting, northing])
        }
    }, [meterCoords, isLoaded])

    return (
        !reload ? isLoaded ?
        <>
            <GoogleMap
                mapContainerClassName="map"
                zoom={ 18 }
                center={ mapCenter === "cmos" ? center : meterCenter }
            >
                {showCMOSMarker &&
                    <Marker position={ center }
                    />
                }
                {showMeterMarker && meterCenter &&
                    <Marker 
                        position={meterCenter}
                        icon={{
                            url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                        }}
                    />
                }
            </GoogleMap>
            {cmosMeterRecenter && 
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                        <p>Show CMOS Address:</p>
                        <Checkbox
                            color='primary'
                            checked={showCMOSMarker && center.lat}
                            onChange={() => setShowCMOSMarker(!showCMOSMarker) }
                            disabled={!center?.lat}
                        />
                        <i style={{ cursor: 'pointer', alignSelf: 'center', color: '#FC6355' }} class="fa-solid fa-location-dot" onClick={() => setMapCenter('cmos')} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <p>Show Meter Location:</p>
                        <Checkbox
                            color='primary'
                            checked={showMeterMarker && !!meterCenter.lat}
                            onChange={() => setShowMeterMarker(!showMeterMarker) }
                            disabled={!meterCenter.lat}
                        />
                        <i style={{ cursor: 'pointer', alignSelf: 'center', color: '#5781FC' }} class="fa-solid fa-location-dot" onClick={() => setMapCenter('meter')} />
                    </div>
                </div>
            }
        </> : loadError ? <p>Error Loading Map</p> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div>
    )
}