import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import session from "../../../stores/session";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import ReactModal from 'react-modal'
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './PortfolioSummary.scss';
import Dropzone from '../../../components/dropzone/dropzone'
import Filesaver from 'file-saver'
import Axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

export default view(function PortfolioSummary() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Connection_Type");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')
  const [search, setSearch] = useState('')
  const [files, setFiles] = useState({})
  const fileNames = [
    'MDS WSPID',
    'MDS SSPID',
    'MDS Meters',
    'MDS Read',
    'MDS LUM',
    'MOSL Vacancy',
    'MOSL Eligibility',
    'MOSL Premises',
    'Other'
  ]
  const [searchIsLoading, setSearchIsLoading] = useState(false)
  const [uploadIsLoading, setUploadIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState()
  const [spidFile, setSpidFile] = useState()
  const [spidSearchIsLoading, setSpidSearchIsLoading] = useState(false)
  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=HP.')
    .then(e => {
      setTranslations(e.data)
    })
  }, [])
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.PORTFOLIO_SUMMARY_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate("All_Refreshes");
      });
  }, []);

  useEffect(() => {
    if (!date) return;
    request(true)
      .get(endpoints.PORTFOLIO_SUMMARY_DATA, {
        doesCancel: true,
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
      })
      .then((e) => {
        setData(e.data);
      });
  }, [filters, selectedGroupBy, date]);

  const InfoComponent = React.forwardRef(function InfoComponent(props, ref) {
    return (
      <i {...props} ref={ref} class="fa-regular fa-circle-info" />
    );
  });

  return [
    <GenericPageContainer
      title="Portfolio Summary"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      containerWidth={'92vw'}
      titleRight={
        <div>
          <button style={{  gridColumn: 'span 2' }} className="navigate" onClick={() => setBulkModel('search')}>
            Single SPID Search
          </button>
          {hasPermission('PORTFOLIO_SUMMARY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setBulkModel("spid_search")}>
              Batch SPID Search
            </button>
          ) : null}
          {hasPermission('PORTFOLIO_SUMMARY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setBulkModel("mds_upload")}>
              MDS Upload
            </button>
          ) : null}
          {hasPermission('PORTFOLIO_SUMMARY', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              Download Return File
            </button>
          ) : null}
          {/* {hasPermission('PORTFOLIO_SUMMARY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload")}>
              SPID Upload
            </button>
          ) : null}
          {hasPermission('PORTFOLIO_SUMMARY', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              SPID Download
            </button>
          ) : null} */}
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          <option key={"All_Refreshes"} value={"All_Refreshes"}>All Refreshes</option>
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='PS'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={endpoints.PORTFOLIO_SUMMARY_GROUPINGS}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
          overrideTableHeader={"Summary"}
          removeTableHeader={true}
          subHeaders={[{source: 'valid', header: 'Valid'}, {source: 'table_insert', header: 'To Be Worked'}]}
          displayModeInitial={'table'}
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.PORTFOLIO_SUMMARY_FILTERS}
          />
          <div className='grid grid-gap-5 mta'>
              { (hasPermission('PORTFOLIO_SUMMARY', 'BULK-BUY')) ?
                  <button style={{  gridColumn: 'span 2' }} className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Create Download File
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.PORTFOLIO_SUMMARY_FILES}
      uploadUrl={endpoints.PORTFOLIO_SUMMARY_FILES}
    />,
    <BulkBuy 
    SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
    SentenceSecond={() => <></>}
    purchaseLabel="Download Limit:"
    buyButtonText="Create File"
    completeOnFirstStep={true} 
    workflowUsersEndpoint={endpoints.PORTFOLIO_SUMMARY_WF_USERS} 
    bulkEndpoint={endpoints.PORTFOLIO_SUMMARY_BULK_BASE} 
    modalIsOpen={bulkModel === 'purchase'} 
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
    // <BulkAllocate workflowUsersEndpoint={endpoints.PORTFOLIO_SUMMARY_WF_USERS} bulkEndpoint={endpoints.PORTFOLIO_SUMMARY_BULK_BASE} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <ReactModal
    isOpen={bulkModel === "search"}
    onRequestClose={() => setBulkModel('')}
    className="card bulk-allocate"
    contentLabel="SPID Search"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <h3>Search for a Core SPID</h3>
      <p>Please enter the Core SPID you wish to search for.</p>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
        <input onChange={e => setSearch(e.target.value)} value={ search ?? '' }></input>
        <button className="button compact smaller-text cancel colour-white" onClick={() => setBulkModel('')} >Cancel</button>
        <Link 
        to={{ pathname: '/portal/portfolio-search', state: { spid: search } }}
        className="button compact smaller-text background-primary colour-white"
        style={{ textDecoration: 'none' }}
        disabled={!search || searchIsLoading}
        onClick={() => setSearchIsLoading(true)}
        >{searchIsLoading ?
          <div style={{ padding: '0em 1em' }} >
            <i className="fa fa-spinner fa-spin"></i>
          </div>
        :
          "Search"
        }</Link>
      </div>
    </div>
  </ReactModal>,
  <ReactModal
    isOpen={bulkModel === "mds_upload"}
    onRequestClose={() => setBulkModel('')}
    className="card bulk-allocate"
    contentLabel="MDS Upload"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
  >
  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    <table className='table borders squish smaller-text left'>
      <thead>
        <th colSpan={2}>MDS Upload</th>
      </thead>
      <tbody>
        { fileNames.map((fileType, index) => {
          return (
            <tr>
              <td style={{ textAlign: "center" }} >{fileType}</td>
              <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Dropzone 
                style={{ background: files[index]?.name ? '#445468' : 'var(--gray)' }} 
                className="mds-upload-button"
                customText={files[index]?.name ?? ' Choose File'} 
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length !== 1) {
                    window.alert('You may only upload one file at a time');
                    return
                  }
                  setFiles({...files, [index]: acceptedFiles[0]})
                }} />
                {files[index] &&
                  <i onClick={() => {
                    const modifiedFiles = {...files}
                    delete modifiedFiles[index];  
                    setFiles(modifiedFiles)
                  }} style={{ position: 'absolute', color: 'black', cursor: 'pointer', marginRight: '-2em' }} class="fa-solid fa-xmark fa-lg"></i>
                }
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
      <button className="button compact smaller-text cancel colour-white" onClick={() => {
        setBulkModel('')
        setFiles({})
      }} 
      >Cancel</button>
      <button 
      className={`button compact smaller-text ${fileNames.length > 0 && !uploadIsLoading ? "background-primary colour-white" : ''}`} 
      disabled={fileNames.length < 1 || uploadIsLoading} 
      onClick={() => {
        setUploadIsLoading(true);
        if (window.confirm("By uploading files, you acknowledge and agree that you are responsible for the accuracy and legality of the data within the files. Identeq are not liable for any issues, including but not limited to copyright violations, privacy breaches, or data inaccuracies.")) {
          request(true).post(endpoints.PORTFOLIO_SUMMARY_MDS_UPLOAD, {
            fileNames: Object.keys(files).reduce((acc, index) => {
              acc[fileNames[index]] = files[index].name;
              return acc;
            }, {}),
          }).then(r => {
            Object.keys(files).forEach((fileCategory, index) => {
              Axios.put(r.data.urls[index], files[fileCategory], {
                headers: {
                    'Content-Type': files[fileCategory].type
                }
              })
            });
          }).then(() => {
            window.alert('Files successfully uploaded')
            setUploadIsLoading(false)
          }).catch(e => {
            console.log(e)
            window.alert('Error uploading files');
            setUploadIsLoading(false);
          }) 
        } else {
          setUploadIsLoading(false);
        }
      }}
        >
        {uploadIsLoading ?
            <div style={{ padding: '0em 1em' }} >
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          :
            "Upload Files"
        }
      </button> 
    </div>
  </div>
</ReactModal>,
    <ReactModal
    isOpen={bulkModel === "spid_search"}
    onRequestClose={() => setBulkModel('')}
    className="card bulk-allocate"
    contentLabel="Batch SPID Search"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
  >
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Tooltip 
        placement="top"
        arrow
        TransitionComponent={Zoom}
        style={{ marginLeft: '5px', position: 'absolute' }}
        title={
          <p>
            <strong>File Upload Requirements:</strong>
  
            <ul>
              <li><strong>Accepted formats:</strong> Excel (.xlsx) or CSV (.csv)</li>
              <li><strong>Data structure:</strong> Full SPIDs must be listed in the first column</li>
              <li><strong>No other columns should be populated</strong></li>
              <li><strong>No header rows</strong> (The file should not include any column titles)</li>
              <li><strong>Maximum file size:</strong> 50,000 rows (lines)</li>
            </ul>

            <i>Please note: If more than 50,000 rows are uploaded, only the first 50,000 will be processed and returned.</i>
          </p>
        }>
          <InfoComponent />
        </Tooltip>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <h3>Batch SPID Search</h3>
      <p>Upload a list of SPIDs to receive a return file with the status for each.</p>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
        <p>SPID List:</p>
        <Dropzone 
        style={{ background: spidFile?.name ? '#445468' : 'var(--gray)', minWidth: 'unset', width: '100%' }} 
        className="mds-upload-button"
        customText={spidFile?.name ?? ' Choose File'} 
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length !== 1) {
            window.alert('You may only upload one file at a time');
            return
          }
          setSpidFile(acceptedFiles[0])
        }} />
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        <button className="button compact smaller-text cancel colour-white" onClick={() => setBulkModel('')} >Cancel</button>
        <button 
        className={`button compact smaller-text ${spidFile && !spidSearchIsLoading ? "background-primary colour-white" : ''}`} 
        disabled={!spidFile || spidSearchIsLoading} 
        onClick={() => {
          setSpidSearchIsLoading(true);
          request(true).post(endpoints.PORTFOLIO_SUMMARY_BATCH_SPID_UPLOAD, {
            fileName: spidFile.name
          }).then(r => {
            Axios.put(r.data, spidFile, {
              headers: {
                  'Content-Type': spidFile.type
              }
            })
          }).then(() => {
            window.alert('Files successfully uploaded')
            setSpidSearchIsLoading(false)
          }).catch(e => {
            console.log(e)
            window.alert('Error uploading files');
            setSpidSearchIsLoading(false);
          })
        }}
        >
          {spidSearchIsLoading ?
              <div style={{ padding: '0em 1em' }} >
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            :
              "Upload SPIDs"
          }
        </button> 
      </div>
    </div>
  </ReactModal>,
  ];
})
