import React, { Component } from 'react'
import sessionState from '../stores/session';
import { view } from '@risingstack/react-easy-state';

export default view(class Logout extends Component {
    componentDidMount() {
        sessionState.reset();
        this.props.history.push('/login');
    }

    render() { return(<></>) }
});
