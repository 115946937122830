import { view } from '@risingstack/react-easy-state';
import React, { useEffect } from 'react'
import endpoints from '../helpers/endpoints';
import request from '../helpers/request';
import session from '../stores/session'

export default view(function AzureLoginPage() {
  
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    console.log('CODE', search.get('code'))
    request().get(endpoints.AZURE_CALLBACK, {
      params: {
        code: search.get('code')
      }
    }).then(e => {
      sessionStorage.setItem('user-token', e.data.token);
      session.token = e.data.token;
      session.fetch();

      if (e.data.user.password_expires_in && e.data.user.password_expires_in < 5) {
          if (e.data.user.company) {
              return this.props.history.push('/account')
          }
      }

      if (e.data.user.company) {
          return this.props.history.push('/portal')
      }

      return this.props.history.push('/admin/portal')
    })
    .catch(e => {
      window.location.href = '/'
    })
  }, [])
  return (
    <div>Loading...</div>
  )
})
