import React, { Component } from 'react';
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints'
import Card from '../card/card'
import './login.scss';

export default class DoReset extends Component
{
    constructor(props) {
        super(props);

        this.params = new URLSearchParams(this.props.location.search);

        this.state = {
            form: {
                action: null,
                signature: null,
                password: null,
            },
            message: '',
            passwordMatch: false,
            passwordStrong: false,
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem('api-path') === null) {
            this.handleWait();
        } else {
            this.handleReady();
        }
    }

    handleWait = () => {
        setTimeout(() => {
            if (sessionStorage.getItem('api-path') === null) {
                this.handleWait();
            } else {
                this.handleReady();
            }
        }, 250);
    }

    handleReady = () => {
        let queryString = window.location.href.substring(window.location.href.indexOf('?') + 1);
        let signature = queryString.substring(queryString.indexOf('signature') + 10);

        request(false).post(endpoints.RESET_CHECK, {
            action: this.params.get('action'),
            signature: signature
        }).then(r => {
            if (r.data.valid) {
                this.setState({
                    form: {
                        action: this.params.get('action'),
                        signature: signature,
                    }
                })
            } else {
                this.props.history.push('/login');
            }
        }).catch(r => {
            this.props.history.push('/login');
        })
    }

    formSubmit = () => {
        if (this.state.form.password !== this.state.form.confirm_password) {
            this.setState({
                message: 'Passwords do not match.'
            });

            return;
        } else {
            this.setState({
                message: ''
            });
        }

        request(false).post(endpoints.RESET_RESET, this.state.form).then(r => {
            this.props.history.push('/login');
        }).catch(r => {
            console.error(r);
            this.setState({
                message: r.response.data.errors.password
            });
        });
    }

    updateFormValue = (e) => {
        let passwordStrong = this.state.passwordStrong;
        let passwordMatch = this.state.passwordMatch;

        if (e.target.name === 'password') {
            if (e.target.value.match(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/)) {
                passwordStrong = true;
            } else {
                passwordStrong = false;
            }

            passwordMatch = e.target.value === this.state.form.confirmPassword && passwordStrong;
        }

        if (e.target.name === 'confirm_password') {
            passwordMatch = (e.target.value === this.state.form.password) && passwordStrong;
        }

        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value ? e.target.value : undefined
            },
            passwordStrong: passwordStrong,
            passwordMatch: passwordMatch
        });
    }

    enterKeyDown = (e) => {
        if (e.which === 13) {
            this.formSubmit();
        }
    }

    render() {
        return (
            <div className="login-wrapper">
                <Card>
                    <div className="">
                        <p className="font-weight-600 font-size-2 font-align-center">Reset Password</p>
                    </div>
                    <p>Please enter your new password!</p>
                    <div className="grid grid-gap-15">
                        <input onKeyPress={ this.enterKeyDown } onChange={ this.updateFormValue } name="password" placeholder="Password" className={`input ${this.state.passwordStrong ? 'color-green' : ''}`} type="password" required/>
                        <small>Passwords must be at least 12 characters long and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</small>
                        <input onKeyPress={ this.enterKeyDown } onChange={ this.updateFormValue } name="confirm_password" placeholder="Repeat Password" className={`input ${this.state.passwordMatch ? 'color-green' : ''}`} type="password" required/>
                        { this.state.message ? <p className="error">
                            { this.state.message }
                        </p> : ''}
                        <button onClick={this.formSubmit} className="button background-primary colour-white">Update Password</button>
                    </div>
                </Card>
            </div>
        );
    }
}
