import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './affordabilityAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class AffordabilityAlertsSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customers: [undefined, undefined, undefined, undefined],
                    selectedCustomer: 0,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: null,
                    household: null
                }
            }

            componentDidMount = () => {
                useWSProduct('affordability-alerts');// eslint-disable-line
                request(true).get('/translate?key=tvaa.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                this.setState({
                    customers: [undefined, undefined, undefined, undefined],
                    household: null
                })
                request(true).get(`${endpoints.AFFORDABILITY_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data);
                    if (!r.data.customers || !r.data.customers.length) window.alert('Could not find SPID.');
                    if (!Array.isArray(r.data.customers)) r.data.customers = [r.data.customers];
                    while (r.data.customers.length < 4) { r.data.customers.push(undefined)}
                    while (r.data.length < 4) { r.data.push(undefined)}
                    this.setState({
                        // customers: r.data.customers.sort((a, b) => {
                        //     if (a["Residency_Status"] === "Primary") return -1;
                        //     if (b["Billing_Flag"] === "Primary") return 1;
                        //     if (a["Billing_Flag"] === "Associated") return -1;
                        //     if (b["Billing_Flag"] === "Associated") return 1;
                        //     return a["Residency_Status"] - b["Residency_Status"];
                        // }),
                        customers: r.data.customers.sort((a, b) => {
                            const order = ['Primary', 'Associate', 'New', 'Previous']
                            return order.indexOf(a.Residency_Status) - order.indexOf(b.Residency_Status)
                        }),
                        household: r.data.household,
                        showMap: true,
                        purchasable: r.data[0] && r.data[0].Date_Bought === null,
                        shouldRefreshMap: true,
                        selectedCustomer: 0,
                    }, () => {
                        this.setState({ shouldRefreshMap: false });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = () => {
                request(true).post(`${endpoints.AFFORDABILITY_ALERTS_BUY_CUSTOMER}`, {
                    "customer": this.state.customers[0].Account_Number
                }).then(r => {
                    if (this.state.customers[0].Purchasable_Message) window.alert(this.state.customers[0].Purchasable_Message)
                    this.setState({
                        customers: r.data,
                        showMap: true,
                        purchasable: r.data[0] && r.data[0].Date_Bought === null,
                        selectedCustomer: 0,
                    });
                }).catch(e => {
                    console.log(e);
                })
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            setSelectedCustomer = (index) => {
                this.setState({
                    selectedCustomer: index
                })
            }

            componentWillUnmount() {
                useWSProduct('affordability-alerts', 'unlisten');// eslint-disable-line
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="affordability-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Affordability Alerts - Search" titleRight={
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to='/portal/affordability-alerts/'>
                                        To Overview
                                    </Link>
                                    { (hasPermission('AFFORDABILITY_ALERTS', 'WORKFLOW')) ?
                                        <Link className="navigate" to='/portal/affordability-alerts/workflow'>
                                            To Workflow
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="affordability-alerts">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row tvaa-data-row-title">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tvaa.account_number'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                            <button className="search-button" onClick={ this.getData }>
                                                Search
                                            </button>
                                        </div>

                                        <div className="household">
                                            <div className="left-grid-bottom">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.property_income'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Property_Income : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.disposable_income'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Household_EDI : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.affordability_band'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.AA_Band : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.residency_status'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Income_Category : ''}/>
                                                </div>
                                            </div>
                                            <div className="left-grid-bottom">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.current_balance'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Current_Balance : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.debt_banding'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Debt_Banding : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.account_start_date'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Residency_Age_Band : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tvaa.type'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Type : ''}/>
                                                </div>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tvaa.client_address'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customers[0] ? this.state.customers[0].SPID_Address : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tvaa.matched_address'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customers[0] ? this.state.customers[0].AddressBase_Address : '' }/>
                                            </div>
                                        </div>
                                        
                                        { this.state.customers.map((customer, index) => {
                                            return (
                                                <div className="customer">
                                                    <div className="name-grid">
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['tvaa.name'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? `${customer.Name}` : '' }/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['tvaa.date_of_birth'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? `${customer.Date_of_Birth}` : '' }/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                                { this.state.translations ? this.state.translations['tvaa.age'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Age : ''}/>
                                                        </div>
                                                        <button className="select-button" onClick={() => this.setSelectedCustomer(index)}>Select</button>
                                                    </div>
                                                    <div className="left-grid-bottom">
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['tvaa.income_band'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Occupier_Income_Category : ''}/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['tvaa.disposable_income'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Occupier_EDI : '' }/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['tvaa.affordability_band'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Occupier_AA_Band : ''}/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['tvaa.residency_status'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Residency_Status : ''}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <PropertyMap address={ this.state.customers ? this.state.customers[0] ? this.state.customers[0].SPID_Address : null : null } />
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '200px'}}>
                                                    Listed on electoral roll
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Electoral_Roll : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Listed on BT register
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].BT_Line : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of secured loans
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Secured_Loans : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Named on Title Deed
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Land_Registry : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    GAS redirect in place
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].GAS_Redirect : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    NCoA redirect in place
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].NCOA_Redirect : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of bank accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Bank_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of unsecured loans
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Unsecured_Loans : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of utility accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Utility_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of telco/media accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Telco_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of home credit accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Home_Credit_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '200px'}}>
                                                    Count of mail order accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Mail_Order : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of revolving facilities
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Revolving_Facilities : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of other facilities
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Other_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of recent credit searches
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Credit_Searches : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Company House Director
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].CH_Director : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Loans
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].DoD_Loans : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Subscriptions
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].DoD_Subscriptions : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Latest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Latest_Date : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Earliest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? (this.state.customers[this.state.selectedCustomer].Earliest_Date !== null ? this.state.customers[this.state.selectedCustomer].Earliest_Date : '-')  : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Occupier Score
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Occupier_Confidence + " Stars" : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Occupier Score Change
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Occupier_Change : ''  : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

